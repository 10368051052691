import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/analytics";
import "firebase/compat/performance";
import {
	THEME,
	FIREBASE_APP_API_KEY,
	FIREBASE_APP_AUTH_DOMAIN,
	FIREBASE_APP_DATABASE_URL,
	FIREBASE_APP_PROJECT_ID,
	FIREBASE_APP_STORAGE_BUCKET,
	FIREBASE_APP_MESSAGING_SENDER_ID,
	FIREBASE_APP_ID,
	TIME_DELAY,
	FIREBASE_MEASURE_ID,
	API_TAKEOVER,
} from "../../constants/const";
import moment from "moment";
import "moment-timezone";
import axios from "axios";

//TODO: need to make .env make
const config = {
	apiKey: FIREBASE_APP_API_KEY,
	authDomain: FIREBASE_APP_AUTH_DOMAIN,
	databaseURL: FIREBASE_APP_DATABASE_URL,
	projectId: FIREBASE_APP_PROJECT_ID,
	storageBucket: FIREBASE_APP_STORAGE_BUCKET,
	messagingSenderId: FIREBASE_APP_MESSAGING_SENDER_ID,
	measurementId: FIREBASE_MEASURE_ID,
	appId: FIREBASE_APP_ID,
};

// For login by email without password
const actionCodeSettings = {
	url: "https://loge.wisslmedia.com/",
	iOS: {
		bundleId: "com.wisslmedia.quiz",
	},
	android: {
		packageName: "com.wisslmedia.quiz",
		installApp: true,
		minimumVersion: "1",
	},
	handleCodeInApp: true,
	// When multiple custom dynamic link domains are defined, specify which
	// one to use.
	dynamicLinkDomain: "wslquiz.page.link",
};

class Firebase {
	constructor() {
		app.initializeApp(config);
		// check if firebase authentication or ASSE
		if (THEME === "assejeux") {
			this.auth = {};
			this.auth.onAuthStateChanged = (_callback) => {
				console.log('onAuthStateChanged called');

				let _userLocal = window.localStorage.getItem('_asse_user');
				if (_userLocal) {
					let _userJSON = JSON.parse(_userLocal);
					console.log('_userJSON =', _userJSON);
					if (_userJSON.uid) {
						return _callback(_userJSON);
					}
				}
				if (!this.auth.alreadyCalled) {
					// check token if need
					let _tokenLocal = window.localStorage.getItem('_asse_token_data');
					let _tokenJSON = {};
					if (_tokenLocal) {
						_tokenJSON = JSON.parse(_tokenLocal);
					}

					let _dateNow = new Date();
					let _dateExpire = new Date(_tokenJSON.token_expire || new Date());
					let _dateExpire3hSooner = new Date(_dateExpire.getTime() - (3 * 60 * 1000));
					console.log('_dateExpire3hSooner.getTime() = ', _dateExpire3hSooner.getTime())
					console.log('_dateNow.getTime() = ', _dateNow.getTime())
					this.auth.alreadyCalled = true;
					if (_dateExpire3hSooner.getTime() < _dateNow.getTime()) {
						// get and save token
						axios.get(`${API_TAKEOVER}/asse/token`).then((res) => {
							if (res.data) {
								window.localStorage.setItem('_asse_token_data', JSON.stringify(res.data));
								window.localStorage.setItem('_asse_token', res.data.access_token);
							}
						}).catch((_err) => {
							console.log(_err);
						});
					}
				}

				return _callback(null);
			};

		} else {
			this.auth = app.auth();
			this.auth.languageCode = "fr";
			this.FacebookAuthProvider = app.auth.FacebookAuthProvider;
			this.GoogleAuthProvider = app.auth.GoogleAuthProvider;
			this.AuthCredential = app.auth.AuthCredential;
		}

		this.db = app.firestore();
		this.dbrealtime = app.database();
		//For debug
		// app.app().functions('europe-west1').useFunctionsEmulator('http://localhost:5001');
		this.functions = app.app().functions("europe-west1");
		// this.functions.useFunctionsEmulator('http://localhost:5001');
		// Initialize Performance Monitoring and get a reference to the service
		this.perf = app.performance();
		this.FieldValue = app.firestore.FieldValue;
		this.analytics = app.analytics();
	}

	// *** Auth API ***

	getCredential = (email, passwordActuel) =>
		app.auth.EmailAuthProvider.credential(email, passwordActuel);

	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password);

	doSignInAnonymously = () => this.auth.signInAnonymously();

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);

	doSendSignInLinkToEmail = (email) =>
		this.auth.sendSignInLinkToEmail(email, actionCodeSettings);

	doSignOut = () => {
		if (THEME === 'assejeux') {
			console.log('sign  out');
		} else {
			return this.auth.signOut();
		}

	};

	// *** User API ***
	user = (uid) => this.db.doc(`users/${uid}`);
	users = () => this.db.collection("users");
	quiz = (qid) => this.db.doc(`quizzes/${qid}`);
	quizzes = () => this.db.collection("quizzes");
	result = (qid) => this.db.doc(`quizzes/${qid}/result`);

	answer = (aid) => this.db.doc(`answers/${aid}`);
	answers = (id) => this.db.doc(`answers/${id}`);

	surveys_responses = (id) => this.db.doc(`surveys_responses/${id}`);
	surveys_result = (id) => this.db.doc(`surveys_results/${id}`);

	quiz_ranking = (quizId) => this.db.doc(`quiz_rankings/${quizId}`);

	pronostics_responses = (user_prono) =>
		this.db.doc(`pronostics_responses/${user_prono}`);

	connections = () => this.db.collection("connections");

	predictionsResults = (qid) => this.db.doc(`predictions_results/${qid}`);

	timeNow = async () => {
		const getTimeNow = this.functions.httpsCallable("timeNow");
		const result = await getTimeNow();
		// Read result of the Cloud Function.
		var gmt = moment(result.data.ms);
		var timeParis = gmt.clone().tz("Europe/Paris");
		const now = new Date(Date.now());
		window.localStorage.setItem(
			"delay",
			timeParis.valueOf() - TIME_DELAY - now.getTime());
		window.localStorage.setItem(
			"delayTele",
			timeParis.valueOf() - now.getTime());
		return timeParis.valueOf();
	};

	remoteConfigs = () => this.db.doc(`_configs/${THEME}`);

	rankingTotal = async (limit = 1000) => {
		return this.db
			.collection("users")
			.where("theme", "==", THEME)
			.where("pointTotal", ">", 0)
			.orderBy("pointTotal", "desc")
			.orderBy("timeRightMs", "asc")
			.limit(limit);
	};

	rankingQuiz = async (page, limit = 1000) => {
		return this.db
			.collection("users")
			.where("theme", "==", THEME)
			.where("pointQuiz", ">", 0)
			.orderBy("pointQuiz", "desc")
			.orderBy("timeRightMs", "asc")
			.limit(limit);
	};

	rankingQuizInGroup = async (group, page) => {
		return this.db
			.collection("users")
			.where("theme", "==", THEME)
			.where("pointQuiz", ">", 0)
			.where("logeNumber", "==", group)
			.orderBy("pointQuiz", "desc")
			.orderBy("timeRightMs", "asc")
			.limit(1000);
	};

	rankingQuizCompetition = async (comId, page) => {
		return this.db
			.collection(`competitions/${comId}/rankings`)
			.where("pointQuiz", ">", 0)
			.orderBy("pointQuiz", "desc")
			.orderBy("timeRightMs", "asc")
			.limit(1000);
	};

	rankingForecastCompetition = async (comId, page) => {
		return this.db
			.collection(`competitions/${comId}/rankings`)
			.where("theme", "==", THEME)
			.where("pointForecast", ">", 0)
			.orderBy("pointForecast", "desc")
			.limit(1000);
	};

	rankingTotalCompetition = async (comId, page) => {
		return this.db
			.collection(`competitions/${comId}/rankings`)
			.where("theme", "==", THEME)
			.where("pointTotal", ">", 0)
			.orderBy("pointTotal", "desc")
			.orderBy("timeRightMs", "asc")
			.limit(1000);
	};

	rankingForecast = async (page) => {
		return this.db
			.collection("users")
			.where("theme", "==", THEME)
			.where("pointForecast", ">", 0)
			.orderBy("pointForecast", "desc")
			.limit(1000);
	};

	currentEventsQuery = async (limit = 10, test_code = null) => {
		const now = await this.timeNow();
		let arrType = test_code ?
			[`quiz_${test_code}`, `survey_${test_code}`, `duel_${test_code}`, `match_${test_code}`, `match_rugby_${test_code}`, `draw_${test_code}`, `prediction_${test_code}`] :
			["quiz", "survey", "duel", "match", "match_rugby", "draw", "prediction"];
		return this.db
			.collection("events")
			.where("loge", "==", THEME)
			.where("type", "in", arrType)
			.where("end", ">=", new Date(now))
			.orderBy("end", "asc")
			.limit(limit);
	};

	currentEventsQueryAdmin = async (limit = 100, test_code = null) => {
		const now = await this.timeNow();
		const _date = new Date(now);
		_date.setDate(_date.getDate() - 1);
		_date.setHours(0);
		_date.setMinutes(0);
		_date.setSeconds(1);
		let arrType = test_code ?
			[`quiz_${test_code}`, `survey_${test_code}`, `duel_${test_code}`, `match_${test_code}`, `match_rugby_${test_code}`, `draw_${test_code}`, `prediction_${test_code}`] :
			["quiz", "survey", "duel", "match", "match_rugby", "draw", "prediction"];
		return this.db
			.collection("events")
			.where("loge", "==", THEME)
			.where("type", "in", arrType)
			.where("end", ">=", (_date))
			.orderBy("end", "asc")
			.limit(limit);
	};

	currentEventByIdQuery = async (eid) => {
		return this.db
			.collection("events")
			.where("loge", "==", THEME)
			.where("id", "==", eid);
	};

	currentAsyncTeleQuery = async (ids) => {
		const now = await this.timeNow();
		return this.db
			.collection("events")
			.where("show_qrcode_tv", "==", "true")
			.where("loge", "==", THEME)
			.where("type", "in", ["async", "survey_async"])
			.where("end", ">=", new Date(now))
			.orderBy("end", "asc")
			.limit(1);
	};

	currentAsyncScannedQuery = async (ids) => {
		if (!ids) {
			ids = [];
		}
		ids = ids.filter((e) => {
			return e != null;
		});

		console.log("currentAsyncScannedQuery =", ids);
		const now = await this.timeNow();
		return this.db
			.collection("events")
			.where("id", "in", ids)
			.where("loge", "==", THEME)
			.where("type", "==", "async")
			.where("end", ">=", new Date(now))
			.orderBy("end", "asc");
	};

	currentAsyncAllQuery = async () => {
		const now = await this.timeNow();
		return this.db
			.collection("events")
			.where("loge", "==", THEME)
			.where("type", "==", "async")
			.where("end", ">=", new Date(now))
			.orderBy("end", "asc");
	};

	currentSAScannedQuery = async (ids) => {
		console.log("currentSAScannedQuery =", ids);
		if (!ids) {
			ids = [];
		}
		ids = ids.filter((e) => {
			return e != null;
		});
		const now = await this.timeNow();
		return this.db
			.collection("events")
			.where("id", "in", ids)
			.where("loge", "==", THEME)
			.where("type", "==", "survey_async")
			.where("end", ">=", new Date(now))
			.orderBy("end", "asc");
	};

	currentSAAllQuery = async () => {
		const now = await this.timeNow();
		return this.db
			.collection("events")
			.where("loge", "==", THEME)
			.where("type", "==", "survey_async")
			.where("end", ">=", new Date(now))
			.orderBy("end", "asc");
	};

	currentQuizQuery = async () => {
		const now = await this.timeNow();
		return this.db
			.collection("quizzes")
			.where("loge", "==", THEME)
			.where("end", ">=", new Date(now))
			.orderBy("end", "asc")
			.limit(1);
	};

	currentSurveyQuery = async () => {
		const now = await this.timeNow();
		return this.db
			.collection("surveys")
			.where("loge", "==", THEME)
			.where("end", ">=", new Date(now))
			.orderBy("end", "asc")
			.limit(1);
	};

	currentMatchQuery = async () => {
		const now = await this.timeNow();
		return this.db
			.collection("matches")
			.where("loge", "==", THEME)
			.where("end_time", ">=", new Date(now))
			.orderBy("end_time", "asc")
			.limit(1);
	};

	currentResponeseQuery = (surveyId) =>
		this.db.collection(`surveys_results/${surveyId}/results`);

	currentAnswersQuery = (quizId, limit = 50) =>
		this.db
			.collection(`quiz_rankings/${quizId}/winners/`)
			.orderBy("point", "desc")
			.orderBy("timeRightMs", "asc")
			.orderBy("timeMs", "asc")
			.limit(limit);

	currentQuizRankingsQuery = (quizId) =>
		this.db
			.collection(`quiz_rankings/${quizId}/rankings/`);


	currentQuestionManuel = (quizId) =>
		this.db
			.doc(`quiz_manuel/${quizId}`);

	currentQuestionQuizResults = (quizId, questionId) =>
		this.db.doc(`quiz_rankings/${quizId}/results/${questionId}`);

	requestRestart = (sideId) => this.db.doc(`restarts/${sideId}`);

	pronostics_responses_query = (pronoId) =>
		this.db
			.collection("pronostics_responses")
			.where("pronosticId", "==", pronoId);

	my_pronostics_responses = (pronoIds, uid) =>
		this.db
			.collection("pronostics_responses")
			.where("pronosticId", "in", pronoIds)
			.where("userId", "==", uid);

	quiz_winner = (qid) => this.db.doc(`quiz_winner/${qid}`);
	quiz_winner_ranking = (qid, rid) =>
		this.db.doc(`quiz_winner/${qid}/ranking/${rid}`);
	quiz_participants = (qid, uid) =>
		this.db.doc(`quiz_participants/${qid}/participants/${uid}`);
	quiz_participants_count = (qid) =>
		this.db.collection(`quiz_participants/${qid}/participants`);

	duel_lives = (qid) => this.db.doc(`duels_results/${qid}`);
	duel_participants = (qid, uid) =>
		this.db.doc(`duels/${qid}/participants/${uid}`);
	duel_participants_count = (qid) =>
		this.db.collection(`duels/${qid}/participants`);

	survey_participants = (qid, uid) =>
		this.db.doc(`survey_participants/${qid}/participants/${uid}`);
	survey_participants_count = (qid) =>
		this.db.collection(`survey_participants/${qid}/participants`);

	draw_participants = (did, uid) =>
		this.db.doc(`draw_results/${did}/participants/${uid}`);
	draw_tried_data = (did, uid) =>
		this.db.doc(`draw_results/${did}/participants/${uid}/tried/${did}`);
	draw_winners = (did, uid) =>
		this.db.doc(`draw_results/${did}/winners/${uid}`);

	match_participants = (qid, uid) =>
		this.db.doc(`match_participants/${qid}/participants/${uid}`);
	match_participants_count = (qid) =>
		this.db.collection(`match_participants/${qid}/participants`);
	match_lives = (mid) => this.db.doc(`match_lives/${mid}`);
	match_winner = (mid) => this.db.doc(`match_winner/${mid}`);
	match_winner_ranking = (mid, rid) =>
		this.db.doc(`match_winner/${mid}/ranking/${rid}`);

	feedbacks = (fid) => this.db.doc(`feedbacks/${fid}`);
}

export default Firebase;
