import React, { Component } from "react";
import { View, Text, Dimensions } from "react-native";
import PropTypes from "prop-types";
import {
  TEXT_COLOR,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
} from "../../constants/const";
import { getHeightFromHDMargin } from "../../libs/list_picker/ratio";

class PercentResult extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  };

  renderItem() {
    console.log("this.props.questionType = ", this.props.questionType);
    const backgroundColor = this.props.type === "right" ? "white" : "red";

    const mtop = TELE_MARGIN_TOP;
    const mright = TELE_MARGIN_RIGHT;
    const mbottom = TELE_MARGIN_BOTTOM;
    const mleft = TELE_MARGIN_LEFT;

    const screenHeight =
      Math.round(Dimensions.get("window").height) - mtop - mbottom;

    return (
      <View
        style={{
          backgroundColor,
          height: this.props.modeTele ? Math.round(screenHeight / 12) : 40,
          width: this.props.modeTele ? Math.round(screenHeight / 12) : 40,
          borderRadius: this.props.modeTele
            ? Math.round(screenHeight / 24)
            : 20,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          shadowColor: "#999",
          shadowOffset: { width: 4, height: 2 },
          shadowOpacity: 0.5,
          position: "absolute",
          // left: this.props.modeTele ?  'auto' : (this.props.mode === "text" ? (this.props.questionType === "6" ? 'auto' : 10) : 'auto'),
          right: this.props.modeTele
            ? (this.props.mode === "text"
              ? (this.props.questionType === "6"
                ? -Math.round(screenHeight / 24)
                : 50)
              : getHeightFromHDMargin(20, mtop, mright, mbottom, mleft))
            : (this.props.mode === "text"
              ? (this.props.questionType === "6"
                ? -15
                : 20)
              : 2),
          bottom: this.props.modeTele
            ? this.props.mode === "text"
              ? Math.round(screenHeight / 16 - screenHeight / 24) + 10
              : getHeightFromHDMargin(90, mtop, mright, mbottom, mleft)
            : this.props.mode === "text"
              ? 20
              : this.props.mode === "image_text"
                ? 30
                : 10,
          zIndex: 99900,
          ...this.props.style,
        }}
      >
        <Text
          style={{
            textAlign: "center",
            color: this.props.type === "right" ? "limegreen" : TEXT_COLOR,
            fontSize: this.props.modeTele ? 20 : 14,
          }}
        >
          {this.props.value >= 0 ? this.props.value : 0}%
        </Text>
      </View>
    );
  }

  render() {
    console.log('this.props.value = ', this.props.value);
    return (
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {this.renderItem()}
      </View>
    );
  }

  static defaultProps = {
    value: 48,
    type: "right",
  };
}

export default PercentResult;
