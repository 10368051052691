import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { StyleSheet, Image, View } from "react-native";
import { withAuthorization } from "../../components/Session";
import { Button, Card, Icon, Layout, Modal, Text } from "@ui-kitten/components";
import { EMAILS_RESULTS, MAIN_URL, TEXT_COLOR, THEME_ID } from "../../constants/const";
import ReactHtmlParser from 'react-html-parser';
import LottieView from "react-native-web-lottie";
import axios from "axios";
import { withTranslation, t } from "react-multi-lang";

const giftIcon = (props) => <Icon {...props} name="gift" />;
const linkIcon = (props) => <Icon {...props} name="external-link-outline" />;
export class ItemDetailScreen extends Component {
  state = {
    finaldata: false,
    refreshNum: 1,
    product: null,
    modalConfirmVisible: false,
    modalVisible: false,
    isRequesting: false,
    modalText: t("order_sent_message"),
  };
  constructor(props) {
    super(props);
    if (props.authUser && props.navigation.state.params.i) {
      console.log("getting detail for ", props.navigation.state.params.i);
      // get data
      axios
        .get(
          `https://bo.wisslmedia.com/fr/products/apiRead/${props.navigation.state.params.i}?loge_id=${THEME_ID}`
        )
        .then((res) => {
          if (res.data) {
            if (res.data.data) {
              if (this._isMounted) {
                this.setState({
                  finaldata: true,
                  product: res.data.data,
                });
              }
            }
            //refresh data
          } else {
            props.navigation.navigate("Boutique");
          }
        });
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    // console.log("product detail = ", this.state.product);
    // const maxWidth = this.props.maxWidth;
    this.props.userData.pointShop = this.props.userData.pointShop
      ? this.props.userData.pointShop
      : this.props.userData.pointTotal || 0;
    return (
      <Layout>
        {this.state.finaldata === false && (
          <LottieView
            style={{
              position: "fixed",
              height: 50,
              width: 50,
              bottom: 80,
              alignSelf: "center",
            }}
            source={require("../../assets/anim/loader.json")}
            autoPlay
            loop
          />
        )}
        <Text style={{ margin: 20, textAlign: "center" }} category="h3">
          {t("gift_shop")}
        </Text>
        {this.state.product && (
          <Layout style={styles.layout}>
            <Image
              style={{
                height: 200,
                width: "90%",
                borderRadius: 10,
                backgroundColor: "white",
              }}
              source={{ uri: this.state.product.thumbnail_url }}
              resizeMode={"contain"}
            ></Image>

            <Text
              style={{ textAlign: "center", marginVertical: 10 }}
              category="h5"
            >
              {this.state.product.title}
            </Text>
            <Text
              style={{ textAlign: "center", marginVertical: 2 }}
            >
              {parseFloat(this.state.product.quantity)} {t('in_stock')}
            </Text>

            {this.state.isRequesting === true && (
              <LottieView
                style={{
                  height: 50,
                  width: 50,
                  alignSelf: "center",
                }}
                source={require("../../assets/anim/loader.json")}
                autoPlay
                loop
              />
            )}
            {this.state.isRequesting === false && (
              <Button
                style={styles.footerControl}
                size="medium"
                accessoryRight={giftIcon}
                disabled={
                  parseFloat(this.state.product.price) <=
                    parseFloat(this.props.userData.pointShop ?? 0) &&
                    parseFloat(this.state.product.quantity) > 0
                    ? false
                    : true
                }
                onPress={() => {
                  // this.setState({ modalConfirmVisible: true });
                  // Show confirm and buy
                  if (window.confirm(`${t("get_this_gift")} ${this.state.product ? this.state.product.price : "0"} pts ?`)) {
                    const buyProduct =
                      this.props.firebase.functions.httpsCallable("buyProduct");
                    this.setState({ isRequesting: true });
                    const that = this;
                    buyProduct({
                      productId: this.state.product.identifier,
                      productData: this.state.product,
                      userId: this.props.userData.uid,
                      logeId: THEME_ID,
                      userEmail: this.props.userData.email,
                      adminEmails: EMAILS_RESULTS,
                      userDisplayName: this.props.userData.displayName,
                    }).then((_res) => {
                      console.log("sendMailDuel done = ", _res);
                      const re = _res.data;
                      if (re.status === "success") {
                        //update totalpoin loacal
                        that.props.userData.pointShop =
                          parseFloat(that.props.userData.pointShop ?? 0) -
                          parseFloat(that.state.product.price);
                        window.localStorage.setItem(
                          "profile",
                          JSON.stringify(that.props.userData)
                        );
                        that.setState({
                          modalConfirmVisible: false,
                          isRequesting: false
                        });
                        window.alert(`${this.state.modalText}`);
                        window.location.replace("https://" + MAIN_URL + "/Boutique");
                      } else {

                        that.setState({
                          modalConfirmVisible: false,
                          isRequesting: false
                        });
                        window.alert('Cet article a été épuisé.');
                        window.location.replace("https://" + MAIN_URL + "/Boutique");
                      }
                    });

                  }
                }}
              >
                {parseFloat(this.state.product.price) <=
                  parseFloat(this.props.userData.pointShop ?? 0) &&
                  parseFloat(this.state.product.quantity) > 0
                  ? this.state.product.price
                    .toString()
                    .replace(/(.)(?=(\d{3})+$)/g, "$1 ") + " pts"
                  : parseFloat(this.state.product.quantity) > 0
                    ? `Plus que ${(
                      (this.state.product.price ?? 0) -
                      (this.props.userData.pointShop ?? 0)
                    )
                      .toString()
                      .replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1 "
                      )} pts pour débloquer ce cadeau`
                    : `Épuisé`}
              </Button>
            )}
            {this.state.product.url && (
              <Button
                style={styles.footerControl}
                status="control"
                size="small"
                accessoryRight={linkIcon}
                onPress={() => {
                  window.open(this.state.product.url, "_blank");
                }}
              >
                Ouvrir le site
              </Button>
            )}
            <Layout style={{ margin: 10, color: TEXT_COLOR }}>
              {ReactHtmlParser(`${this.state.product.description}`)}
            </Layout>
          </Layout>
        )}
        <Modal
          visible={this.state.modalVisible}
          backdropStyle={styles.backdrop}
          onBackdropPress={() => {
            this.setState({ modalVisible: false });
            window.location.replace("https://" + MAIN_URL + "/Boutique");
          }}
        >
          <Card disabled={true}>
            <Text style={{ textAlign: "center" }}>{this.state.modalText}</Text>
            <View style={styles.rowButtons}>
              <Button
                style={{ margin: 5 }}
                status="success"
                onPress={() => {
                  this.setState({ modalVisible: false });
                  // this.props.navigation.navigate(`Boutique`)
                  // window.location.replace("http://localhost:3000/Boutique");
                  window.location.replace("https://" + MAIN_URL + "/Boutique");
                }}
              >
                OK
              </Button>
            </View>
          </Card>
        </Modal>
        <Modal
          style={styles.modal}
          visible={this.state.modalConfirmVisible}
          backdropStyle={styles.backdrop}
          onBackdropPress={() => {
            this.setState({ modalConfirmVisible: false });
          }}
        >
          <Card disabled={true}>
            <Text>
              {t("get_this_gift")}
              {this.state.product ? this.state.product.price : "0"} pts ?
            </Text>
            <View style={styles.rowButtons}>
              <Button
                style={{ margin: 5 }}
                onPress={() => this.setState({ modalConfirmVisible: false })}
              >
                {t("no")}
              </Button>
              <Button
                style={{ margin: 5 }}
                status="success"
                onPress={() => {
                  const buyProduct =
                    this.props.firebase.functions.httpsCallable("buyProduct");
                  this.setState({ isRequesting: true, modalConfirmVisible: false, });
                  const that = this;
                  buyProduct({
                    productId: this.state.product.identifier,
                    productData: this.state.product,
                    userId: this.props.userData.uid,
                    logeId: THEME_ID,
                    userEmail: this.props.userData.email,
                    adminEmails: EMAILS_RESULTS,
                    userDisplayName: this.props.userData.displayName,
                  }).then((_res) => {
                    console.log("sendMailDuel done = ", _res);
                    const re = _res.data;
                    if (re.status === "success") {
                      //update totalpoin loacal
                      that.props.userData.pointShop =
                        parseFloat(that.props.userData.pointShop ?? 0) -
                        parseFloat(that.state.product.price);
                      window.localStorage.setItem(
                        "profile",
                        JSON.stringify(that.props.userData)
                      );
                      that.setState({
                        modalConfirmVisible: false,
                        modalVisible: true,
                        isRequesting: false
                      });
                    } else {
                      that.setState({
                        modalConfirmVisible: false,
                        modalText: `Cet article a été épuisé.`,
                        modalVisible: true,
                        isRequesting: false
                      });
                    }
                  });
                }}
              >
                {t("yes")}
              </Button>
            </View>
          </Card>
        </Modal>
      </Layout>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
  layout: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    flex: 1,
    width: "96%",
    backgroundColor: "#fff",
    margin: 2,
  },
  footerContainer: {
    margin: 5,
    flexDirection: "row",
    justifyContent: "center",
  },
  footerControl: {
    textAlign: "center",
    marginHorizontal: 10,
    marginVertical: 10,
    borderRadius: 25,
  },
  rowButtons: {
    flexDirection: "row",
    marginTop: 30,
    justifyContent: "center",
  },
  backdrop: {
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(
  withMainLayout(withTranslation(ItemDetailScreen))
);
