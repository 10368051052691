import React, { Component } from "react";
import Question from "../../components/Quizzes/question";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import { Prediction } from "../../components/Prediction";
import { Duel } from "../../components/Duel";
import { Draw } from "../../components/Draw";
import Surveys from "../../components/Sondages/sondages";

export class LiveScreen extends Component {

  componentDidMount() {
    this._isMounted = true;
    if (this.props.authUser) {

    }
  }

  _renderMenu = () => {
    var out = [];
    var outRender = [];

    if (this.props.currentPredictions) {
      for (let _i = 0; _i < this.props.currentPredictions.length; _i++) {
        const _m = this.props.currentPredictions[_i];
        out.push({
          type: "prediction",
          start:
            _m.start.seconds -
            _m.open * 60 * 60,
          data: _m
        });
      }
    }

    if (this.props.currentQuiz && this.props.currentQuiz.name !== "") {
      out.push({ type: "quiz", start: this.props.currentQuiz.start.seconds });
    }
    if (this.props.currentAsyncs) {
      for (let i = 0; i < this.props.currentAsyncs.length; i++) {
        const a = this.props.currentAsyncs[i];
        if (a.id) {
          out.push({ type: "async", start: a.start.seconds, data: a });
        }
      }
    }

    if (this.props.currentSurvey && this.props.currentSurvey.name !== "") {
      out.push({
        type: "survey",
        start: this.props.currentSurvey.start.seconds,
      });
    }
    if (this.props.currentSAs) {
      for (let i = 0; i < this.props.currentSAs.length; i++) {
        const a = this.props.currentSAs[i];
        if (a.id) {
          out.push({ type: "sa", start: a.start.seconds, data: a });
        }
      }
    }
    if (this.props.currentDuel && this.props.currentDuel.name !== "") {
      out.push({ type: "duel", start: this.props.currentDuel.start.seconds });
    }

    if (this.props.currentDraw) {
      out.push({ type: "draw", start: this.props.currentDraw.start.seconds });
    }

    out.sort((a, b) => {
      return a.start - b.start;
    });

    // Array for SA + QA
    var alreadyHaveS = false;
    var alreadyHaveQ = false;

    if (out.length > 0) {
      for (let i = 0; i < out.length; i++) {
        const event = out[i];
        switch (event.type) {
          case "prediction":
            outRender.push(<Prediction {...this.props} />);
            break;
          case "quiz":
            if (!alreadyHaveQ) {
              outRender.push(<Question {...this.props} />);
              alreadyHaveQ = true;
            }
            break;
          case "duel":
            outRender.push(<Duel {...this.props} />);
            break;
          case "draw":
            outRender.push(<Draw {...this.props} />);
            break;
          case "survey":
            if (!alreadyHaveS) {
              outRender.push(<Surveys {...this.props} />);
              alreadyHaveS = true;
            }
            break;
          default:
            break;
        }
      }
    }
    return outRender;
  };

  render() {
    return (<>
      {this._renderMenu().length > 0 ? this._renderMenu()[0] : <Question {...this.props} />}
    </>
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(LiveScreen));
