import React, { Component } from "react";
import { View } from "react-native";
import {
  TEXT_HOME_2,
  TEXT_COLOR,
  BANNER_BOTTOM_ENABLE,
  SHOW_GAMES,
  APP_NAVTAB,
  MENU_COLOR,
  THEME,
  PAGE_GAMES
} from "../../constants/const";
import { Layout, Text } from "@ui-kitten/components";
import { withTranslation, t } from "react-multi-lang";
import { TouchableOpacity, Image } from "react-native-web";
import ReactHtmlParser from 'react-html-parser';
export class IntroInside extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.currentSurvey = null;
    this.currentQuiz = null;
    this.currentMatch = null;
    this.state = { skipRegister: false };

    if (["rfm-jeux", "fanquizwz", "wizink"].includes(THEME)) {
      props.navigation.navigate("Quizzes");
      return;
    }
  }

  checkTheTimeDisplay = () => {
    var out = [];
    if (this.currentMatch) {
      out.push({ type: "match", start: this.currentMatch.start.seconds });
    }
    if (this.currentQuiz && this.currentQuiz.name !== "") {
      out.push({ type: "quiz", start: this.currentQuiz.start.seconds });
    }
    if (this.currentSurvey && this.currentSurvey.name !== "") {
      out.push({
        type: "survey",
        start: this.currentSurvey.start.seconds,
      });
    }

    out.sort((a, b) => {
      return a.start - b.start;
    });

    if (out.length > 0) {
      if (out[0].type === "match") {
        if (this._isMounted) {
          this.setState({ skipRegister: true });
        }
      } else {
        if (this._isMounted) {
          this.setState({ skipRegister: false });
        }
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { navigate } = this.props.navigation;

    return (
      <>
        <View
          style={{
            alignItems: "center",
            marginBottom: BANNER_BOTTOM_ENABLE() ? 100 : 0,
          }}
        >
          <Layout
            style={{
              color: this.props.navigation.state.params
                ? this.props.navigation.state.params.tc
                  ? this.props.navigation.state.params.tc
                  : TEXT_COLOR
                : TEXT_COLOR,
              fontSize: 16,
              marginBottom: 30,
              textAlign: "justify",
            }}
          >
            {ReactHtmlParser(`${TEXT_HOME_2()}`)}
          </Layout>
        </View>

        <View style={{
          position: 'fixed',
          bottom: BANNER_BOTTOM_ENABLE() ? 70 : 0,
          width: '100%',
          backgroundColor: MENU_COLOR,
          padding: 15
        }}>
          <TouchableOpacity
            onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate(PAGE_GAMES()) : navigate("Home")}
          >
            <View style={{
              flexDirection: "row",
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 16,
                  textAlign: "center",
                  fontWeight: 'bold'
                }}
              >
                {t("go_in")}
              </Text>
              <Image
                style={{ position: 'absolute', right: 0, width: 40, height: 40 }}
                source={require("../../assets/images/next.png")}
                resizeMode="contain"
              />
            </View>
          </TouchableOpacity>
        </View>

      </>
    );
  }
}

export default withTranslation(IntroInside);
