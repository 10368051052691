import React from "react";
import { TIME_PAUSE, TIME_EXTRA_ANIM, THEME } from "../../constants/const";

export class BaseQuiz extends React.Component {
  _isMounted = false;
  timeoutId = null;
  constructor(props) {
    super(props);
    this.currentQuiz = null;
    this.currentQuestion = null;
    this.currentQuestionResult = null;
    this.currentResults = null;
    this.playersRanking = [];
    this.myRanking = null;
    this.snapshotQuestion = null;
    this.manuelSnapshot = null;
    this.state = {
      screen: "CountDown",
      shouldShow: true,
      currentQuiz: null,
      currentQuestion: null,
      currentResults: null,
      displayResult: false,
      timeLeft: 0,
      updateNum: 0
    };
  }

  checkQuizAndQuestion = () => {
    if (this.currentQuiz !== null && this.currentQuiz.start) {
      if (this.currentQuiz.play_mode === "manuel") {
        // this.checkQuizAndQuestionManuel();
        return;
      }
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      const nowMs = Math.floor(
        Date.now() + parseInt(window.localStorage.getItem("delay"), 10)
      );
      const timeLeft = this.currentQuiz.start.seconds - now;

      const isStarted = timeLeft <= 0;
      if (isStarted) {
        // Process questions base on time
        var { questions } = this.currentQuiz;
        questions = questions.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });
        var timePast = this.currentQuiz.start.seconds;
        this.currentQuestion = null;
        for (let i = 0; i < questions.length; i++) {
          const question = questions.filter(e => {
            return e.order === i + 1;
          })[0];
          if (question) {
            const qTime = question ? question.time : 0;
            if (i === 0) {
              timePast = timePast + qTime + TIME_EXTRA_ANIM;
            } else {
              timePast = timePast + TIME_PAUSE + qTime + TIME_EXTRA_ANIM;
            }
            const timePastWithResult = timePast + TIME_PAUSE + TIME_EXTRA_ANIM;
            const now = Math.floor(
              (Date.now() +
                parseInt(window.localStorage.getItem("delay"), 10)) /
              1000
            );

            if (now <= timePastWithResult) {
              this.currentQuestion = question;
              this.currentQuestion.timeLeft = timePast - now; //>  this.currentQuestion.time ? this.currentQuestion.time : (timePast - now);
              this.currentQuestion.timeLeftMs = timePast * 1000 - nowMs;
              this.currentQuestion.displayResult = now > timePast;
              this.currentQuestion.displayResultTimeLeft =
                timePastWithResult - now;
              this.currentQuestion.timeEnd = timePast;
              this.currentQuestion.order = i + 1;
              if (this.state.currentQuestion === null) {
                this.bindData();
              } else if (
                this.state.currentQuestion !== null &&
                this.currentQuestion !== null
              ) {
                if (this.currentQuestion.id !== this.state.currentQuestion.id) {
                  this.bindData();
                } else if (
                  this.currentQuestion.id === this.state.currentQuestion.id &&
                  this.currentQuestion.displayResult !==
                  this.state.currentQuestion.displayResult
                ) {
                  this.bindData();
                }
              }
              break;
            }
          }
        }
        if (this.currentQuestion === null) {
          // this.currentQuestion = this.currentQuiz.questions[0];
          // console.log(this.props.navigation);
          if (this.state.screen !== "Ranking") {
            this.setState({ screen: "Ranking" });
          }
        }
      } else {
        this.setState({
          screen: "CountDown",
          timeLeft: timeLeft,
          currentQuiz: this.currentQuiz,
          updateNum: this.state.updateNum + 1
        });
      }
    } else {
      // navigate to screen no QUIZ
      if (!["rfm-jeux", "fanquizwz", "wizink"].includes(THEME))
        this.props.navigation.navigate("Home");
    }
  };

  bindData = () => {

    if (this._isMounted) {
      this.setState(
        {
          currentQuiz: this.currentQuiz,
          currentQuestion: this.currentQuestion,
          displayResult: this.currentQuestion.displayResult,
          updateNum: this.state.updateNum + 1,
          currentQuestionIndex: this.currentQuestionIndex,
          timeLeft: this.currentQuestion.displayResult
            ? this.currentQuestion.displayResultTimeLeft
            : this.currentQuestion.timeLeft
        },
        () => {
          // console.log("==== this.currentQuestion = ", this.currentQuestion);
        }
      );
    }

  };

  updateRankingData = arrAnswers => {
    // NOTE : get the ranking final and ranking for each question
    if (arrAnswers !== null && this.currentQuiz !== null) {
      this.currentResults = arrAnswers;

      this.updateCurrentQuestionRanking();
    }
  };

  updateCurrentQuestionRanking = () => {
    if (this.currentResults != null) {
      var cResult = [...this.currentResults];
      cResult.sort((a, b) => {
        return (
          b.point - a.point ||
          a.timeRightMs - b.timeRightMs
        );
      });
      this.playersRanking = cResult;
      let _myRank = cResult.find(e => e.id === this.props.authUser?.uid);
      this.myRanking = { ..._myRank, rank: cResult.indexOf(_myRank) + 1 };
      this.setState({ updateNum: this.state.updateNum + 1, myRanking: this.myRanking });

    }

  };

  checkQuizAndQuestionManuel = () => {

    if (this.manuelSnapshot === null) return;
    // Process the time line for quiz
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );

    const timeLeft = this.manuelSnapshot.start.seconds - now;

    const isStarted = timeLeft <= 0;

    if (isStarted) {
      // Process questions base on time
      var { questions } = this.currentQuiz;
      var timePast = this.manuelSnapshot.start.seconds;
      this.currentQuestion = null;


      const question = questions.filter(e => {
        return e.id === this.manuelSnapshot.id;
      })[0];

      if (question) {
        const qTime = question ? question.time : 0;

        timePast = timePast + qTime;
        const timePastWithResult = timePast + TIME_PAUSE;

        const now = Math.floor(
          (Date.now() +
            parseInt(window.localStorage.getItem("delay"), 10)) /
          1000
        );

        this.currentQuestion = question;
        this.currentQuestion.timeLeft = timePast - now;
        this.currentQuestion.timeLeftMs = (timePast - now) * 1000;
        this.currentQuestion.displayResult = now > timePast;
        this.currentQuestion.displayResultTimeLeft = timePastWithResult - now;
        this.currentQuestion.timeEnd = timePast;

        this.bindData();

      }
      // check if  current question is the last question
      let questionIds = (questions.sort((a, b) => a.order - b.order)).map(q => q.id);
      let indexCurrentQuestion = questionIds.indexOf(this.currentQuestion?.id);
      let isLastQuestion = (indexCurrentQuestion === questionIds.length - 1);

      // console.log(this.currentQuestion);
      if (this.currentQuestion?.displayResultTimeLeft === 0 && this.currentQuestion?.displayResult && isLastQuestion) {
        if (this.state.screen !== "Ranking") {
          this.setState({ screen: "Ranking" });
        }
      }
    } else {

      this.setState({
        screen: "CountDown",
        timeLeft: timeLeft,
        currentQuiz: this.currentQuiz,
        updateNum: this.state.updateNum + 1
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.currentQuiz && this.props.currentQuiz.start) {
      this.currentQuiz = this.props.currentQuiz;
    } else {
      var cQ = window.localStorage.getItem("currentQuiz");
      if (cQ !== null) {
        var savedQ = JSON.parse(cQ);
        if (savedQ !== null) {
          this.currentQuiz = savedQ;
        }
      }
    }

    if (this.currentQuiz !== null) {

      if (this.currentQuiz.play_mode === "manuel") {
        // watch for changes current question
        this.props.firebase.currentQuestionManuel(`${this.currentQuiz.id}_${this.currentQuiz.start.seconds}`).onSnapshot(
          snapshot => {
            if (snapshot.exists) {
              let questionManuel = snapshot.data();
              this.manuelSnapshot = questionManuel;

              this.checkQuizAndQuestionManuel();
            }
          },
          err => {
            console.log(`Encountered currentQuestionManuel: ${err}`);
          }
        );
      } else {
        this.checkQuizAndQuestion();
      }

      // watch for changes results
      this.props.firebase
        .currentQuizRankingsQuery(`${this.currentQuiz.id}_${this.currentQuiz.start.seconds}`)
        .onSnapshot(
          snapshot => {
            var arrRanking = [];
            snapshot.forEach(data => {
              arrRanking.push({ ...data.data(), id: data.id });
            });
            this.updateRankingData(arrRanking);
          },
          err => {
            console.log(`currentQuizRankingsQuery Encountered error: ${err}`);
          }
        );

      // precall the calcul function
      const calQuizRanking = this.props.firebase.functions.httpsCallable(
        "calculateQuizByRanking"
      );
      // calcul
      calQuizRanking({
        quizId: `${this.currentQuiz.id}`,
        startSecond: `${this.currentQuiz.start.seconds}`
      }).then(re => {
        console.log("calculateQuiz precall done");
      });
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this._isMounted = false;
  }
}

export default BaseQuiz;
