import React from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);
      if (props.firebase) {
        props.firebase.auth.onAuthStateChanged(authUser => {
          if (!condition(authUser)) {
            if (props.navigation) {
              props.navigation.navigate("Home");
            }
          }
          // Note ASSE: return without toJSON()
          // this.setState({ authUser});
        });
      }
    }

    render() {
      return <Component key={`${this.state?.authUser?.uid}`} {...this.props} />;
    }
  }

  return compose(withFirebase)(WithAuthorization);
};

export default withAuthorization;
