import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet
} from "react-native";
import RF from "react-native-responsive-fontsize";
import {
  PROFILE_LOGE_NUMBER,
  LOGE_NUMBERS,
  LOGE_TEXT,
  TEXT_COLOR,
  THEME,
  SHOW_GAMES,
  PAGE_GAMES
} from "../../constants/const";
import { Picker } from "react-native-web";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import moment from "moment";
import TOButton from "../../libs/to_button";
import {
  withTranslation,
  t
} from "react-multi-lang";

export class LogeScreen extends Component {
  validInput = () => {
    if (this.state === null) {
      return false;
    }

    if (!this.state.logeNumber || this.state.logeNumber === "") {
      return false;
    }

    return true;
  };
  onValidateClicked = () => {
    const errMessage = "Paramètre d'entrée invalide ou manquant";
    if (this.validInput()) {
      this.setState({ error: null });
      // const { navigate } = this.props.navigation;
      const { firebase } = this.props;
      const that = this;
      firebase
        .user(this.props.authUser.uid)
        .set({
          logeNumber: this.state.logeNumber || false,
          logeNumberDate: moment().format("YYYY-MM-DD")
        }, { merge: true })
        .then(() => {
          this._shouldRedirectToEvent();
        })
        .catch(error => {
          that.setState({ error });
          console.log(error);
        });
      // Go to notice screen for checking email
    } else {
      this.setState({ error: { message: errMessage } });
    }
  };
  _shouldRedirectToEvent = () => {
    var out = [];
    const { navigate } = this.props.navigation;

    if (THEME === 'salonstade' || THEME === 'stadedefrance') {
      if (SHOW_GAMES) {
        navigate(PAGE_GAMES());
      } else {
        navigate("HomeInside");
      }
      return;
    }

    if (this.props.currentMatch) {
      out.push({
        type: "match",
        priority: 0,
        game: this.props.currentMatch.type,
        start: this.props.currentMatch.start.seconds,
      });
    }

    if (this.props.currentQuiz && this.props.currentQuiz.name !== "") {
      out.push({
        type: "quiz",
        priority: 1,
        start: this.props.currentQuiz.start.seconds,
      });
    }
    if (this.props.currentAsyncs) {
      for (let i = 0; i < this.props.currentAsyncs.length; i++) {
        const a = this.props.currentAsyncs[i];
        if (a.id) {
          out.push({
            type: "async",
            priority: 1,
            start: a.start.seconds,
            data: a,
          });
        }
      }
    }
    if (this.props.currentSurvey && this.props.currentSurvey.name !== "") {
      out.push({
        type: "survey",
        priority: 1,
        start: this.props.currentSurvey.start.seconds,
      });
    }
    if (this.props.currentSAs) {
      for (let i = 0; i < this.props.currentSAs.length; i++) {
        const a = this.props.currentSAs[i];
        if (a.id) {
          out.push({
            type: "sa",
            priority: 1,
            start: a.start.seconds,
            data: a,
          });
        }
      }
    }
    if (this.props.currentDuel && this.props.currentDuel.name !== "") {
      out.push({
        type: "duel",
        priority: 1,
        start: this.props.currentDuel.start.seconds,
      });
    }

    if (this.props.currentDraw && this.props.currentDraw.name !== "") {
      out.push({
        type: "draw",
        priority: 1,
        start: this.props.currentDraw.start.seconds,
      });
    }

    out.sort((a, b) => {
      return a.start - b.start;
    });
    if (out.length > 0) {
      const event = out[0];
      switch (event.type) {
        case "match":
          if (event.game === "match_rugby") {
            navigate("Rugby");
          } else {
            navigate("Forecast");
          }
          return;
        case "quiz":
          navigate("Quizzes");
          return;
        case "duel":
          navigate("Duel");
          return;
        case "draw":
          navigate("Draw");
          return;
        case "survey":
          navigate("Surveys");
          return;
        default:
          break;
      }
    }
    if (SHOW_GAMES) {
      navigate(PAGE_GAMES());
    } else {
      navigate("HomeInside");
    }
  };
  _renderItem = (value, label) => {
    return <Picker.Item
      // style={{color: TEXT_COLOR}} 
      key={label} label={label} value={value} />;
  };
  _pickerItemLogeNumber = () => {
    var out = [];
    out.push(this._renderItem("", `${LOGE_TEXT} *`));
    // merge arr of salon and loges
    var arrList = LOGE_NUMBERS();
    for (let i = 0; i < arrList.length; i++) {
      var loge = arrList[i];
      //Get Name from data with geolocation
      loge = loge.split(';')[0];
      out.push(this._renderItem(loge, loge));
    }
    return out;
  };
  render() {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: "100%",
          width: this.props.maxWidth
        }}
      >
        <View
          style={{
            padding: 20,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Text
            style={{
              color: TEXT_COLOR,
              fontSize: 16,
              fontWeight: "normal",
              lineHeight: 24,
              margin: 10,
              textAlign: "center",
              // textTransform: "uppercase"
            }}
          >
            {THEME !== 'salonstade' && THEME !== 'france23-skyboxes' && THEME !== '35ansrecrea' && t('select_box_number')}
            {THEME === 'salonstade' && t('select_salon_number')}
            {THEME === 'france23-skyboxes' && t('select_skybox_number')}
            {THEME === '35ansrecrea' && "Sélectionnez votre région, puis cliquez sur VALIDEZ"}

          </Text>

          {this.state && this.state.error && (
            <Text
              style={{
                color: "red",
                fontSize: RF(1.5),
                margin: 10,
                textAlign: "center"
              }}
            >
              * {this.state.error.message}
            </Text>
          )}

          {PROFILE_LOGE_NUMBER === true && (
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20
              }}
            >
              <Picker
                selectedValue={(this.state && this.state.logeNumber) || ""}
                onValueChange={(itemValue, itemIndex) => {
                  this.setState({
                    logeNumber: itemValue
                  });
                }}
                mode="dropdown"
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  fontSize: 20,
                  padding: 10,
                  paddingRight: 25,
                  paddingLeft: 25
                }}
                itemStyle={styles.itemStyle}
              >
                {this._pickerItemLogeNumber()}
              </Picker>
            </View>
          )}

          <TOButton onClick={() => this.onValidateClicked()}></TOButton>

        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  itemStyle: {
    textAlign: "left"
  }
});

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(withTranslation(LogeScreen)));
