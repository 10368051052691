import React, { Component } from "react";
import { Text, View, TextInput, StyleSheet, Button } from "react-native";
import RF from "react-native-responsive-fontsize";
import { withFirebase } from "../Firebase";
import {
  BUTTON_COLOR,
  BORDER_COLOR,
  BORDER_WIDTH,
  TEXT_COLOR,
  MAIN_URL,
  SHOW_GAMES,
  PAGE_GAMES,
} from "../../constants/const";
import {
  withTranslation,
  t
} from "react-multi-lang";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.currentUser = null;
    this.state = { currentEmail: "" };
    if (props.firebase) {
      props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser != null) {
          //Save prono if needed
          this.currentUser = authUser;
          if(SHOW_GAMES){
            props.navigation.navigate(PAGE_GAMES());
          }else{
            props.navigation.navigate("HomeInside");
          }
        }
      });

      const actionCode = props.navigation.state.params.oobCode || "";
      const continueUrl = props.navigation.state.params.continueUrl || "";
      const apiKey = props.navigation.state.params.apiKey || "";
      const mode = props.navigation.state.params.mode || "";
      const oobCode = props.navigation.state.params.oobCode || "";

      if (continueUrl) {
        if (!continueUrl.includes(MAIN_URL)) {
          window.location.href = `${continueUrl}?apiKey=${apiKey}&mode=${mode}&oobCode=${oobCode}`;
        }
      }

      //verify email if needed
      if (mode === "verifyEmail") {
        props.firebase.auth
          .applyActionCode(actionCode)
          .then((resp) => {
            // Email address has been verified.
            // TODO: Display a confirmation message to the user.
            // You could also provide the user with a link back to the app.
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
            if(SHOW_GAMES){
              props.navigation.navigate(PAGE_GAMES());
            }else{
              props.navigation.navigate("HomeInside");
            }
          })
          .catch((error) => {
            // Code is invalid or expired. Ask the user to verify their email address
            // again.
          });
      } else {
        // Verify the password reset code is valid.
        const that = this;
        props.firebase.auth
          .verifyPasswordResetCode(actionCode)
          .then(function (email) {
            var accountEmail = email;
            console.log(accountEmail);
            that.setState({ currentEmail: accountEmail });
            // TODO: Show the reset screen with the user's email and ask the user for
            // the new password.

            // Save the new password.
            //auth.confirmPasswordReset(actionCode, newPassword).then(function(resp) {
            // Password reset has been confirmed and new password updated.

            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);

            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
            //}).catch(function(error) {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            //});
          })
          .catch(function (error) {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            console.log(error);
          });
      }
    }
  }

  onValidateClicked = (firebase) => {
    this.setState({ error: null });
    const newPassword = this.state.password;
    const actionCode = this.props.navigation.state.params.oobCode || "";
    // Verify the password reset code is valid.
    const that = this;
    this.props.firebase.auth
      .verifyPasswordResetCode(actionCode)
      .then(function (email) {
        var accountEmail = email;
        // Save the new password.
        that.props.firebase.auth
          .confirmPasswordReset(actionCode, newPassword)
          .then(function (resp) {
            // Password reset has been confirmed and new password updated.
            // if the page belongs to the same domain as the app:
            that.props.firebase.auth.signInWithEmailAndPassword(
              accountEmail,
              newPassword
            );
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
          })
          .catch(function (error) {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            if (error.code === "auth/weak-password") {
              that.setState({
                error: {
                  message:
                    t("password_needs_6_charaters"),
                },
              });
            } else {
              that.setState({ error: { message: error.code } });
            }
          });
      })
      .catch(function (error) {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        console.log(error);
        that.setState({ error: { message: error.code } });
      });
  };

  render() {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: this.props.maxWidth,
        }}
      >
        {this.props.navigation.state.params.mode !== 'verifyEmail' && (
        <View
          style={{
            padding: 20,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: TEXT_COLOR,
              fontSize: RF(2),
              fontWeight: "bold",
              marginVertical: 10,
              textAlign: "center",
              
            }}
          >
            {t('reset_password')}
          </Text>

          <Text
            style={{
              color: TEXT_COLOR,
              fontSize: RF(2),
              marginBottom: 10,
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            {t('for')}&nbsp;
            <b>
              <i>{this.state.currentEmail}</i>
            </b>
          </Text>

          {(!this.state || !this.state.sentLink) && (
            <TextInput
              textContentType="password"
              style={styles.inputBox}
              secureTextEntry={true}
              placeholder={t('new_password')}
              placeholderTextColor={"lightgray"}
              onChangeText={(text) => {
                this.setState({ password: text });
              }}
              value={(this.state && this.state.password) || ""}
            />
          )}

          {(!this.state || !this.state.sentLink) && (
            <Button
            style={{margin: 60, borderRadius: 25, textTransform: 'uppercase'}}
              title={t('submit')}
              color={BUTTON_COLOR}
              onPress={() => {
                this.onValidateClicked();
              }}
            />
          )}

          {this.state && this.state.error && (
            <Text
              style={{
                color: "red",
                fontSize: RF(1.5),
                margin: 10,
                textAlign: "center",
              }}
            >
              * {this.state.error.message}
            </Text>
          )}
        </View>
      
      )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  inputBox: {
    height: 30,
    width: "100%",
    borderWidth: BORDER_WIDTH,
    borderRadius: 3,
    borderColor: BORDER_COLOR,
    backgroundColor: "white",
    padding: 5,
    margin: 3,
    marginBottom: 20,
  },
});

export default withFirebase(withTranslation(ResetPassword));
