import React, { Component } from "react";
import {
  Text,
  View,
  ImageBackground,
  Image,
  Animated,
  Easing,
  Dimensions,
} from "react-native";
import {
  MAIN_COLOR,
  MAIN_URL,
  BG_TELE,
  THEME_BANNER_FULL,
  THEME_BACKGROUND_TELE,
  TEXT_TV_COLOR,
  SHOW_NULBER_ONLINE,
  SHOW_TUTO,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
  HIDE_QRCODE,
  THEME,
  QRCODE_FOREGROUND_COLOR,
  QRCODE_BACKGROUND_COLOR,
  ONLY_ANONYMOUS,
  MAIN_DYNAMIC_LINK
} from "../../constants/const";
import NumberOnline from "./Elements/online";
import TeleCountDown from "./Elements/countdown";
import QRCode from "react-qr-code";
import RF from "react-native-responsive-fontsize";
import TutorTele from "./Tutor/tutor";
import {
  getWidthFromHDMargin,
  getHeightFromHDMargin,
} from "../../libs/list_picker/ratio";
import FadeView from "../Anim/FadeView";
import ScaleView from "../Anim/scaleView";
import { t } from "react-multi-lang";

export class TeleHome6 extends Component {
  _isMounted = false;
  state = {
    currentQuiz: null,
    timeLeft: 0,
    animate: false,
    showTuto: false,
    showFocus: false,
    blinkAnimation: new Animated.Value(0.2),
    animations: {
      bgWidth: new Animated.Value(80),
      bgMarginTop: new Animated.Value(100),
      bgMarginBottom: new Animated.Value(200),
      textMarginBottom: new Animated.Value(90),
      fadeOut: new Animated.Value(1),
    },
    aniShadowRadius: new Animated.Value(20),
  };

  springSize = new Animated.Value(3);
  constructor(props) {
    super(props);

    this.callbackTimer = this.callbackTimer.bind(this);
  }
  spring() {
    this.springSize.setValue(3);
    Animated.spring(this.springSize, {
      toValue: 1,
      bounciness: 12,
    }).start();
  }

  doShadowRadiusUp = (aniShadowRadius) =>
    Animated.parallel([
      Animated.timing(aniShadowRadius, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 1000,
        delay: 0,
      }),
    ]);
  doShadowRadiusDown = (aniShadowRadius) =>
    Animated.parallel([
      Animated.timing(aniShadowRadius, {
        toValue: 40,
        easing: Easing.elastic(),
        duration: 1000,
        delay: 500,
      }),
    ]);

  doAnimation = ({
    bgWidth,
    bgMarginTop,
    bgMarginBottom,
    textMarginBottom,
    fadeOut,
  }) =>
    Animated.parallel([
      Animated.timing(bgWidth, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0,
      }),
      Animated.timing(bgMarginTop, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0,
      }),
      Animated.timing(bgMarginBottom, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0,
      }),
      Animated.timing(textMarginBottom, {
        toValue: 350,
        easing: Easing.ease,
        duration: 300,
        delay: 0,
      }),
      Animated.timing(fadeOut, {
        toValue: 0,
        easing: Easing.ease,
        duration: 300,
        delay: 0,
      }),
    ]);

  componentDidMount() {
    this._isMounted = true;

    if (this.props.currentQuiz) {
      // precall the calcul function
      const calQuizRanking = this.props.firebase.functions.httpsCallable(
        "calculateQuizByRanking"
      );
      // calcul
      calQuizRanking({
        quizId: `${this.props.currentQuiz.id}`,
        startSecond: `${this.props.currentQuiz.start.seconds}`,
      }).then((re) => {
        console.log("calculateQuiz precall done");
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  callbackTimer(val) {
    if (val <= 0 && this.state.animate === false) {
      //set state animated
      if (this._isMounted) {
        this.setState({ animate: true });
      }

      const {
        bgWidth,
        bgMarginTop,
        bgMarginBottom,
        textMarginBottom,
        fadeOut,
      } = this.state.animations;

      this.doAnimation({
        bgWidth,
        bgMarginTop,
        bgMarginBottom,
        textMarginBottom,
        fadeOut,
      }).start(() => {
        // Do stuff after animations
      });
    }

    if (val % 45 === 0 && val > 60) {
      if (this._isMounted) {
        this.setState({ showTuto: true });
        setTimeout(
          function () {
            if (this._isMounted) {
              this.setState({ showTuto: false });
            }
          }.bind(this),
          30000
        );
      }
    }

    if (val % 20 === 0 && val > 0) {
      if (this._isMounted) {
        this.setState({ showFocus: true });
        setTimeout(
          function () {
            Animated.sequence([
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
            ]).start();
          }.bind(this),
          1000
        );

        setTimeout(
          function () {
            if (this._isMounted) {
              this.setState({ showFocus: false });
            }
          }.bind(this),
          4000
        );
      }
    }

    if (this.state.animate === true) {
      if (this._isMounted) {
        this.setState({ timeLeft: val });
      }
      this.spring();
      if (val < 0) {
        if (this.props.currentQuiz === null) {
          if (this._isMounted) {
            this.setState({
              msg: t("no_events"),
            });
          }
        }
      }
    }

    if (this.props.callbackTimer) {
      this.props.callbackTimer(val);
    }
  }

  render() {
    const { animations, animate } = this.state;
    // const qrSize = 940;
    const mtop = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mtop
          ? parseInt(this.props.navigation.state.params.mtop, 10)
          : TELE_MARGIN_TOP
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP;
    const mright = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mright
          ? parseInt(this.props.navigation.state.params.mright, 10)
          : TELE_MARGIN_RIGHT
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mbottom
          ? parseInt(this.props.navigation.state.params.mbottom, 10)
          : TELE_MARGIN_BOTTOM
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mleft
          ? parseInt(this.props.navigation.state.params.mleft, 10)
          : TELE_MARGIN_LEFT
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT;
    const screenHeight =
      Math.round(Dimensions.get("window").height) - mtop - mbottom;
    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;

    const should_hide_qrcode = this.props.navigation.state.params
      ? this.props.navigation.state.params.hasOwnProperty("hideqr")
        ? this.props.navigation.state.params.hideqr === "true"
        : HIDE_QRCODE
      : HIDE_QRCODE;
console.log('home 6', this.state);
    return (
      <View
        style={{
          backgroundColor: this.props.navigation.state.params
            ? this.props.navigation.state.params.mc
              ? this.props.navigation.state.params.mc
              : MAIN_COLOR
            : MAIN_COLOR,
          flex: 1,
          marginBottom: mbottom,
          marginLeft: mleft,
          marginRight: mright,
          marginTop: mtop,
        }}
      >
        <View
          style={{
            flex: 1,
            alignSelf: "center",
            width: "100%",
          }}
        >
          <ImageBackground
            style={{
              flex: 1,
              flexDirection: "row",
              backgroundColor: this.props.navigation.state.params
                ? this.props.navigation.state.params.bgc
                  ? this.props.navigation.state.params.bgc
                  : BG_TELE
                : BG_TELE,
              justifyContent: 'space-between'
            }}
            source={
              this.props.navigation.state.params
                ? this.props.navigation.state.params.bgtele
                  ? this.props.navigation.state.params.bgtele
                  : THEME_BACKGROUND_TELE
                : THEME_BACKGROUND_TELE
            }
            resizeMode={"cover"}
          >
            <View
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: "column",
                height: screenHeight,
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Animated.View
                style={{
                  margin: getWidthFromHDMargin(
                    20,
                    mtop,
                    mright,
                    mbottom,
                    mleft
                  ),
                  opacity: animate ? animations.fadeOut : 1,
                  alignSelf: THEME === 'nantes-fanzone' ? 'center' : 'stretch'
                }}
              >
                {this.props.currentQuiz &&
                  (this.props.navigation.getParam("for") === "wisslmedia" ||
                    (this.props.navigation.state.params
                      ? this.props.navigation.state.params.sonline
                        ? this.props.navigation.state.params.sonline === "true"
                        : SHOW_NULBER_ONLINE
                      : SHOW_NULBER_ONLINE) === true) && (
                    <NumberOnline
                      type="quiz"
                      mode={2}
                      logeName={this.props.currentQuiz.subtitle}
                      {...this.props}
                    />
                  )}

                {this.props.currentQuiz &&
                  <TeleCountDown
                    type={2}
                    timeLeft={this.props.timeLeft}
                    callbackTimer={this.callbackTimer}
                    style={{
                      marginTop: getWidthFromHDMargin(
                        20,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      )
                    }}
                  />}
              </Animated.View>
            </View>
            {this.props.currentQuiz && (
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  height: screenHeight,
                  // width: getWidthFromHDMargin(1080, mtop, mright, mbottom, mleft)
                }}
              >
                {should_hide_qrcode && (
                  <Animated.View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      borderRadius: 25,
                      width: getWidthFromHDMargin(
                        740,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      height: getHeightFromHDMargin(
                        350,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "94%",
                        }}
                      >
                        <Text
                          style={{
                            color: MAIN_COLOR,
                            fontSize: RF(4),
                            fontWeight: "bold",
                            textAlign: "center",
                            marginBottom: 20,
                          }}
                        >
                          Connectez-vous sur :
                        </Text>
                        <Text
                          style={{
                            color: THEME === "ogcnice-stade" ? "#fff" : "#000",
                            fontSize:
                              (getWidthFromHDMargin(
                                700,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ) /
                                MAIN_URL.length) *
                              1.65,
                            fontWeight: "bold",
                          }}
                        >
                          {MAIN_URL.toUpperCase()}
                        </Text>
                      </View>
                    </View>
                  </Animated.View>
                )}

                {!should_hide_qrcode && (
                  <Animated.View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: QRCODE_BACKGROUND_COLOR,
                          height: screenHeight,
                          width: screenHeight,
                          borderRadius: 30,
                        }}
                      >
                        {this.state.showFocus && (
                          <ScaleView
                            style={{
                              position: "absolute",
                              backgroundColor: "green",
                              opacity: this.state.blinkAnimation,
                            }}
                            delay={500}
                            duration={500}
                            size={screenHeight}
                          ></ScaleView>
                        )}
                        {this.state.showFocus && (
                          <FadeView
                            style={{ position: "absolute" }}
                            delay={1000}
                            duration={300}
                          >
                            <Image
                              style={{
                                width: screenHeight,
                                height: screenHeight,
                              }}
                              source={require("../../assets/tutor/focus.png")}
                              resizeMode="contain"
                            />
                          </FadeView>
                        )}

                        <QRCode
                          value={MAIN_DYNAMIC_LINK.includes('http') ? MAIN_DYNAMIC_LINK : ("https://" + MAIN_URL + (ONLY_ANONYMOUS ? "/quickSignUp" : "/SignUp"))}
                          fgColor={QRCODE_FOREGROUND_COLOR}
                          bgColor={QRCODE_BACKGROUND_COLOR}
                          size={screenHeight * 0.95}
                        />
                      </View>
                    </View>
                  </Animated.View>
                )}
              </View>
            )}
          </ImageBackground>
        </View>

        <Animated.Image
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignSelf: "center",
            opacity: animate ? animations.fadeOut : 1,
          }}
          source={THEME_BANNER_FULL}
          resizeMode={"stretch"}
        />

        {this.state.animate && (
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            {this.state.msg && (
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(8),
                  fontStyle: "bold",
                  textAlign: "center",
                  textShadowOffset: { width: 5, height: 5 },
                  textShadowColor: "rgba(255, 255, 255, 0.5)",
                  textShadowRadius: 20,
                  position: "absolute",
                  alignSelf: "center",
                }}
              >
                {this.state.msg}
              </Text>
            )}
          </View>
        )}

        {this.state.showTuto &&
          (this.props.navigation.state.params
            ? this.props.navigation.state.params.stuto
              ? this.props.navigation.state.params.stuto === "true"
              : SHOW_TUTO
            : SHOW_TUTO) === true && (
            <View
              style={{
                width: screenWidth,
                height: screenHeight,
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TutorTele {...this.props} />
            </View>
          )}
      </View>
    );
  }
}

export default TeleHome6;
