import React from "react";
import {
  Button,
  Icon,
  TopNavigation,
  TopNavigationAction,
} from "@ui-kitten/components";
import {
  TouchableOpacity,
  Text,
  View,
  Image,
} from "react-native";
import {
  MENU_COLOR,
  ONLY_ANONYMOUS,
  PAGE_GAMES,
  SHOW_GAMES,
  SHOW_SHOP,
  TEXT_COLOR,
  THEME,
  THEME_HEADER_LEFT,
} from "../../constants/const";
import BurgerMenu from "./BurgerMenu";

const BackIcon = (props) => <Icon {...props} name="arrow-back-outline" />;

const InfoIcon = (props) => <Icon {...props} name="gift" />;

export const NavBar = (props) => {

  var _hasProfile = window.localStorage.getItem("profile");

  const renderMenuActions = () => (
    <>
      {props.userData && (
        <View
          style={{
            marginLeft: props.withMenuBurger ? 50 : 5,
            borderLeftWidth: props.withMenuBurger ? 1 : 0,
            borderLeftColor: "white",
          }}
        >
          {props.backable && (
            <TopNavigationAction
              icon={BackIcon}
              onPress={() => {
                // need add cases
                if (props.firebase && props.firebase.analytics) {
                  props.firebase.analytics.logEvent("button_header_back", { name: 'button' });
                }
                if (SHOW_GAMES) {
                  props.navigation.navigate(PAGE_GAMES());
                } else {
                  props.navigation.navigate("HomeInside");
                }
              }}
            />
          )}
          {!props.backable && !props.withMenuBurger && (
            <Image
              style={{
                width: 88, height: 44,
                backgroundPositionX: "left",
              }}
              resizeMode={"contain"}
              source={THEME_HEADER_LEFT}
            />
          )}
        </View>
      )}
    </>
  );

  const renderInfoAction = () => {
    return (
      <>
        {props.userData.displayName && SHOW_SHOP && !props.withAdmin && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ borderRadius: 25 }}
              onPress={() => {
                if (props.firebase && props.firebase.analytics) {
                  props.firebase.analytics.logEvent("button_header_shop", { name: 'button' });
                }
                props.navigation.navigate("Boutique");
              }}
              accessoryRight={InfoIcon}
              size="small"
            >
              {`${props.userData.pointShop || 0} pts`}
            </Button>
          </View>
        )}

        {THEME === "ogcnice-stade" && (
          <Image
            style={{
              width: 40,
              height: 40,
            }}
            resizeMode={"contain"}
            source={THEME_HEADER_LEFT}
          />
        )}
      </>
    );
  };

  const renderTitle = (pr) => {
    // console.log("renderTitle", props);
    return props.navigation.state.routeName !== "Profile" ? (
      <TouchableOpacity
        onPress={() => {
          if (THEME !== 'assejeux') {
            if (props.authUser && props.userData && props.userData.displayName) {
              if (props.firebase && props.firebase.analytics) {
                props.firebase.analytics.logEvent("button_header_profile", { name: 'button' });
              }
              if (!ONLY_ANONYMOUS) {
                props.navigation.navigate("Profile");
              }
            } else {
              if (props.firebase && props.firebase.analytics) {
                props.firebase.analytics.logEvent("button_header_login", { name: 'button' });
              }
              if (!ONLY_ANONYMOUS) {
                props.navigation.navigate("SignIn");
              }
            }
          }
        }}
      >
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <Text
            style={
              THEME === "jeutop14"
                ? {
                  color: TEXT_COLOR,
                  fontWeight: "bold",
                  maxWidth: 140,
                  textAlign: "center",
                }
                : { ...pr.style }
            }
          >
            {props.authUser && props.userData && props.userData.displayName
              ? props.userData.displayName
              : _hasProfile
                ? ""
                : ""}
          </Text>
          {!(
            props.authUser &&
            props.userData &&
            props.userData.displayName
          ) && (
              <Text
                style={{
                  color: TEXT_COLOR,
                  textDecorationLine: "underline",
                  fontWeight: "bold",
                }}
              >
                {_hasProfile ? "" : ""}
              </Text>
            )}

          {!_hasProfile && props.withMenuBurger && (
            <Image
              style={{
                width: 88, height: 44,
                backgroundPositionX: "left",
              }}
              resizeMode={"contain"}
              source={THEME_HEADER_LEFT}
            />
          )}
        </View>
      </TouchableOpacity>
    ) : (
      <></>
    );
  };

  return (
    <>
      <View
        style={{
          position: "fixed",
          width: "100%",
          // shadowColor: "#000",
          // shadowOpacity: 1,
          // shadowRadius: 10,
          borderRadius: 8,
          boxShadow: "0 1px 1px 1px rgba(0, 0, 0, 0.3)",
          backgroundColor: MENU_COLOR,
        }}
      >
        <TopNavigation
          alignment="center"
          title={renderTitle}
          accessoryLeft={renderMenuActions}
          accessoryRight={renderInfoAction}
        />
      </View>
      {props.withMenuBurger && (
        <BurgerMenu {...props} ></BurgerMenu>
      )}
    </>
  );
};
