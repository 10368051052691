import React, { Component } from "react";

import { View, Text } from "react-native";
import { withFirebase } from "../../components/Firebase";
import {
  TIME_EXTRA_ANIM,
  TIME_PAUSE,
  BG_TELE,
  TEXT_COLOR,
  THEME,
  TV_TEMPLATE,
  APP_RANKING_TOTAL_TV,
} from "../../constants/const";
import TeleQuizzes from "../../components/Tele/quizzes";
import TeleRanking from "../../components/Tele/ranking";
import TeleSurveys from "../../components/Tele/surveys";
import TeleSurveyResults from "../../components/Tele/surveyResults";
import {
  OPTIONS_QUESTIONS_TV_AND_RESULTS,
  OPTIONS_QUESTIONS_TV,
} from "../../constants/routes";

import MatchWinner from "../../components/Tele/matchWinner";
import TeleHome3 from "../../components/Tele/home3";
import TeleHome2 from "../../components/Tele/home2";
import TeleMatchHome3 from "../../components/Tele/matchHome3";
import TeleMatchHome2 from "../../components/Tele/matchHome2";
import TeleSurveyHome3 from "../../components/Tele/surveyHome3";
import TeleSurveyHome2 from "../../components/Tele/surveyHome2";
import DuelHome from "../../components/Tele/duelHome";
import AsyncHome from "../../components/Tele/asyncHome";
import TeleRankingGlobal from "../../components/Tele/ranking_global";
import TeleHome4 from "../../components/Tele/home4";
import TeleHome5 from "../../components/Tele/home5";
import DuelHome2 from "../../components/Tele/duelHome2";
import TeleHome6 from "../../components/Tele/home6";
import TeleSurveyHome6 from "../../components/Tele/surveyHome6";
import TeleMatchHome6 from "../../components/Tele/matchHome6";
import DuelHome6 from "../../components/Tele/duelHome6";

import en from "../../translations/en.json";
import pt from "../../translations/pt.json";
import fr from "../../translations/fr.json";
import { setDefaultLanguage, setTranslations, withTranslation } from "react-multi-lang";
import TeleHome7 from "../../components/Tele/home7";

class HomeScreen extends Component {
  _isMounted = false;
  currentActive = null;
  defaultScreen = APP_RANKING_TOTAL_TV ? "ranking_global" : "home";
  state = {
    screen: this.defaultScreen,
    updatedNum: 0,
    currentQuiz: null,
    currentQuestion: null,
    currentSurvey: null,
    currentDuel: null,
    timeLeft: 0,
    timeLeftSurvey: 0,
    matchWinners: [],
    liveData: {},
    pronosticsSorted: [],
    ranking_title: null
  };

  constructor(props) {
    super(props);
    this.currentSurvey = null;
    this.currentQuiz = null;
    this.currentAsync = null;
    this.currentDuel = null;
    this.currentMatch = null;
    this.currentQuestion = null;
    this.snapshotQuestion = null;
    this.currentResults = null;
    this.surveyResults = null;
    this.eventFromParam = false;

    this.manuelSnapshot = null;

    this.metaData = require("../../metadata.json");



    // reset télé
    // window.localStorage.clear();

    setTranslations({ fr, en, pt });
    setDefaultLanguage("fr");
    if (["fanquizwz", "wizink"].includes(THEME)) {
      setDefaultLanguage("pt");
    } else if (["challengeic2024"].includes(THEME)) {
      setDefaultLanguage("en");
    }

    if (props.firebase) {

      // get remote config
      props.firebase.remoteConfigs().onSnapshot((configSnapshot) => {
        var lData = configSnapshot.data();
        // console.log("remote config = ", lData);
        // bind data
        if (lData) {
          // Save all key and value of lData to storage local
          for (var key in lData) {
            if (lData.hasOwnProperty(key)) {
              localStorage.setItem(key, lData[key]);
            }
          }
        }
      });

      //check if get event unique
      if (props.navigation) {
        if (
          props.navigation.state.params &&
          props.navigation.state.params.eid
        ) {
          //get only the event from param
          this.eventFromParam = true;
          props.firebase
            .currentEventByIdQuery(props.navigation.state.params.eid)
            .then((query) => {
              this.processQuery(query);
            })
            .catch((err) => {
              console.log("events err:", err);
            });
        } else {
          // get all events
          let test_code = props.navigation.state.params?.code;
          props.firebase
            .currentEventsQuery(10, test_code)
            .then((query) => {
              this.processQuery(query);
            })
            .catch((err) => {
              console.log("events err:", err);
            });
        }
      }

      //check qr tele async
      props.firebase
        .currentAsyncTeleQuery()
        .then((query) => {
          query.onSnapshot(
            (snapshot) => {
              var snapData = [];
              snapshot.forEach((sn) => {
                snapData.push(sn.data());
              });

              //For Async
              var snapDataAsync = snapData.filter((el) => {
                return (
                  el.hasOwnProperty("quiz_id") || el.hasOwnProperty("survey_id")
                );
              });
              if (snapDataAsync.length > 0) {
                snapDataAsync.sort((a, b) => {
                  return a.end.seconds - b.end.seconds;
                });
                // Actually the data should be only 1
                this.currentAsync = snapDataAsync[0];
                if (this.currentAsync.start_delayed) {
                  this.currentAsync.start_display = this.currentAsync.start;
                  this.currentAsync.start = this.currentAsync.start_delayed;
                }
                var cQ = window.localStorage.getItem("currentAsync");
                if (cQ !== null) {
                  var savedQ = JSON.parse(cQ);
                  if (savedQ !== null) {
                    const savedQ_id = `${savedQ.id}_${savedQ.start.seconds}`;
                    const current_id = `${this.currentAsync.id}_${this.currentAsync.start.seconds}`;
                    if (savedQ_id !== current_id) {
                      window.localStorage.removeItem("currentResults");
                    }
                  }
                }
                window.localStorage.setItem(
                  "currentAsync",
                  JSON.stringify(this.currentAsync)
                );
              } else {
                window.localStorage.removeItem("currentAsync");
              }

              this.checkTimeDisplay();
            },
            (err) => {
              console.log(`Encountered error: ${err}`);
            }
          );
        })
        .catch((err) => {
          console.log("events err:", err);
        });

      // check request restart
      props.firebase.requestRestart(THEME).onSnapshot(
        (snapshot) => {
          if (snapshot.exists) {
            this.restartTele(props.firebase);
          }
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    }
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackTimerForEnd = this.callbackTimerForEnd.bind(this);
    this.callbackMatch = this.callbackMatch.bind(this);
  }

  processQuery = (query) => {
    query.onSnapshot(
      (snapshot) => {
        var snapData = [];
        snapshot.forEach((sn) => {
          snapData.push(sn.data());
        });
        // filter only date end > now
        snapData = snapData.filter((el) => {
          return el.end.seconds > new Date().getTime() / 1000;
        });

        //For QUIZ
        var snapDataQuiz = snapData.filter((el) => {
          return el.hasOwnProperty("quiz_id");
        });
        if (snapDataQuiz.length > 0) {
          snapDataQuiz.sort((a, b) => {
            return a.start.seconds - b.start.seconds;
          });
          // Actually the data should be only 1
          this.currentQuiz = snapDataQuiz[0];
          if (this.currentQuiz.start_delayed) {
            this.currentQuiz.start_display = this.currentQuiz.start;
            this.currentQuiz.start = this.currentQuiz.start_delayed;
          }
          if (this.eventFromParam) {
            if (this.props.navigation.state.params.result) {
              this.currentQuiz.queryResult = this.props.navigation.state.params.result;
            }
            this.currentQuiz.start.seconds = Math.floor((Date.now()) / 1000);
            this.currentQuiz.start_delayed.seconds = Math.floor((Date.now()) / 1000);
            this.currentQuiz.start_display = this.currentQuiz.start;
            this.currentQuiz.end.seconds = Math.floor((Date.now() + 1000 * 60 * 60) / 1000);
          }
          var cQ = window.localStorage.getItem("currentQuiz");
          if (cQ !== null) {
            var savedQ = JSON.parse(cQ);
            if (savedQ !== null) {
              const savedQ_id = `${savedQ.id}_${savedQ.start.seconds}`;
              const current_id = `${this.currentQuiz.id}_${this.currentQuiz.start.seconds}`;
              if (savedQ_id !== current_id) {
                window.localStorage.removeItem("currentResults");
              }
            }
          }
          window.localStorage.setItem(
            "currentQuiz",
            JSON.stringify(this.currentQuiz)
          );

          window.localStorage.removeItem("ranking_competition");

          // check quiz play mode manuel
          if (this.currentQuiz.play_mode === "manuel") {
            // watch for changes current question
            this.props.firebase.currentQuestionManuel(`${this.currentQuiz.id}_${this.currentQuiz.start.seconds}`).onSnapshot(
              snapshot => {
                if (snapshot.exists) {
                  let questionManuel = snapshot.data();
                  this.manuelSnapshot = questionManuel;
                  console.log('questionManuel = ', questionManuel);
                  this.checkQuizAndQuestionManuel();
                }
              },
              err => {
                console.log(`Encountered currentQuestionManuel: ${err}`);
              }
            );
          } else {
            this.checkQuizAndQuestion();
          }
        } else {
          window.localStorage.removeItem("currentQuiz");
          window.localStorage.removeItem("currentResults");
          window.localStorage.removeItem("ranking_competition");
          this.currentQuiz = null;
        }

        //For SURVEY
        var snapDataSurvey = snapData.filter((el) => {
          return el.hasOwnProperty("survey_id");
        });

        if (snapDataSurvey.length > 0) {
          snapDataSurvey.sort((a, b) => {
            return a.end.seconds - b.end.seconds;
          });
          this.currentSurvey = snapDataSurvey[0];
          this.currentSurvey.start_display = this.currentSurvey.start;
          if (this.currentSurvey.start_delayed) {
            this.currentSurvey.start = this.currentSurvey.start_delayed;
          }
          window.localStorage.setItem(
            "currentSurvey",
            JSON.stringify(this.currentSurvey)
          );
          this.checkSurveyAndQuestion();
        } else {
          window.localStorage.removeItem("currentSurvey");
          this.currentSurvey = null;
        }

        //For DUEL
        var snapDataDuel = snapData.filter((el) => {
          return el.hasOwnProperty("duel_id");
        });

        if (snapDataDuel.length > 0) {
          snapDataDuel.sort((a, b) => {
            return a.end.seconds - b.end.seconds;
          });
          this.currentDuel = snapDataDuel[0];
          this.currentDuel.start_display = this.currentDuel.start;
          if (this.currentDuel.start_delayed) {
            this.currentDuel.start = this.currentDuel.start_delayed;
          }
          window.localStorage.setItem(
            "currentDuel",
            JSON.stringify(this.currentDuel)
          );
        } else {
          window.localStorage.removeItem("currentDuel");
          this.currentDuel = null;
        }

        //For MATCH
        // Match
        var snapDataMatch = snapData.filter((el) => {
          return el.hasOwnProperty("match_id");
        });

        if (snapDataMatch.length > 0) {
          snapDataMatch.sort((a, b) => {
            return a.end.seconds - b.end.seconds;
          });

          this.currentMatch = snapDataMatch[0];

          window.localStorage.setItem(
            "currentMatch",
            JSON.stringify(this.currentMatch)
          );
        } else {
          console.log("No Match Active!");
          window.localStorage.removeItem("match_forecast");
          this.currentMatch = null;
        }

        this.checkTimeDisplay();
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  restartTele = (fb) => {
    fb.requestRestart(THEME)
      .delete()
      .then((re) => {
        console.log("Delete restart:", THEME);
        window.location.reload(true);
        // window.location.replace("https://" + MAIN_URL + "/tele");
      })
      .catch((error) => {
        console.log("Error deleted:", error);
      });
  };

  componentDidMount() {
    this._isMounted = true;

    this.checkQuizAndQuestion();
    this.checkAnswers();

    var intervalId = setInterval(() => {
      if (this._isMounted)
        this.checkEndTime();
    }, 1000);
    this.setState({ intervalId: intervalId });

    const script = document.createElement("script");
    script.src = "/tutor.js?d=" + new Date().getTime();
    script.async = true;
    document.body.appendChild(script);

    // resquest full screen
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen().catch((er) => {
        console.log("fullscreen err");
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this._isMounted = false;
  }

  checkEndTime() {
    // Check for QUIZ
    // NOTE: check diff time config
    // console.log("checkEndTime");
    const currentNow = Date.now();

    const now = Math.floor(
      (currentNow + parseInt(window.localStorage.getItem("delayTele"), 10)) /
      1000
    );

    // console.log(this.state.screen);
    if (this.state.screen === "ranking") {
      if (this.currentQuiz !== null) {
        // console.log(now - this.currentQuiz.end.seconds);
        const shouldDisplay = window.localStorage.getItem('ranking_competition');
        if (shouldDisplay) {
          // set global ranking
          this.setState({
            screen: 'ranking_global',
            ranking_title: shouldDisplay,
            updatedNum: this.state.updatedNum + 1
          })

        }
        if (now > this.currentQuiz.end.seconds) {
          console.log("looking for next quiz");
          window.location.reload(true);
        }
      }
    }
    if (this.state.screen === "thankyou") {
      if (this.currentSurvey !== null) {
        // console.log(now - this.currentSurvey.end.seconds);
        if (now > this.currentSurvey.end.seconds) {
          console.log("looking for next survey");
          window.location.reload(true);
        }
      }
    }
    if (this.state.screen === "matchWinner") {
      if (this.currentMatch !== null) {
        // console.log(now - this.currentSurvey.end.seconds);
        if (now > this.currentMatch.end_time.seconds) {
          console.log("looking for next match");
          window.location.reload(true);
        }
      }
    }
    if (this.state.screen === "duelHome") {
      if (this.currentDuel !== null) {
        // console.log(now - this.currentSurvey.end.seconds);
        if (now > this.currentDuel.end.seconds) {
          console.log("looking for next Duel");
          window.location.reload(true);
        }
      }
    }
    if (this.state.screen === "ranking_global") {
      if (this.currentQuiz !== null) {
        if (now > this.currentQuiz.end.seconds) {
          console.log("looking for next quiz");
          //remove
          window.localStorage.removeItem('ranking_competition');
          window.location.reload(true);
        }
      } else {
        if (this.currentActive) {
          // console.log("currentActive = ", this.currentActive);
          if (now > this.currentActive.start_display) {
            //remove
            window.localStorage.removeItem('ranking_competition');
            this.checkTimeDisplay();
          }
        }
      }
    }
  }

  checkTimeDisplay() {
    console.log("checkTimeDisplay");
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
      1000
    );

    this.currentActive = null;
    const currentScreen = this.state.screen;
    var shouldDisplayScreen = currentScreen;

    var out = [];
    if (this.currentDuel) {
      out.push({
        type: "duel",
        start: this.currentDuel.start.seconds,
        start_display: this.currentDuel.start_display.seconds,
      });
    }
    if (this.currentQuiz && this.currentQuiz.name !== "") {
      out.push({
        type: "quiz",
        start: this.currentQuiz.start.seconds,
        start_display: this.currentQuiz.start_display?.seconds || this.currentQuiz.start.seconds,
      });
    }
    if (this.currentSurvey && this.currentSurvey.name !== "") {
      out.push({
        type: "survey",
        start: this.currentSurvey.start.seconds,
        start_display: this.currentSurvey.start_display.seconds,
      });
    }
    if (this.currentAsync) {
      out.push({
        type: "async",
        start: this.currentAsync.start.seconds,
        start_display: this.currentAsync.start_display.seconds,
      });
    }
    out.sort((a, b) => {
      return a.start - b.start;
    });

    if (out.length > 0) {
      this.currentActive = out[0];

      switch (this.currentActive.type) {
        case "duel":
          shouldDisplayScreen = "duelHome";
          break;
        case "async":
          shouldDisplayScreen = "asyncHome";
          break;
        case "survey":
          shouldDisplayScreen = "surveyHome";
          if (now >= this.currentActive.start) {
            if (this.currentQuestion == null) {
              shouldDisplayScreen = "thankyou";
            } else {
              shouldDisplayScreen = "surveys";
            }
          } else {
            shouldDisplayScreen = "surveyHome";
          }
          break;
        default:
          // quiz

          if (now >= this.currentActive.start_display) {
            shouldDisplayScreen = "home";
            if (now >= this.currentActive.start) {
              if (this.currentQuestion == null && this.currentQuiz.play_mode !== 'manuel') {
                shouldDisplayScreen = "ranking";
              } else {
                shouldDisplayScreen = "quizzes";
              }
            }
          } else {
            shouldDisplayScreen = this.defaultScreen;
          }
          break;
      }
    } else {
      shouldDisplayScreen = this.defaultScreen;
    }

    if (this.currentMatch !== null) {
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
        1000
      );
      if (this.currentActive === null) {
        shouldDisplayScreen = "matchHome";
      } else {
        if (this.currentActive.start_delayed) {
          if (
            !(
              now >= this.currentActive.start_delayed.seconds &&
              now <= this.currentActive.end.seconds
            )
          ) {
            shouldDisplayScreen = "matchHome";
          }
        }
        // else {
        //   shouldDisplayScreen = "matchHome";
        // }
      }
    }

    if (this.eventFromParam) {
      if (this.currentActive && this.currentActive.end) {
        this.currentActive.end.seconds = (Date.now() + 1000 * 60 * 60) / 1000;
      }
      if (this.currentMatch) {
        this.currentMatch.end.seconds = (Date.now() + 1000 * 60 * 60) / 1000;
      }
    }

    if (shouldDisplayScreen.includes("match")) {
      this.setState({
        screen: shouldDisplayScreen,
        timeLeft: this.currentMatch ? this.currentMatch.start.seconds - now : 0,
        currentMatch: this.currentMatch,
        currentQuiz: this.currentQuiz,
        currentSurvey: this.currentSurvey,
        currentDuel: this.currentDuel,
        currentAsync: this.currentAsync,
        updatedNum: this.state.updatedNum + 1,
      });
    } else {
      this.setState({
        screen: shouldDisplayScreen,
        currentMatch: this.currentMatch,
        currentQuiz: this.currentQuiz,
        currentSurvey: this.currentSurvey,
        currentDuel: this.currentDuel,
        currentAsync: this.currentAsync,
        timeLeft:
          this.currentActive !== null ? this.currentActive.start - now : 0,
        updatedNum: this.state.updatedNum + 1,
      });

      console.log("state = ", {
        screen: shouldDisplayScreen,
        currentMatch: this.currentMatch,
        currentQuiz: this.currentQuiz,
        currentSurvey: this.currentSurvey,
        currentDuel: this.currentDuel,
        currentAsync: this.currentAsync,
        timeLeft:
          this.currentActive !== null ? this.currentActive.start - now : 0,
        updatedNum: this.state.updatedNum + 1,
      });
    }



    if (this.currentMatch === null && this.currentActive === null) {
      // refresh and clear cache, reset
      this.setState({
        screen: this.defaultScreen,
        updatedNum: 0,
        currentMatch: this.currentMatch,
        currentQuiz: this.currentQuiz,
        currentSurvey: this.currentSurvey,
        currentAsync: this.currentAsync,
        timeLeft: 0,
      });
    }

    console.log("this.currentActive = ", this.currentActive);
  }

  callbackMatch(dataPronos, dataLive, fullList) {
    this.setState({
      screen: "matchWinner",
      matchWinners: dataPronos,
      liveData: dataLive,
      pronosticsSorted: fullList,
      updatedNum: this.state.updatedNum + 1,
    });
  }

  callbackTimerForEnd(val) {
    if (val <= 0) {
      this.setState({ screen: "thankyou" });
    }
  }

  callbackTimer(val) {
    //Quizzes started
    // console.log(val);
    if (val <= 0) {
      if (this.state.screen === "surveyHome") {
        if (
          this.currentSurvey.display_type ===
          OPTIONS_QUESTIONS_TV_AND_RESULTS ||
          this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV
        ) {
          this.checkSurveyAndQuestion(true);
          // this.setState({ screen: "surveys" });
        }
      } else if (this.state.screen === "home") {
        if (this.currentQuiz !== null) {
          if (this.currentQuiz.play_mode === 'manuel') {
            this.checkQuizAndQuestionManuel(true);
          } else {
            this.checkQuizAndQuestion(true);
          }
          // this.setState({ screen: "quizzes" });
        }
      } else if (this.state.screen === "matchHome") {
        console.log("should display match");
      }
    } else {
      this.checkTimer(val);
    }
  }

  checkTimer = (val) => {
    // console.log(val);
    if (
      this.currentQuiz !== null ||
      this.currentDuel !== null ||
      this.currentSurvey !== null ||
      this.currentMatch !== null
    ) {
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
        1000
      );
      var timeLeft = 0;
      if (this.state.screen === "surveyHome") {
        if (this.currentSurvey === null) {
          this.checkTimeDisplay();
          return;
        } else {
          timeLeft = this.currentSurvey.start.seconds - now;
        }
      } else if (this.state.screen === "home") {
        if (this.currentQuiz === null) {
          this.checkTimeDisplay();
          return;
        } else {
          timeLeft =
            this.currentQuiz !== null
              ? this.currentQuiz.start.seconds - now
              : 10;
        }
      } else if (this.state.screen === "duelHome") {
        if (this.currentDuel === null) {
          this.checkTimeDisplay();
          return;
        } else {
          timeLeft =
            this.currentDuel !== null
              ? this.currentDuel.start.seconds - now
              : 10;
        }
      } else if (this.state.screen === "matchHome") {
        timeLeft =
          this.currentMatch !== null
            ? this.currentMatch.start.seconds - now
            : 0;
        this.checkTimeDisplay();
      }
      // console.log(Math.abs(val - timeLeft));
      if (Math.abs(val - timeLeft) > 1) {
        this.setState({
          timeLeft: timeLeft,
          updatedNum: this.state.updatedNum + 1,
        });
      }
      // console.log("sycned timer");
      // console.log(timeLeft);
    }
  };

  checkSurveyAndQuestion = (needRedirect) => {
    if (this.currentSurvey !== null) {
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
        1000
      );
      const timeLeft = this.currentSurvey.start.seconds - now;
      const isStarted = timeLeft <= 0;
      if (isStarted) {
        // Process questions base on time
        var { questions } = this.currentSurvey;
        questions = questions.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });
        var timePast = this.currentSurvey.start.seconds;
        this.currentQuestion = null;
        var time_pause = 0;
        if (
          this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS
        ) {
          time_pause = TIME_PAUSE;
        }
        for (let i = 0; i < questions.length; i++) {
          const question = questions.filter((e) => {
            return e.order === i + 1;
          })[0];
          const qTime = question ? question.time : 0;
          if (i === 0) {
            timePast = timePast + qTime + TIME_EXTRA_ANIM;
          } else {
            timePast = timePast + time_pause + qTime + TIME_EXTRA_ANIM;
          }
          const now = Math.floor(
            (Date.now() +
              parseInt(window.localStorage.getItem("delayTele"), 10)) /
            1000
          );
          if (now < timePast) {
            this.currentQuestion = question;
            this.currentQuestion.timeLeft = timePast - now;
            this.currentQuestion.timeEnd = timePast;
            break;
          }
        }
        if (this.currentQuestion == null) {
          // Finish Quiz, no more question, should go to thankyou
          // console.log("go thankyou");
          if (
            this.state.screen === "surveys" ||
            this.state.screen === "surveyHome"
          ) {
            this.setState({
              screen: "thankyou",
              currentSurvey: this.currentSurvey,
            });
            // console.log("go thankyou setted");
          }
        }
      }

      //Only need to check the respones if the result is display on TV
      if (
        this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS
      ) {
        this.checkResponses();
      }
      // console.log("checkSurveyQuestion");
      if (needRedirect) {
        this.setState({
          currentSurvey: this.currentSurvey,
          currentQuestion: this.currentQuestion,
          timeLeft: timeLeft,
          screen: "surveys",
        });
      } else {
        this.setState({
          currentSurvey: this.currentSurvey,
          currentQuestion: this.currentQuestion,
          timeLeft: timeLeft,
        });
      }

      // }
    } else {
      // navigate to screen no QUIZ
      // this.props.navigation.navigate("NoQuiz");
    }
  };

  checkQuizAndQuestion = (needRedirect) => {
    if (this.currentQuiz !== null) {
      if (this.currentQuiz.play_mode === 'manuel') {
        return;
        // this.checkQuizAndQuestionManuel();

      }
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
        1000
      );
      const timeLeft = this.currentQuiz.start.seconds - now;
      const isStarted = timeLeft <= 0;
      if (isStarted) {
        // Process questions base on time
        var { questions } = this.currentQuiz;
        questions = questions.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });
        var timePast = this.currentQuiz.start.seconds;
        this.currentQuestion = null;
        for (let i = 0; i < questions.length; i++) {
          const question = questions.filter((e) => {
            return e.order === i + 1;
          })[0];
          const qTime = question ? question.time : 0;
          if (question) {
            if (i === 0) {
              timePast = timePast + qTime + TIME_EXTRA_ANIM;
            } else {
              timePast = timePast + TIME_PAUSE + qTime + TIME_EXTRA_ANIM;
            }
            const timePastWithResult = timePast + TIME_PAUSE + TIME_EXTRA_ANIM;
            const now = Math.floor(
              (Date.now() +
                parseInt(window.localStorage.getItem("delayTele"), 10)) /
              1000
            );
            if (now <= timePastWithResult) {
              this.currentQuestion = question;
              this.currentQuestion.timeLeft = timePast - now;
              this.currentQuestion.timeEnd = timePast;
              this.currentQuestion.displayResult = now > timePast;
              this.currentQuestion.displayResultTimeLeft =
                timePastWithResult - now;
              break;
            }
          }
        }
        this.checkAnswers();
        if (this.currentQuestion == null) {
          // Finish Quiz, no more question, should go to ranking
          if (this.state.screen === "home" || this.state.screen === "quizzes")
            this.setState({ screen: "ranking", currentQuiz: this.currentQuiz });
        }
      }

      // console.log("checkQuizAndQuestion");
      if (needRedirect) {
        this.setState({
          currentQuiz: this.currentQuiz,
          currentQuestion: this.currentQuestion,
          displayResult: this.currentQuestion
            ? this.currentQuestion.displayResult
            : false,
          timeLeft: timeLeft,
          screen: "quizzes",
        });
      } else {
        this.setState({
          currentQuiz: this.currentQuiz,
          currentQuestion: this.currentQuestion,
          displayResult: this.currentQuestion
            ? this.currentQuestion.displayResult
            : false,
          timeLeft: timeLeft,
        });
      }

      if (this.currentQuestion && this.props.firebase) {
        if (this.snapshotQuestion) {
          //unsnapshot
          this.snapshotQuestion();
        }
        let _queryString = `${this.currentQuiz.id}_${this.currentQuiz.start.seconds}`;
        if (this.eventFromParam) {
          if (this.currentQuiz.queryResult)
            _queryString = `${this.currentQuiz.queryResult}`;
        }
        // then snap again
        this.snapshotQuestion = this.props.firebase
          .currentQuestionQuizResults(
            _queryString,
            this.currentQuestion.id
          )
          .onSnapshot(
            (snapshot) => {
              if (snapshot.exists) {
                this.currentQuestionResult = snapshot.data();
                window.localStorage.setItem(
                  "questionResults",
                  JSON.stringify(this.currentQuestionResult)
                );
              }
            },
            (err) => {
              console.log(`Encountered error: ${err}`);
            }
          );
      }

      // }
    } else {
      // navigate to screen no QUIZ
      // this.props.navigation.navigate("NoQuiz");
    }
  };

  checkQuizAndQuestionManuel = (needRedirect) => {
    console.log("checkQuizAndQuestionManuel");
    if (this.currentQuiz !== null) {
      if (this.manuelSnapshot === null) return;
      console.log('should return ', this.currentQuestion?.displayResultTimeLeft === 0 && this.currentQuestion?.displayResult && this.currentQuestion.isLastOne)
      if (this.currentQuestion?.displayResultTimeLeft === 0 && this.currentQuestion?.displayResult && this.currentQuestion.isLastOne) {
        // Finish Quiz, no more question, should go to ranking
        if (this.state.screen === "home" || this.state.screen === "quizzes")
          this.setState({ screen: "ranking", currentQuiz: this.currentQuiz });
        return;
      }
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
        1000
      );
      let timeLeft = this.manuelSnapshot.start.seconds - now;
      console.log("timeLeft", timeLeft);
      // const isStarted = timeLeft <= 0;
      // if (isStarted) {
      // Process questions base on time
      var { questions } = this.currentQuiz;
      var timePast = this.manuelSnapshot.start.seconds;
      this.currentQuestion = null;


      const question = questions.filter(e => {
        return e.id === this.manuelSnapshot.id;
      })[0];

      const qTime = question ? question.time : 0;
      if (question) {

        timePast = timePast + qTime;

        const timePastWithResult = timePast + TIME_PAUSE;
        const now = Math.floor(
          (Date.now() +
            parseInt(window.localStorage.getItem("delayTele"), 10)) /
          1000
        );

        this.currentQuestion = question;
        this.currentQuestion.timeLeft = timePast - now;
        this.currentQuestion.displayResult = now > timePast;
        this.currentQuestion.displayResultTimeLeft =
          timePastWithResult - now > 0 ? timePastWithResult - now : 0;
        this.currentQuestion.timeEnd = timePast;
        timeLeft = this.currentQuestion.displayResult ? this.currentQuestion.displayResultTimeLeft : this.currentQuestion.timeLeft;
      }

      // if(this.currentQuestion.displayResult){
      this.checkAnswers();
      // }


      let questionIds = (questions.sort((a, b) => a.order - b.order)).map(q => q.id);
      let indexCurrentQuestion = questionIds.indexOf(this.currentQuestion?.id);
      let isLastQuestion = (indexCurrentQuestion === questionIds.length - 1);
      console.log('isLastQuestion = ', isLastQuestion, ' indexCurrentQuestion = ', indexCurrentQuestion);
      console.log('timeLeft = ', timeLeft);
      console.log('this.currentQuestion = ', this.currentQuestion);
      console.log('show ranking = ', this.currentQuestion?.displayResultTimeLeft === 0 && this.currentQuestion?.displayResult && isLastQuestion);
      console.log('this.state.screen : ', this.state.screen);

      this.currentQuestion.isLastOne = isLastQuestion;
      // }

      if (needRedirect) {
        this.setState({
          currentQuiz: this.currentQuiz,
          currentQuestion: this.currentQuestion,
          displayResult: this.currentQuestion
            ? this.currentQuestion.displayResult
            : false,
          timeLeft: timeLeft,
          screen: "quizzes",
          updatedNum: this.state.updatedNum + 1
        });
      } else {
        this.setState({
          currentQuiz: this.currentQuiz,
          currentQuestion: this.currentQuestion,
          displayResult: this.currentQuestion
            ? this.currentQuestion.displayResult
            : false,
          timeLeft: timeLeft,
          updatedNum: this.state.updatedNum + 1
        });
      }

      if (this.currentQuestion && this.props.firebase) {
        if (this.snapshotQuestion) {
          //unsnapshot
          this.snapshotQuestion();
        }
        let _queryString = `${this.currentQuiz.id}_${this.currentQuiz.start.seconds}`;
        if (this.eventFromParam) {
          if (this.currentQuiz.queryResult)
            _queryString = `${this.currentQuiz.queryResult}`;
        }
        // then snap again
        this.snapshotQuestion = this.props.firebase
          .currentQuestionQuizResults(
            _queryString,
            this.currentQuestion.id
          )
          .onSnapshot(
            (snapshot) => {
              if (snapshot.exists) {
                this.currentQuestionResult = snapshot.data();
                window.localStorage.setItem(
                  "questionResults",
                  JSON.stringify(this.currentQuestionResult)
                );
              }
            },
            (err) => {
              console.log(`Encountered error: ${err}`);
            }
          );
      }

      // }
    } else {
      // navigate to screen no QUIZ
      // this.props.navigation.navigate("NoQuiz");
    }
  };

  updateRankingData = (arrAnswers) => {
    if (arrAnswers !== null && this.currentQuiz !== null) {
      window.localStorage.setItem("currentResults", JSON.stringify(arrAnswers));
      this.currentResults = arrAnswers;
      // save and display ranking
      if (this._isMounted) {
        // if(this.currentQuiz.play_mode !== 'manuel'){
        //   this.setState({ updatedNum: this.state.updatedNum + 1, currentResults: this.currentResults });
        // }
      }
    }
  };

  checkAnswers = () => {
    // NOTE: Change: only read result from firebase
    if (this.currentQuiz !== null) {
      let _queryString = `${this.currentQuiz.id}_${this.currentQuiz.start.seconds}`;
      if (this.eventFromParam) {
        if (this.currentQuiz.queryResult)
          _queryString = `${this.currentQuiz.queryResult}`;
      }
      console.log("_queryString = ", _queryString);
      this.props.firebase
        .currentAnswersQuery(
          _queryString,
          ["challengeic2024", "35ansrecrea", "takeover"].includes(THEME) ? 2000 : 10
        )
        .onSnapshot(
          (snapshot) => {
            var arrRanking = [];
            snapshot.forEach((data) => {
              arrRanking.push(data.data());
            });

            this.updateRankingData(arrRanking);
          },
          (err) => {
            console.log(`Encountered error: ${err}`);
          }
        );
    }
  };

  checkResponses = () => {
    if (this.currentSurvey !== null) {
      this.props.firebase
        .currentResponeseQuery(`${this.currentSurvey.id}`)
        .onSnapshot(
          (snapshot) => {
            // console.log("snapshot.size=", snapshot);
            if (snapshot.size <= 0) {
              window.localStorage.removeItem("currentSurveyAnwsers");
              window.localStorage.removeItem("currentSurveyResults");
            }
            var arrDataQ = [];
            snapshot.forEach((data) => {
              arrDataQ.push(data.data());
            });
            window.localStorage.setItem(
              "currentSurveyResults",
              JSON.stringify(arrDataQ)
            );
            this.surveyResults = arrDataQ;
          },
          (err) => {
            console.log(`Encountered error: ${err}`);
          }
        );
    }
  };

  render() {
    console.log('TV template = ', TV_TEMPLATE, this.state);
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: this.props.navigation.state.params
            ? this.props.navigation.state.params.bgc
              ? this.props.navigation.state.params.bgc
              : BG_TELE
            : BG_TELE,
        }}
      >
        {/* {["hasard-fdj"].includes(THEME) && ["quizzes", "ranking"].includes(this.state.screen) && <audio autoPlay loop>
          <source src="https://storage.googleapis.com/take-over-loges.appspot.com/hasard_ee8367a4ca/hasard_ee8367a4ca.mp3" />
        </audio>} */}
        {this.state.screen === "asyncHome" && (
          <AsyncHome
            key={this.state.updatedNum}
            {...this.props}
            currentAsync={this.state.currentAsync}
            timeLeft={this.state.timeLeft}
            callbackTimer={this.callbackTimer}
          />
        )}

        {this.state.screen === "duelHome" &&
          (`${TV_TEMPLATE}` !== '2' && `${TV_TEMPLATE}` !== '5') &&
          (<DuelHome
            key={this.state.updatedNum}
            {...this.props}
            currentDuel={this.state.currentDuel}
            timeLeft={this.state.timeLeft}
            callbackTimer={this.callbackTimer}
            callbackDuel={this.callbackDuel}
          />
          )}
        {this.state.screen === "duelHome" &&
          (`${TV_TEMPLATE}` === '2') && (
            <DuelHome2
              key={this.state.updatedNum}
              {...this.props}
              currentDuel={this.state.currentDuel}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
              callbackDuel={this.callbackDuel}
            />
          )}
        {this.state.screen === "duelHome" &&
          (`${TV_TEMPLATE}` === '5') && (
            <DuelHome6
              key={this.state.updatedNum}
              {...this.props}
              currentDuel={this.state.currentDuel}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
              callbackDuel={this.callbackDuel}
            />
          )}
        {this.state.screen === "matchHome" &&
          `${TV_TEMPLATE}` === '1' && (
            <TeleMatchHome2
              key={this.state.updatedNum}
              {...this.props}
              currentMatch={this.state.currentMatch}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
              callbackMatch={this.callbackMatch}
            />
          )}
        {this.state.screen === "matchHome" &&
          (`${TV_TEMPLATE}` === '2' || `${TV_TEMPLATE}` === '3' || `${TV_TEMPLATE}` === '4') && (
            <TeleMatchHome3
              key={this.state.updatedNum}
              {...this.props}
              currentMatch={this.state.currentMatch}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
              callbackMatch={this.callbackMatch}
            />
          )}
        {this.state.screen === "matchHome" &&
          `${TV_TEMPLATE}` === '5' && (
            <TeleMatchHome6
              key={this.state.updatedNum}
              {...this.props}
              currentMatch={this.state.currentMatch}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
              callbackMatch={this.callbackMatch}
            />
          )}
        {this.state.screen === "matchWinner" && (
          <MatchWinner
            key={this.state.updatedNum}
            {...this.props}
            currentMatch={this.state.currentMatch}
            matchWinners={this.state.matchWinners}
            liveData={this.state.liveData}
            pronosticsSorted={this.state.pronosticsSorted}
          />
        )}
        {this.state.screen === "surveyHome" &&
          `${TV_TEMPLATE}` === '1' && (
            <TeleSurveyHome2
              key={this.state.updatedNum}
              {...this.props}
              currentSurvey={this.state.currentSurvey}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
              callbackTimerForEnd={this.callbackTimerForEnd}
            />
          )}
        {this.state.screen === "surveyHome" &&
          (`${TV_TEMPLATE}` === '2' || `${TV_TEMPLATE}` === '3' || `${TV_TEMPLATE}` === '4') && (
            <TeleSurveyHome3
              key={this.state.updatedNum}
              {...this.props}
              currentSurvey={this.state.currentSurvey}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
              callbackTimerForEnd={this.callbackTimerForEnd}
            />
          )}

        {this.state.screen === "surveyHome" &&
          `${TV_TEMPLATE}` === '5' && (
            <TeleSurveyHome6
              key={this.state.updatedNum}
              {...this.props}
              currentSurvey={this.state.currentSurvey}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
              callbackTimerForEnd={this.callbackTimerForEnd}
            />
          )}

        {this.state.screen === "surveys" && (
          <TeleSurveys
            key={this.state.timeLeft}
            currentSurvey={this.state.currentSurvey}
            currentQuestion={this.state.currentQuestion}
            checkSurveyAndQuestion={this.checkSurveyAndQuestion}
          />
        )}
        {this.state.screen === "thankyou" && (
          <TeleSurveyResults
            key={this.state.updatedNum}
            currentSurvey={this.state.currentSurvey}
            {...this.props}
          />
        )}
        {this.state.screen === "home" &&
          `${TV_TEMPLATE}` === '1' && (
            <TeleHome2
              key={this.state.timeLeft}
              {...this.props}
              currentQuiz={this.state.currentQuiz}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
            />
          )}
        {this.state.screen === "home" &&
          (`${TV_TEMPLATE}` === '2') && (
            <TeleHome3
              key={this.state.timeLeft}
              {...this.props}
              currentQuiz={this.state.currentQuiz}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
            />
          )}
        {this.state.screen === "home" &&
          (`${TV_TEMPLATE}` === '3') && (
            <TeleHome4
              key={this.state.timeLeft}
              {...this.props}
              currentQuiz={this.state.currentQuiz}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
            />
          )}

        {this.state.screen === "home" &&
          (`${TV_TEMPLATE}` === '4') && (
            <TeleHome5
              key={this.state.timeLeft}
              {...this.props}
              currentQuiz={this.state.currentQuiz}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
            />
          )}

        {this.state.screen === "home" &&
          (`${TV_TEMPLATE}` === '5') && (
            <TeleHome6
              key={this.state.timeLeft}
              {...this.props}
              currentQuiz={this.state.currentQuiz}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
            />
          )}

        {this.state.screen === "home" &&
          (`${TV_TEMPLATE}` === '6') && (
            <TeleHome7
              key={this.state.timeLeft}
              {...this.props}
              currentQuiz={this.state.currentQuiz}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackTimer}
            />
          )}

        {this.state.screen === "quizzes" && (
          <TeleQuizzes
            key={`${this.state.updatedNum}_${this.state.timeLeft}`}
            currentQuiz={this.state.currentQuiz}
            currentQuestion={this.state.currentQuestion}
            checkQuizAndQuestionManuel={this.checkQuizAndQuestionManuel}
            checkQuizAndQuestion={this.checkQuizAndQuestion}
            currentResults={this.state.currentResults}
          />
        )}
        {this.state.screen === "ranking" && (
          <TeleRanking
            key={this.state.updatedNum}
            currentQuiz={this.state.currentQuiz}
            {...this.props}
          />
        )}
        {this.state.screen === "ranking_global" && (
          <TeleRankingGlobal key={this.state.updatedNum} {...this.props} ranking_title={this.state.ranking_title} />
        )}

        <Text
          style={{
            fontSize: 10,
            fontStyle: "italic",
            color: TEXT_COLOR,
            position: "fixed",
            bottom: 0,
            right: 0,
          }}
        >
          WisslMedia {this.metaData.version || "1.1.0"} - b
          {this.metaData.build || "01"}
        </Text>
      </View>
    );
  }
}

export default withFirebase(withTranslation(HomeScreen));
