import React from "react";
import {
  Text,
  Button,
  Icon,
  Divider,
  Modal,
  Card,
} from "@ui-kitten/components";
import { StyleSheet, Image } from "react-native";
import {
  SHOW_RANKING,
  THEME_MENU_LOGO,
  SHOW_CALENDER,
  THEME,
  APP_RANKING_TOTAL,
  APP_RANKING_QUIZ,
  APP_RANKING_FORECAST,
  THEME_TEXT_MENU,
} from "../../constants/const";
import ReactHtmlParser from 'react-html-parser';
import { withTranslation, t } from "react-multi-lang";
import { View } from "react-native-web";

const RankingIcon = (props) => <Icon {...props} name="bar-chart" />;
const CircleIcon = (props) => <Icon {...props} name="radio-button-on" />;
const CalenderIcon = (props) => <Icon {...props} name="calendar" />;
const HomeIcon = (props) => <Icon {...props} name="home-outline" />;
const LogoutIcon = (props) => <Icon {...props} name="log-out" />;

class MoreElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: props.isOpen,
      modalVisible: false,
      // profile : profileJSON
    };
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  doLogout() {
    window.localStorage.clear();
    this.props.firebase.doSignOut();
    this.setState({ modalVisible: false });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu(callback) {
    this.setState({ menuOpen: false }, () => {
      if (callback) {
        setTimeout(() => {
          callback();
        }, 500);
      }
    });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  showSettings(event) { }

  render() {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    // console.log("this.props.userData = ", this.state.profile);

    return (

      <View style={styles.container}>
        <View style={{ height: 20 }}></View>
        <Button
          style={styles.button}
          status="control"
          accessoryLeft={HomeIcon}
          appearance={
            this.props.navigation.state.routeName === "HomeInside"
              ? "filled"
              : "ghost"
          }
          onPress={() => {
            if (this.props.firebase && this.props.firebase.analytics) {
              this.props.firebase.analytics.logEvent("button_menu_burger_home_inside", { name: 'button' });
            }
            this.props.navigation.navigate("HomeInside");
          }}
        >
          {t("program")}{THEME === 'challengeca' ? ' PJGS 2022' : ''}
        </Button>

        {SHOW_CALENDER && (
          <Button
            style={styles.button}
            status="control"
            accessoryLeft={CalenderIcon}
            appearance="ghost"
            onPress={() => {
              if (this.props.firebase && this.props.firebase.analytics) {
                this.props.firebase.analytics.logEvent("button_menu_burger_calendar", { name: 'button' });
              }
              this.props.navigation.navigate("Calender");
            }}
          >
            {t("calender")}
          </Button>
        )}
        {SHOW_RANKING && (
          <View>
            <Button
              style={styles.button}
              status="control"
              accessoryLeft={RankingIcon}
              appearance="ghost"
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_menu_burger_ranking_total", { name: 'button' });
                }
                if (APP_RANKING_TOTAL) {
                  this.props.navigation.navigate("RankingTotal");
                }
              }}
            >
              {t("rankings")}
            </Button>
            {APP_RANKING_TOTAL && (
              <Button
                style={styles.buttonDetail}
                status="control"
                accessoryLeft={CircleIcon}
                appearance={
                  this.props.navigation.state.routeName === "RankingTotal"
                    ? "filled"
                    : "ghost"
                }
                onPress={() => {
                  if (this.props.firebase && this.props.firebase.analytics) {
                    this.props.firebase.analytics.logEvent("button_menu_burger_ranking_total", { name: 'button' });
                  }
                  this.props.navigation.navigate("RankingTotal");
                }}
              >
                {t("general")}
              </Button>
            )}
            {APP_RANKING_QUIZ &&
              (
                <Button
                  style={styles.buttonDetail}
                  status="control"
                  accessoryLeft={CircleIcon}
                  appearance={
                    this.props.navigation.state.routeName === "RankingQuiz"
                      ? "filled"
                      : "ghost"
                  }
                  onPress={() => {
                    if (this.props.firebase && this.props.firebase.analytics) {
                      this.props.firebase.analytics.logEvent("button_menu_burger_ranking_quiz", { name: 'button' });
                    }
                    this.props.navigation.navigate("RankingQuiz");
                  }}
                >
                  {THEME === "challengefff" || THEME === "challengeca" ? "Quiz / Blind Test" : t("quiz")}
                </Button>
              )}
            {APP_RANKING_FORECAST && (
              <Button
                style={styles.buttonDetail}
                status="control"
                accessoryLeft={CircleIcon}
                appearance={
                  this.props.navigation.state.routeName ===
                    "RankingForecast"
                    ? "filled"
                    : "ghost"
                }
                onPress={() => {
                  if (this.props.firebase && this.props.firebase.analytics) {
                    this.props.firebase.analytics.logEvent("button_menu_burger_ranking_pronostic", { name: 'button' });
                  }
                  this.props.navigation.navigate("RankingForecast");
                }}
              >
                {t("forecast")}
              </Button>
            )}
          </View>
        )}
        <Divider style={styles.deviderBottom} />
        {THEME_TEXT_MENU() &&
          ReactHtmlParser(`${THEME_TEXT_MENU()}`)}
        {this.props.userData.displayName && (
          <>
            <Button
              style={styles.button}
              status="control"
              accessoryLeft={LogoutIcon}
              appearance="ghost"
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_menu_burger_logout", { name: 'button' });
                }
                this.setState({ modalVisible: true });
              }}
            >
              {t("logout")}
            </Button>
            <Divider style={styles.deviderBottom} />
          </>
        )}
        {THEME_MENU_LOGO && THEME_MENU_LOGO !== "null" &&
          <Image
            style={{
              top: 50,
              height: 150,
              width: 180
            }}
            resizeMode={"contain"}
            source={THEME_MENU_LOGO}
          />}


        <Modal
          style={styles.modal}
          visible={this.state.modalVisible}
          backdropStyle={styles.backdrop}
          onBackdropPress={() => this.setState({ modalVisible: false })}
        >
          <Card disabled={true}>
            <Text>{t("logout")} ?</Text>
            <View style={styles.rowButtons}>
              <Button
                style={{ margin: 5 }}
                onPress={() => {
                  if (this.props.firebase && this.props.firebase.analytics) {
                    this.props.firebase.analytics.logEvent("button_logout_no", { name: 'button' });
                  }
                  this.setState({ modalVisible: false });
                }}
              >
                {t("no")}
              </Button>
              <Button
                style={{ margin: 5 }}
                status="danger"
                onPress={() => {
                  if (this.props.firebase && this.props.firebase.analytics) {
                    this.props.firebase.analytics.logEvent("button_logout_yes", { name: 'button' });
                  }
                  this.doLogout();
                }}
              >
                {t("yes")}
              </Button>
            </View>
          </Card>
        </Modal>
      </View>

    );
  }
}


const styles = StyleSheet.create({
  container: {
    paddingBottom: 80,
    alignSelf: "stretch",
  },
  modal: {},
  backdrop: {
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  rowHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowButtons: {
    flexDirection: "row",
    marginTop: 30,
    justifyContent: "space-between",
  },
  devider: {
    backgroundColor: "#fff",
    marginTop: 5,
    marginBottom: 20,
  },
  deviderBottom: {
    backgroundColor: "#fff",
    marginTop: 5,
    marginBottom: 5,
  },
  textHeader: {
    color: "#ffffff",
  },
  button: {
    color: "white",
    justifyContent: "left",
    alignItems: "flex-start",
  },
  buttonDetail: {
    color: "white",
    justifyContent: "left",
    alignItems: "flex-start",
    left: 30,
  },
  buttonHeader: {
    color: "white",
    justifyContent: "space-between",
    activeOpacity: 0.5,
    backfaceVisibility: "visible",
  },
  textWrapper: {
    position: "fixed",
    flex: 1,
    bottom: 10,
    left: 10,
    right: "20%",
  },
  textFooter: {
    color: "white",
    fontSize: 8,
    textAlign: "center",
  },
});

export default withTranslation(MoreElement);
