import React, { Component } from "react";
import { View, TouchableHighlight } from "react-native";
import { Text, Input, Button } from "@ui-kitten/components";
import RF from "react-native-responsive-fontsize";
import { withFirebase } from "../Firebase";
import LottieView from "react-native-web-lottie";
import {
  withTranslation,
  t
} from "react-multi-lang";
import { TEXT_COLOR, MAIN_URL, SHOW_GAMES, APP_NAVTAB, GO_HOME_INSIDE, PAGE_GAMES } from "../../constants/const";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.currentUser = null;
    this.state = { screen: "forgot" };
    if (props.firebase) {
      props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser != null) {
          //Save prono if needed
          this.currentUser = authUser;
          if (GO_HOME_INSIDE) {
            this.props.navigation.navigate("HomeInside");
          } else {
            if (SHOW_GAMES) {
              this.props.navigation.navigate(PAGE_GAMES());
            } else {
              this.props.navigation.navigate("HomeInside");
            }
          }
        }
      });
    }
  }

  validInput = () => {
    if (!this.state || !this.state.email) {
      return false;
    }
    var re = /\S+@\S+\.\S+/;
    return re.test(String(this.state.email).toLowerCase());
  };

  onValidateClicked = (firebase) => {
    const errMessage = t('email_invalid');
    if (this.validInput()) {
      this.setState({ error: null });
      const email = this.state.email;
      const that = this;
      this.props.firebase.auth
        .sendPasswordResetEmail(email, {
          url: `https://${MAIN_URL}/SignIn`,
          handleCodeInApp: true,
        })
        .then(function () {
          // Email sent.
          // console.log("sent email to : ", email);
          that.setState({ screen: "sent" });
        })
        .catch(function (err) {
          // An error happened.
          that.setState({ error: { message: err.code } });
        });
      // Go to notice screen for checking email
    } else {
      this.setState({ error: { message: errMessage } });
    }
  };

  render() {
    const { navigate } = this.props.navigation;
    return (
      <View
        style={{
          width: this.props.maxWidth,
          flexDirection: "column",
        }}
        keyboardShouldPersistTaps="always"
      >
        {this.state && this.state.screen === "forgot" && (
          <View style={{ padding: 20 }}>
            {(!this.state || !this.state.sentLink) && (
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: RF(2),
                  fontWeight: "bold",
                  marginVertical: 30,
                  textAlign: "center",
                }}
              >
                {t('reset_password')}
              </Text>
            )}
            {(!this.state || !this.state.sentLink) && (
              <Input
                status="control"
                textContentType="emailAddress"
                placeholder={"Email *"}
                placeholderTextColor={"lightgray"}
                onChangeText={(text) => {
                  this.setState({ email: text });
                }}
                value={(this.state && this.state.email) || ""}
              />
            )}

            {(!this.state || !this.state.sentLink) && (
              <Button
                style={{ margin: 60, borderRadius: 25, textTransform: 'uppercase' }}
                onPress={() => {
                  this.onValidateClicked();
                }}
              >
                {t('submit')}
              </Button>
            )}

            {this.state && this.state.error && (
              <Text
                style={{
                  color: "white",
                  margin: 10,
                  textAlign: "center",
                }}
              >
                --{" "}
                {this.state.error.message === "auth/user-not-found"
                  ? t('email_not_found')
                  : this.state.error.message === "auth/wrong-password"
                  ? t('wrong_password')
                  : this.state.error.message}{" "}
                --
              </Text>
            )}
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                marginTop: 50,
                justifyContent: "space-between",
              }}
            >
              <TouchableHighlight
                style={{ alignSelf: "flex-start" }}
                onPress={() => navigate("Home")}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    textAlign: "center",
                    padding: 10,
                    borderRadius: 25,
                    textDecorationLine: 'underline'
                  }}
                >
                  <i>{SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}</i>
                </Text>
              </TouchableHighlight>
              <TouchableHighlight
                style={{ alignSelf: "flex-end" }}
                onPress={() => navigate("SignIn")}
              >
                <Text
                  style={{
                    color: this.props.navigation.state.params
                      ? this.props.navigation.state.params.bc
                        ? this.props.navigation.state.params.bc
                        : TEXT_COLOR
                      : TEXT_COLOR,
                    textAlign: "center",
                    padding: 10,
                    paddingLeft: 30,
                    borderRadius: 25,
                    textDecorationLine: 'underline'
                  }}
                >
                  <i>{t('signin')}</i>
                </Text>
              </TouchableHighlight>
            </View>
          </View>
        )}

        {this.state && this.state.screen === "sent" && (
          <View
            style={{
              padding: 20,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LottieView
              style={{ height: 80 }}
              source={require("../../assets/anim/email.json")}
              autoPlay
              loop
            />

            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: RF(2),
                fontWeight: "normal",
                margin: 10,
                textAlign: "center",
              }}
            >
              {t('email_forgot_sent')}
            </Text>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                marginTop: 50,
                justifyContent: "space-between",
              }}
            >
              <TouchableHighlight
                style={{ alignSelf: "flex-start" }}
                onPress={() => navigate("Home")}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    textAlign: "center",
                    padding: 10,
                    borderRadius: 25,
                    textDecorationLine: 'underline'
                  }}
                >
                  <i>{SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}</i>
                </Text>
              </TouchableHighlight>
              <TouchableHighlight
                style={{ alignSelf: "flex-end" }}
                onPress={() => navigate("SignIn")}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    textAlign: "center",
                    padding: 10,
                    paddingLeft: 30,
                    borderRadius: 25,
                    textDecorationLine: 'underline'
                  }}
                >
                  <i>{t('signin')}</i>
                </Text>
              </TouchableHighlight>
            </View>
          </View>
        )}
      </View>
    );
  }
}

export default withFirebase(withTranslation(ForgotPassword));
