import React, { Component } from "react";
import { Text, View, FlatList, Image } from "react-native";
import RF from "react-native-responsive-fontsize";
import { getWidthFromHD, getWidthPercent } from "../../libs/list_picker/ratio";
import { TEXT_COLOR, DISPLAY_NAME, PROFILE_LOGE_NUMBER } from "../../constants/const";
import { withTranslation } from "react-multi-lang";

export class RankingGeneralComponent extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  _keyExtractor = (item, index) => `_${index}`;

  _renderHeader = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          width: this.props.modeTele
            ? getWidthPercent(45)
            : this.props.maxWidth,
          justifyContent: "space-between",
          alignContent: "stretch",
          alignItems: "flex-start",
          borderBottomColor: "gray",
          borderBottomWidth: 0.5,
        }}
      ></View>
    );
  };

  _renderEmpty = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignContent: "stretch",
          alignItems: "flex-start",
          width: this.props.modeTele
            ? getWidthPercent(45)
            : this.props.maxWidth,
          justifyContent: "space-between",
          borderBottomColor: "gray",
          borderBottomWidth: 0.5,
          paddingBottom: 5,
          paddingTop: 5,
        }}
      >
        <Text
          style={{
            color: TEXT_COLOR,
            textAlign: "center",
            textAlignVertical: "center",
            flex: 1,
            fontSize: this.props.modeTele ? RF(4) : 14,
          }}
        >
          {/* {t("no_participants")} */}
        </Text>
      </View>
    );
  };

  _renderItem = ({ item, index }) => {
    return (
      <View
        style={{
          justifyContent: "space-between",
          alignContent: "stretch",
          alignItems: "flex-start",
        }}
      >
        {this.props &&
          this.props.authUser &&
          this.props.authUser.uid === item.userId && (
            <View
              style={{
                backgroundColor: "lightgray",
                position: "absolute",
                height: "100%",
                width: "100%",
                opacity: 0.2,
              }}
            />
          )}
        <View
          direction={index % 2 === 0 ? "left" : "right"}
          style={{
            flex: 1,
            flexDirection: "row",
            alignContent: "stretch",
            alignItems: "flex-start",
            width: this.props.modeTele
              ? getWidthPercent(45)
              : this.props.maxWidth,
            justifyContent: "space-between",
            borderBottomColor: "gray",
            borderBottomWidth: 0.5,
          }}
        >
          {index === 0 && (
            <View>
              <Image
                style={{
                  width: 80,
                  height: 15,
                  maginBottom: 5,
                  marginTop: 5,
                  alignSelf: "center",
                }}
                source={require("../../assets/images/first.png")}
                resizeMode="contain"
              />
              {item[this.props.pointField] && <Text
                style={{
                  fontSize: this.props.modeTele ? RF(4) : 12,
                  textAlign: "center",
                  width: 80,
                  textAlignVertical: "center",
                  color: "white",
                  maginBottom: 5,
                  marginTop: 5,
                }}
              >
                {`${item[this.props.pointField]}`}
              </Text>}
            </View>
          )}
          {index > 0 && (
            <View>
              <Text
                style={{
                  fontSize: this.props.modeTele ? RF(4) : 14,
                  textAlign: "center",
                  width: 80,
                  textAlignVertical: "center",
                  color: "white",
                  paddingTop: 5,
                }}
              >
                {`${index + 1}`}
              </Text>
              {item[this.props.pointField] && <Text
                style={{
                  fontSize: this.props.modeTele ? RF(4) : 12,
                  textAlign: "center",
                  width: 80,
                  textAlignVertical: "center",
                  color: "white",
                  paddingBottom: 5,
                }}
              >
                {`${item[this.props.pointField]}`}
              </Text>}
            </View>
          )}
          <Text
            style={{
              color: TEXT_COLOR,
              textAlign: "left",
              textAlignVertical: "center",
              fontWeight: "bold",
              flex: 1,
              fontSize: this.props.modeTele
                ? DISPLAY_NAME === "SHORT"
                  ? RF(3)
                  : 16
                : 14,
              alignSelf: "center",
              justifyContent: "center",
              borderLeftColor: "gray",
              borderLeftWidth: 0.5,
              paddingLeft: 10,
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {item.displayName ? item.displayName : `${item.firstName} ${item.lastName.charAt(0).toUpperCase()}.`}
          </Text>


          {PROFILE_LOGE_NUMBER && <Text
            style={{
              fontSize: 14,
              color: TEXT_COLOR,
              textAlign: "right",
              textAlignVertical: "center",
              width: this.props.pointField !== "pointForecast" ? 80 : 140,
              paddingBottom: 5,
              paddingTop: 5,
              paddingRight: 8,
              alignSelf: "center",
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {item.logeNumber ? item.logeNumber : ""}
          </Text>}

          {item[this.props.pointField] > 0 && (this.props.pointField !== "pointForecast" || this.props.modeTele) && (
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: this.props.modeTele ? RF(3) : 12,
                textAlignVertical: "bottom",
                textAlign: "center",
                alignSelf: "center",
                marginLeft: 5,
                width: this.props.modeTele ? getWidthFromHD(40 * 3) : 60,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              {Math.floor(item.timeRightMs / 1000).toFixed(0)}'
              {Math.floor(
                (item.timeRightMs -
                  Math.floor(item.timeRightMs / 1000).toFixed(0) * 1000) /
                10
              ) < 10
                ? "0" +
                Math.floor(
                  (item.timeRightMs -
                    Math.floor(item.timeRightMs / 1000).toFixed(0) * 1000) /
                  10
                )
                : Math.floor(
                  (item.timeRightMs -
                    Math.floor(item.timeRightMs / 1000).toFixed(0) * 1000) /
                  10
                )}
              s
            </Text>
          )}

        </View>
      </View>
    );
  };

  _displayTimeMs = (timeMs) => {
    var displayT = "";
    displayT += Math.floor(timeMs / 1000).toFixed(0) + "'";
    if (
      Math.floor((timeMs - Math.floor(timeMs / 1000).toFixed(0) * 1000) / 10) <
      10
    ) {
      displayT +=
        "0" +
        Math.floor((timeMs - Math.floor(timeMs / 1000).toFixed(0) * 1000) / 10);
    } else {
      displayT +=
        Math.floor(
          (timeMs - Math.floor(timeMs / 1000).toFixed(0) * 1000) / 10
        ) + "s";
    }
    return displayT;
  };

  render() {
    // console.log("this.props.data = ", this.props.data);
    // console.log("this.state = ", this.state);
    return (
      <FlatList
        style={{ marginBottom: 33 }}
        data={this.props.data}
        // extraData={this.state}
        renderItem={this._renderItem}
        ListHeaderComponent={this._renderHeader}
        numColumns={1}
        keyExtractor={this._keyExtractor}
        ListEmptyComponent={this._renderEmpty}
      />
    );
  }
}

export default withTranslation(RankingGeneralComponent);
