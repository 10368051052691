import React, { Component } from "react";
import { View } from "react-native";
import {
  TEXT_HOME_1,
  ONLY_ANONYMOUS,
  TEXT_COLOR,
  BANNER_BOTTOM_ENABLE,
  SHOW_GAMES,
  APP_NAVTAB,
  GO_HOME_INSIDE,
  THEME,
  PAGE_GAMES
} from "../../constants/const";
import { withFirebase } from "../Firebase";
import { Button, Layout } from "@ui-kitten/components";
import { withTranslation, t } from "react-multi-lang";
import withMainLayout from "../MainLayout";
import ReactHtmlParser from 'react-html-parser';

export class Intro extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.currentSurvey = null;
    this.currentQuiz = null;
    this.currentMatch = null;
    this.authUser = null;
    this.state = { skipRegister: false };
    if (props.firebase) {
      if (["challengeic2024"].includes(THEME)) {
        props.navigation.navigate("SignIn");
        return;
      }

      props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser !== null) {
          this.authUser = authUser;
          if (GO_HOME_INSIDE) {
            this.props.navigation.navigate("HomeInside");
          } else {
            if (SHOW_GAMES || APP_NAVTAB) {
              this.props.navigation.navigate(PAGE_GAMES());
            } else {
              this.props.navigation.navigate("HomeInside");
            }
          }
        }
      });
    }
  }


  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { navigate } = this.props.navigation;

    return (
      <View
        style={{
          alignItems: "center",
          marginBottom: BANNER_BOTTOM_ENABLE() ? 100 : 0,
        }}
      >
        <Layout
          style={{
            color: this.props.navigation.state.params
              ? this.props.navigation.state.params.tc
                ? this.props.navigation.state.params.tc
                : TEXT_COLOR
              : TEXT_COLOR,
            fontSize: 16,
            marginBottom: 30,
            textAlign: "justify",
          }}
        >
          {ReactHtmlParser(`${TEXT_HOME_1()}`)}
        </Layout>

        {this.state.skipRegister === false && ONLY_ANONYMOUS === false && THEME !== 'assejeux' && (
          <View
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              alignSelf: "center",
              // bottom: BANNER_BOTTOM_ENABLE() ? 70 : 0,
              bottom: 0,
              backgroundColor: "transparent"
            }}
          >
            <View
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: "transparent"
              }}
            />

            <Button
              style={{
                marginHorizontal: 20, marginVertical: 3, borderRadius: 25,
                shadowColor: "#000",
                shadowOpacity: 1,
                shadowRadius: 10,
                textTransform: 'uppercase'
              }}
              size="large"
              onPress={() => navigate("SignUp")}
            >
              {t("create_account")}
            </Button>
            <Button
              style={{
                marginHorizontal: 20, marginVertical: 3, borderRadius: 25,
                shadowColor: "#000",
                shadowOpacity: 1,
                shadowRadius: 10,
                textTransform: 'uppercase'
              }}
              size="large"
              onPress={() => navigate("SignIn")}
            >
              {t("login")}
            </Button>
          </View>
        )}

        {this.state.skipRegister === false && ONLY_ANONYMOUS === false && THEME === 'assejeux' && (
          <View
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              alignSelf: "center",
              bottom: BANNER_BOTTOM_ENABLE() ? 70 : 0,
              backgroundColor: "transparent"
            }}
          >
            <View
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: "transparent"
              }}
            />

            <Button
              style={{
                marginHorizontal: 20, marginVertical: 3, borderRadius: 25,
                shadowColor: "#000",
                shadowOpacity: 1,
                shadowRadius: 10
              }}
              size="large"
              onPress={() => navigate("Connexion")}
            >
              S’inscrire ou Se connecter
            </Button>
          </View>
        )}

        {ONLY_ANONYMOUS === true && (
          <View
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              alignSelf: "center",
              bottom: BANNER_BOTTOM_ENABLE() ? 70 : 0,
              backgroundColor: "transparent"
            }}
          >
            <View
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: "transparent"
              }}
            />
            <Button style={{ margin: 60, width: 300, borderRadius: 25, textTransform: 'uppercase' }} size="large" onPress={() => navigate("QuickSignUp")}>
              {t("go_in")}
            </Button>
          </View>
        )}

      </View>
    );
  }
}

export default withFirebase(withMainLayout(withTranslation(Intro)));
