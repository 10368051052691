import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { StyleSheet, View, Image } from "react-native";
import { withAuthorization } from "../../components/Session";
import { Button, Card, Icon, Layout, Text } from "@ui-kitten/components";
import { THEME, THEME_ID } from "../../constants/const";

import LottieView from "react-native-web-lottie";
import axios from "axios";

import {
  withTranslation,
  t
} from "react-multi-lang";

const giftIcon = (props) => <Icon {...props} name="gift" />;
export class ShopScreen extends Component {
  state = { finaldata: false, refreshNum: 1, arrProducts: [] };
  constructor(props) {
    super(props);
    if (props.authUser) {
      // get data
      axios
        .get(
          `https://bo.wisslmedia.com/fr/products/apiList?loge_id=${THEME_ID}`
        )
        .then((res) => {
          if (res.data) {
            if (res.data.data.length > 0) {
              if (this._isMounted) {
                this.setState({
                  finaldata: true,
                  arrProducts: [...res.data.data],
                });
              }
            }
            //refresh data
          }
        });
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  footerItem = (item) => (
    <View style={styles.footerContainer}>
      <Button
        style={styles.footerControl}
        size="small"
        accessoryRight={giftIcon}
        onPress={() => {
          this.props.navigation.navigate(`Item`, { i: item.identifier });
        }}
      >
        {item.price.toString().replace(/(.)(?=(\d{3})+$)/g, "$1 ")} pts
      </Button>
    </View>
  );

  oneItem = (item) => (
    <Layout style={styles.layout}>
      <Card
        style={styles.card}
        onPress={() => {
          console.log("should go detail");
          console.log(this.props);
          this.props.navigation.navigate(`Item`, { i: item.identifier });
        }}
      >
        <Image
          style={{
            height: 150,
          }}
          source={{ uri: item.thumbnail_url }}
          resizeMode={"contain"}
        ></Image>
        <Text
          style={{ textAlign: "center", color: "#666", marginVertical: 10 }}
        >
          {item.title}
        </Text>
        {this.footerItem(item)}
      </Card>
    </Layout>
  );

  renderItem = (data) => {
    var arrItems = [];
    for (let i = 0; i < data.length; i++) {
      const item1 = data[i];
      var item2 = null;
      if (i + 1 < data.length) {
        item2 = data[i + 1];
      }

      arrItems.push(
        <Layout style={styles.container} key={item1.identifier}>
          {this.oneItem(item1)}
          {item2 && <>{this.oneItem(item2)}</>}
        </Layout>
      );
      if (i + 1 < data.length) {
        i++;
      }
    }
    return arrItems;
  };

  render() {
    // console.log(this.arrProducts);
    return (
      <Layout>
        {this.state.finaldata === false && (
          <LottieView
            style={{
              position: "fixed",
              height: 50,
              width: 50,
              bottom: 80,
              alignSelf: "center",
            }}
            source={require("../../assets/anim/loader.json")}
            autoPlay
            loop
          />
        )}
        <Text style={{ margin: 20, textAlign: "center" }} category="h3">
          {t("gift_shop")}
        </Text>
        <Text style={{ margin: 20, marginTop: 0, textAlign: "center" }} >
          {THEME === "neuillyfetelesjeux" ? t("600 points à gagner par jour,\nsoit 10 200 points au total jusqu’au 11 août\n\nCes lots, dont le stock est limité, vous sont offerts par la Métropole, le Département et la Région.\n\nVous conservez vos points pour le classement général, même après les avoir échangés contre les lots. Les 10 premiers.ères du classement le 11 août à 20h00 seront récompensés.ées") :
            THEME === "hasard-fdj" ? t("Gagnez un maximum de points avec nos jeux digitaux et échangez-les contre des cadeaux Hasard (attention les stocks sont limités)") :
              t("shop_title_desc")}

        </Text>
        {this.renderItem(this.state.arrProducts)}
      </Layout>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
  layout: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    flex: 1,
    width: "96%",
    backgroundColor: "#fff",
    margin: 2,
  },
  footerContainer: {
    margin: 5,
    flexDirection: "row",
    justifyContent: "center",
  },
  footerControl: {
    marginHorizontal: 2,
    borderRadius: 25
  },
});

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(withMainLayout(withTranslation(ShopScreen)));
