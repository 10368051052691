import React from "react";
import {
  BottomNavigation,
  BottomNavigationTab,
  Text,
} from "@ui-kitten/components";
import { APP_RANKING_FORECAST, APP_RANKING_QUIZ, APP_RANKING_TOTAL, BUTTON_COLOR, ONLY_ANONYMOUS, PAGE_GAMES, THEME } from "../../constants/const";
import { Image, View } from "react-native";
import BurgerMenu from "./BurgerMenu";
import { useTranslation, setLanguage, getLanguage } from "react-multi-lang";

// const BackIcon = (props) => <Icon {...props} name="arrow-ios-back" />;
// const MoreIcon = (props) => <Icon  name="more-horizontal-outline" width={24} height={24} style={{color:"#fff"}} color="#fffff" backgroundColor="#ffffff" />;
// const ProfileIcon = (props) => <Icon  name="person-outline" color="white"/>;
// const HomeIcon = (props) => <Icon  name="home-outline" color="white"/>;
// const EventIcon = (props) => <Icon {...props} name="layers-outline" />;
// const RankingIcon = (props) => <Icon {...props} name="bar-chart-outline" />;

export const BottomNavBar = (props) => {
  if (getLanguage() !== window.localStorage.getItem("lang")) {
    setLanguage(window.localStorage.getItem("lang"));
  }
  var page = 0;
  switch (props.navigation.state.routeName.toLowerCase()) {
    case "homeinside":
      page = 0;
      break;
    case PAGE_GAMES():
      page = 1;
      break;
    case "rankingtotal":
    case "rankingquiz":
    case "rankingforecast":
      page = 2;
      break;
    case "profile":
      page = 3;
      break;
    case "more":
      if (ONLY_ANONYMOUS) page = 3;
      else page = 4;
      break;

    default:
      break;
  }
  const [selectedIndex, setSelectedIndex] = React.useState(page);
  const [menuVisible, setMenuVisible] = React.useState(false);
  const t = useTranslation();

  if (ONLY_ANONYMOUS) {
    return (
      <View>
        <BurgerMenu
          {...props}
          fromRight={true}
          isOpen={menuVisible}
          callbackOpened={(_opened) => setMenuVisible(_opened)}
        ></BurgerMenu>
        <View
          style={{
            position: "fixed",
            width: "100%",
            bottom: 0,
            borderRadius: 8,
            boxShadow: "0 1px 1px 1px rgba(0, 0, 0, 0.3)",
          }}
        >
          <BottomNavigation
            selectedIndex={selectedIndex}
            onSelect={(index) => {
              switch (index) {
                case 0:
                  setSelectedIndex(index);
                  props.navigation.navigate("HomeInside");
                  break;
                case 1:
                  setSelectedIndex(index);
                  props.navigation.navigate(PAGE_GAMES());
                  break;
                case 2:
                  setSelectedIndex(index);
                  if (APP_RANKING_TOTAL) {
                    props.navigation.navigate("RankingTotal");
                  } else if(APP_RANKING_QUIZ){
                    props.navigation.navigate("RankingQuiz");
                  }else if(APP_RANKING_FORECAST){
                    props.navigation.navigate("RankingForecast");
                  }
                  break;
                case 3:
                  if (!ONLY_ANONYMOUS) {
                    setSelectedIndex(index);
                    props.navigation.navigate("Profile");
                  } else {
                    setMenuVisible(true);
                  }
                  break;
                case 4:
                  setMenuVisible(true);
                  break;
                default:
                  break;
              }
            }}
            // style={{backgroundColor: "white"}}
            indicatorStyle={{
              backgroundColor: BUTTON_COLOR,
              color: "white",
            }}
          >
            <BottomNavigationTab
              icon={(props) => (
                <Image
                  style={{ width: 24, height: 24, margin: 3 }}
                  source={require("../../assets/images/menu/home.svg")}
                  resizeMode="contain"
                />
              )}
              title={(props) => (
                <Text {...props} style={{ fontSize: 11 }}>
                  {t("home")}
                </Text>
              )}
            />
            <BottomNavigationTab
              // style={{ color: BUTTON_COLOR, backgroundColor: BUTTON_COLOR }}
              icon={(props) => (
                <Image
                  style={{ width: 24, height: 24, margin: 3 }}
                  source={require("../../assets/images/menu/game.svg")}
                  resizeMode="contain"
                />
              )}
              title={(props) => (
                <Text color="#fff" style={{ fontSize: 11 }}>
                  {THEME !== "salonstade" && THEME !== "stadedefrance" ? t("events") : t("events_sdf")}
                </Text>
              )}
            />
            <BottomNavigationTab
              icon={(props) => (
                <Image
                  style={{ width: 24, height: 24, margin: 3 }}
                  source={require("../../assets/images/menu/ranking.svg")}
                  resizeMode="contain"
                />
              )}
              title={(props) => (
                <Text {...props} style={{ fontSize: 11 }}>
                  {t("rankings")}
                </Text>
              )}
            />

            <BottomNavigationTab
              icon={(props) => (
                <Image
                  style={{ width: 24, height: 24, margin: 3 }}
                  source={require("../../assets/images/menu/more.svg")}
                  resizeMode="contain"
                />
              )}
              title={(props) => (
                <Text {...props} style={{ fontSize: 11 }}>
                  {t("more")}
                </Text>
              )}
            />
          </BottomNavigation>
        </View>
      </View>
    );
  } else {
    return (
      <View>
        <BurgerMenu
          {...props}
          fromRight={true}
          isOpen={menuVisible}
          callbackOpened={(_opened) => setMenuVisible(_opened)}
        ></BurgerMenu>
        <View
          style={{
            position: "fixed",
            width: "100%",
            bottom: 0,
            borderRadius: 8,
            boxShadow: "0 1px 1px 1px rgba(0, 0, 0, 0.3)",
          }}
        >
          <BottomNavigation
            selectedIndex={selectedIndex}
            onSelect={(index) => {
              switch (index) {
                case 0:
                  setSelectedIndex(index);
                  props.navigation.navigate("HomeInside");
                  break;
                case 1:
                  setSelectedIndex(index);
                  props.navigation.navigate(PAGE_GAMES());
                  break;
                case 2:
                  setSelectedIndex(index);
                  if (APP_RANKING_TOTAL) {
                    props.navigation.navigate("RankingTotal");
                  } else if(APP_RANKING_QUIZ){
                    props.navigation.navigate("RankingQuiz");
                  }else if(APP_RANKING_FORECAST){
                    props.navigation.navigate("RankingForecast");
                  }
                  break;
                case 3:
                  if (!ONLY_ANONYMOUS) {
                    setSelectedIndex(index);
                    props.navigation.navigate("Profile");
                  } else {
                    setMenuVisible(true);
                  }
                  break;
                case 4:
                  setMenuVisible(true);
                  break;
                default:
                  break;
              }
            }}
            // style={{backgroundColor: "white"}}
            indicatorStyle={{
              backgroundColor: "white",
              color: "white",
            }}
          >
            <BottomNavigationTab
              icon={(props) => (
                <Image
                  style={{ width: 24, height: 24, margin: 3 }}
                  source={require("../../assets/images/menu/home.svg")}
                  resizeMode="contain"
                />
              )}
              title={(props) => (
                <Text {...props} style={{ fontSize: 11 }}>
                  {t("home")}
                </Text>
              )}
            />
            <BottomNavigationTab
              // style={{ color: BUTTON_COLOR, backgroundColor: BUTTON_COLOR }}
              icon={(props) => (
                <Image
                  style={{ width: 24, height: 24, margin: 3 }}
                  source={require("../../assets/images/menu/game.svg")}
                  resizeMode="contain"
                />
              )}
              title={(props) => (
                <Text color="#fff" style={{ fontSize: 11 }}>
                  {THEME !== "salonstade" && THEME !== "stadedefrance" ? t("events") : t("events_sdf")}
                </Text>
              )}
            />
            <BottomNavigationTab
              icon={(props) => (
                <Image
                  style={{ width: 24, height: 24, margin: 3 }}
                  source={require("../../assets/images/menu/ranking.svg")}
                  resizeMode="contain"
                />
              )}
              title={(props) => (
                <Text {...props} style={{ fontSize: 11 }}>
                  {t("rankings")}
                </Text>
              )}
            />

            <BottomNavigationTab
              icon={(props) => (
                <Image
                  style={{ width: 24, height: 24, margin: 3 }}
                  source={require("../../assets/images/menu/profile.svg")}
                  resizeMode="contain"
                />
              )}
              title={(props) => (
                <Text {...props} style={{ fontSize: 11 }}>
                  {t("profile_nav")}
                </Text>
              )}
            />

            <BottomNavigationTab
              icon={(props) => (
                <Image
                  style={{ width: 24, height: 24, margin: 3 }}
                  source={require("../../assets/images/menu/more.svg")}
                  resizeMode="contain"
                />
              )}
              title={(props) => (
                <Text {...props} style={{ fontSize: 11 }}>
                  {t("more")}
                </Text>
              )}
            />
          </BottomNavigation>
        </View>
      </View>
    );
  }
};
