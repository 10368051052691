import React from "react";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import { Text, View, Image } from "react-native";
import RF from "react-native-responsive-fontsize";
import {

  MENU_COLOR,
  TEXT_COLOR,

} from "../../constants/const";
import RankingGeneralComponent from "../../components/Ranking/ranking_general";

import { withTranslation, t } from "react-multi-lang";


export class RankingGroupScreen extends React.Component {
  _isMounted = false;
  arrRanking = [];
  arrCompetitions = [{ id: -1, name: t("all_competitions") }];
  constructor(props) {
    super(props);
    this.state = {
      arrCompetitions: this.arrCompetitions,
      data: null,
      currentPlayer: null,
      selectedIndex: null,
      selectedValue: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.authUser) {

      this.getRanking();


    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  getRanking = () => {
    if (this.props.firebase) {
      const that = this;
      this.props.firebase.rankingQuiz().then((query) => {
        query.get().then((snapshot) => {
          var snapData = [];
          snapshot.forEach((sn) => {
            snapData.push(sn.data());
          });
          // add data to array
          that.arrRanking = snapData;

          const arrResultsByLogeNumber = {};
          const arrResultsRankingByGroup = [];
          for (let i = 0; i < that.arrRanking.length; i++) {
            const r = that.arrRanking[i];
            if (!arrResultsByLogeNumber[r.logeNumber]) {
              arrResultsByLogeNumber[r.logeNumber] = [];
            }
            arrResultsByLogeNumber[r.logeNumber].push(r);
          }

          const logeNumbers = Object.keys(arrResultsByLogeNumber);
          for (let i = 0; i < logeNumbers.length; i++) {
            const key = logeNumbers[i];
            arrResultsRankingByGroup.push({
              uid: key,
              userId: key,
              displayName: key,
              logeNumber: '',
              point: arrResultsByLogeNumber[key].reduce((a, b) => a + b.pointQuiz, 0),
              timeRightMs: arrResultsByLogeNumber[key].reduce((a, b) => a + b.timeRightMs, 0)
            });
          }

          arrResultsRankingByGroup.sort((a, b) => {
            return (
              b.point - a.point ||
              a.timeRightMs - b.timeRightMs
            );
          });

          if (that._isMounted) {
            that.setState({
              data: [...arrResultsRankingByGroup],
              authUser: that.props.authUser,
            });
          }
        });
      });
    }
  };

  render() {
    return (
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <View
          style={{
            backgroundColor: MENU_COLOR,
            width: "100%",
            height: 90,
            justifyContent: "center",
            alignItems: "center",
            shadowColor: "#000",
            shadowOpacity: 1,
            shadowRadius: 30,
          }}
        >
          <Text
            style={{
              color: TEXT_COLOR,
              textAlign: "center",
              textAlignVertical: "center",
              margin: 10,
              fontSize: 22,
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {t("Team Ranking")}
          </Text>
        </View>
        <View style={{ marginBottom: RF(2) + 56 }}>
          <RankingGeneralComponent
            data={this.state.data}
            authUser={this.state.authUser}
            pointField="point"
            final={true}
            maxWidth={this.props.maxWidth}
          />
        </View>

        {this.state.currentPlayer && (
          <View
            style={{
              position: "fixed",
              width: "100%",
              height: 44,
              bottom: 0,
            }}
          >
            <View
              style={{
                backgroundColor: "dimgray",
                position: "absolute",
                height: "100%",
                width: "100%"
              }}
            />

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignContent: "stretch",
                alignItems: "flex-start",
                justifyContent: "space-between",
                borderBottomColor: "gray",
                borderBottomWidth: 0.5,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              {this.state.currentPlayer.rank === 1 && (
                <View>
                  <Image
                    style={{
                      width: 80,
                      height: 15,
                      maginBottom: 5,
                      alignSelf: "center",
                    }}
                    source={require("../../assets/images/first.png")}
                    resizeMode="contain"
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      textAlign: "center",
                      width: 80,
                      textAlignVertical: "center",
                      color: "lightgray",
                      maginBottom: 5,
                      marginTop: 5,
                    }}
                  >
                    {`${this.state.currentPlayer.pointQuiz}`}
                  </Text>
                </View>
              )}
              {this.state.currentPlayer.rank > 1 && (
                <View>
                  <Text
                    style={{
                      fontSize: 18,
                      textAlign: "center",
                      width: 80,
                      textAlignVertical: "center",
                      color: "white",
                    }}
                  >
                    {`${this.state.currentPlayer.rank}`}
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      textAlign: "center",
                      width: 80,
                      textAlignVertical: "center",
                      color: "lightgray",
                    }}
                  >
                    {`${this.state.currentPlayer.pointQuiz}`}
                  </Text>
                </View>
              )}

              <Text
                style={{
                  color: TEXT_COLOR,
                  textAlign: "left",
                  textAlignVertical: "center",
                  alignSelf: "center",
                  flex: 1,
                  marginLeft: 8,
                  fontSize: 18
                }}
              >
                {`${this.state.currentPlayer.firstName
                  } ${this.state.currentPlayer.lastName
                    .charAt(0)
                    .toUpperCase()}.`}
              </Text>

              <Text
                style={{
                  fontSize: 12,
                  color: TEXT_COLOR,
                  textAlign: "right",
                  textAlignVertical: "center",
                  width: 80,
                  paddingBottom: 5,
                  paddingTop: 5,
                  paddingRight: 8,
                  alignSelf: "center",
                }}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {this.state.currentPlayer.logeNumber
                  ? this.state.currentPlayer.logeNumber
                  : ""}
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(
  withMainLayout(withTranslation(RankingGroupScreen))
);
