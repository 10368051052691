import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import FadeSlideView from "../Anim/FadeSlideView";
import { APP_NAVTAB, BUTTON_COLOR, PAGE_GAMES, SHOW_GAMES, TEXT_COLOR, isEn } from "../../constants/const";
import CountDown from "../CountDown";
import RF from "react-native-responsive-fontsize";
import { withTranslation, t } from "react-multi-lang";
import WheelComponent from "./WheelComponent";
import Confetti from "react-confetti";
import ReactHtmlParser from 'react-html-parser';
export class Draw extends React.Component {
  _isMounted = false;
  _maxTry = 1;
  // Spin specs
  segments = [
    "Gagné",
    "Perdu",
    "Gagné",
    "Perdu",
    "Gagné",
    "Perdu",
    "Gagné",
    "Perdu",
  ];
  segColors = [
    "#006A4D",
    "#EE142B",
    "#009698",
    "#EE142B",
    "#009698",
    "#EE142B",
    "#006A4D",
    "#EE142B",
  ];

  possibleWin = false;

  constructor(props) {
    super(props);
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackStartTimer = this.callbackStartTimer.bind(this);
    this.currentQuestion = null;
    var _screen = "Question";
    var tlv = 0;

    if (props.currentDraw) {
      this.currentQuestion = {
        id: props.currentDraw.id,
        question: props.currentDraw.name, //isEn() ? props.currentDraw.name_en : props.currentDraw.name,
        type: 3,
        rewards: props.currentDraw.rewards ? props.currentDraw.rewards.rewards : [],
      };
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      console.log("draw = ", props.currentDraw);
      tlv = props.currentDraw.start.seconds - now;
      console.log("tlv = ", tlv);
      _screen = tlv <= 0 ? "Question" : "CountDown";

      // check if passed max_try 
      this._maxTry = parseInt(`${props.currentDraw.max_try}`, 10);
      let _drawLocal = window.localStorage.getItem("_draw_local"); // {id: 123, tried: 1, won: false}
      if (_drawLocal) {
        _drawLocal = JSON.parse(_drawLocal);
        if (_drawLocal.id === this.currentQuestion.id) {
          if (_drawLocal.tried >= this._maxTry) {
            // already tried max_try the only need to show the result
            _screen = _drawLocal.won ? "ResultWon" : "Result";
          }
        }
      }

      // Check if passed max_try from server
      if (props.userData.uid && props.currentDraw) {
        this.props.firebase
          .draw_tried_data(props.currentDraw.id, props.userData.uid).onSnapshot((docSnapshot) => {
            var _triedData = docSnapshot.data();
            if (_triedData) {
              if (_triedData.tried >= this._maxTry) {
                // already tried max_try the only need to show the result
                _screen = _triedData.won ? "ResultWon" : "Result";
                // save localstorage
                window.localStorage.setItem("_draw_local", JSON.stringify(_triedData));
                this.setState({
                  screen: _screen,
                });
              }
            }
          },
            (err) => {
              console.log(`Encountered error: ${err}`);
            });
      }

    }



    this.state = {
      screen: _screen,
      shouldShow: true,
      currentDraw: props.currentDraw,
      currentQuestion: this.currentQuestion,
      timeLeft: 0,
      updateNum: 0,
      timeLeftVote: tlv,
      disabled: tlv < 0,
      totalVotes: 0,
      vote1: 0,
      vote2: 0,
      vote1percent: 0.5,
      vote2percent: 0.5,
      winningSegment: 'Perdu'

    };
  }

  onFinished = (winner) => {
    console.log("winningSegment = ", this.state.winningSegment);
    console.log(winner);
    if (this.state.winningSegment === 'Gagné') {
      this.setState({ screen: "ResultWon" });
    } else {
      this.setState({ screen: "Result" });
    }
  };

  onStarted = () => {
    // check possiblity of winners
    console.log("spin started");

    const checkingDrawsForGift = this.props.firebase.functions.httpsCallable("checkingDrawsForGift");
    checkingDrawsForGift({
      currentDraw: this.state.currentDraw,
      currentUser: this.props.userData
    }).then((_res) => {
      const re = _res.data;
      console.log("checkingDrawsForGift = ", re);
      this.setState({
        winningSegment: re.win ? "Gagné" : "Perdu"
      });
    });
  };

  _renderProducts = (arrProducts) => {
    console.log("arrProducts = ", arrProducts);
    var arrView = [];
    for (let i = 0; i < arrProducts.length; i++) {
      const p = arrProducts[i];
      arrView.push(
        <View
          key={p.product.id}
          style={{
            backgroundColor: "transparent",
            // borderRadius: 10
            // with: 100,
          }}
        >
          <Image
            style={{
              height: 200,
              width: 200,
              backgroundColor: "transparent",
            }}
            source={{ uri: p.product.image }}
            resizeMode={"contain"}
          ></Image>
          {/* <Text
            style={{ textAlign: "center", color: "#666", marginVertical: 10 }}
          >
            {p.product.name}
          </Text> */}
        </View>
      );
    }

    return arrView;
  };

  getStateTimer = () => {
    if (this.state.currentDraw != null) {
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      this.timeLeftSecs = this.state.currentDraw.start.seconds - now;
    }
  };

  callbackStartTimer(val) {
    //Quizzes started
    // console.log(val);
    if (val >= 0) {
      this.checkTimer(val);
    } else {
      if (this._isMounted) {
        if (this.state.currentDraw != null) {
          const now = Math.floor(
            (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) /
            1000
          );
          const tlv =
            this.state.currentDraw.start.seconds +
            parseInt(this.state.currentDraw.duration, 10) -
            now;
          this.setState({
            screen: "Question",
            timeLeftVote: tlv,
            disabled: tlv < 0,
          });
        }
      }
    }
  }

  checkTimer = (val) => {
    this.getStateTimer();
    if (this.currentAction !== null) {
      if (Math.abs(val - this.timeLeftSecs) > 0) {
        if (this._isMounted) {
          // console.log('checkTimer');
          this.setState({
            timeLeft: this.timeLeftSecs,
            updateNum: this.state.updateNum + 1,
          });
        }
      }
    }
  };

  callbackTimer(val) {
    // check again the question
    // check timer and current question time
    if (val < 0) {
      //window.location.reload(true);
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  registerDrawIn = () => {
    // console.log("this.props.authUser = ", this.props.authUser);
    // console.log("this.props.userData = ", this.props.userData);
    if (this.props.userData.uid && this.state.currentDraw) {
      this.props.firebase
        .draw_participants(this.state.currentDraw.id, this.props.userData.uid)
        .set(this.props.userData);
    }
  };

  weightedRandom = (prob) => {
    let i,
      sum = 0,
      r = Math.random();
    for (i in prob) {
      sum += prob[i];
      if (r <= sum) return i;
    }
  };

  render() {
    // console.log('this.state = ', this.state);
    const { navigate } = this.props.navigation;
    return (
      <View
        style={{
          width: this.props.maxWidth,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {this.state.screen === "CountDown" && (
          <CountDown
            mode="insider"
            key={this.state.updateNum}
            {...this.props}
            msgWaiting={`${isEn() ? this.state.currentDraw?.msg_waiting_en || '' : this.state.currentDraw?.msg_waiting || ''}` || ''}
            msgWaitingPrefix={`${isEn() ? this.state.currentDraw?.msg_waiting_prefix_en || '' : this.state.currentDraw?.msg_waiting_prefix || ''}` || ''}
            timeLeft={this.state.timeLeft || 3}
            callbackTimer={this.callbackStartTimer}
          />
        )}

        {this.state.screen === "Question" && this.state.currentQuestion && (
          <View>
            <View
              style={{
                marginTop: 10,
                width: this.props.maxWidth,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View style={{ justifyContent: "center" }}>
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: 18,
                    fontWeight: "bold",
                    paddingLeft: 5,
                    paddingRight: 5,
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.currentQuestion.question}
                </Text>
              </View>
              <View
                style={{
                  margin: 10,
                  height: 1,
                  width: "50%",
                  justifyContent: "center",
                  borderBottomColor: "white",
                  borderBottomWidth: 1,
                }}
              ></View>
              <View style={{ justifyContent: "center" }}>
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: 15,
                    paddingLeft: 5,
                    paddingRight: 5,
                    textAlign: "center",
                    paddingTop: 0,
                    // textTransform: "uppercase",
                  }}
                >
                  {ReactHtmlParser(`${isEn() ? this.state.currentDraw?.msg_waiting_en || '' : this.state.currentDraw?.msg_waiting || ''}`)}
                </Text>
              </View>

              {/* <View style={{ marginTop: 20, backgroundColor: "transparent", flexDirection: "row"}}>
                {this._renderProducts(this.state.currentQuestion.rewards)}
              </View> */}

              <TouchableOpacity
                style={{
                  justifyContent: "center",
                  // position: "fixed",
                  marginTop: 10,
                  marginBottom: 100
                }}
                onPress={() => {
                  // take user as participated
                  this.registerDrawIn();
                  this.setState(
                    {
                      screen: "Spin",
                    }
                    // function () {
                    //   setTimeout(() => {
                    //     this.setState({ screen: "Result" });
                    //   }, 3000);
                    // }
                  );
                }}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: RF(2),
                    backgroundColor: BUTTON_COLOR,
                    textAlign: "center",
                    padding: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: 25,
                  }}
                >
                  Je participe !
                </Text>
              </TouchableOpacity>
            </View>

            <View
              style={{
                marginTop: 30,
              }}
              key={this.state.currentQuestion.displayResult}
            >
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <FadeSlideView delay={600} direction="up">
                  <View
                    style={{
                      paddingBottom: 5,
                    }}
                  ></View>
                </FadeSlideView>
              </View>
            </View>
          </View>
        )}
        {["Spin"].includes(this.state.screen) && this.state.currentQuestion && (
          <View
            style={{
              width: this.props.maxWidth,
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <View style={{ flex: 1, justifyContent: "center" }}>
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: 18,
                    fontWeight: "bold",
                    paddingLeft: 5,
                    paddingRight: 5,
                    textAlign: "center",
                    paddingTop: 30,
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.currentQuestion.question}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  marginTop: 20,
                  height: 1,
                  width: "50%",
                  justifyContent: "center",
                  borderBottomColor: "white",
                  borderBottomWidth: 1,
                }}
              ></View> */}
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 18,
                  fontWeight: "bold",
                  paddingLeft: 5,
                  paddingRight: 5,
                  textAlign: "center",
                  paddingTop: 10,
                  textTransform: "uppercase",
                }}
              >
                Cliquez sur LANCEZ
              </Text>
              <View
                style={{
                  marginTop: 20,
                  height: 300,
                  width: 320,
                  justifyContent: "center"
                }}
              >
                {/* Wheel spin */}
                <WheelComponent
                  centerX={0}
                  segments={this.segments}
                  segColors={this.segColors}
                  winningSegment={this.state.winningSegment}
                  onFinished={(winner) => this.onFinished(winner)}
                  onStarted={this.onStarted}
                  primaryColor="black"
                  contrastColor="white"
                  buttonText="LANCEZ"
                  isOnlyOnce={true}
                  size={140}
                  upDuration={1000}
                  downDuration={10000}
                />
              </View>
            </View>
          </View>
        )}
        {this.state.screen === "Result" && this.state.currentQuestion && (
          <View
            style={{
              width: this.props.maxWidth,
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 18,
                  paddingLeft: 5,
                  paddingRight: 5,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {ReactHtmlParser(`${this.state.currentDraw.msg_lost}`)}
              </Text>
            </View>
            <TouchableOpacity
              style={{
                // position: "fixed",
                justifyContent: "center",
                marginTop: 10,
                marginBottom: 100
              }}
              onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate(PAGE_GAMES()) : navigate("Home")}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  marginTop: 50,
                  fontSize: 14,
                  backgroundColor: BUTTON_COLOR,
                  textAlign: "center",
                  padding: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRadius: 25,
                }}
              >
                <i>{SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}</i>
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {this.state.screen === "ResultWon" && this.state.currentQuestion && (
          <View
            style={{
              width: this.props.maxWidth,
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Confetti
              width={this.props.maxWidth}
              height={700}
              colors={[
                "#f44336",
                "#e91e63",
                "#9c27b0",
                "#673ab7",
                "#3f51b5",
                "#2196f3",
                "#03a9f4",
                "#00bcd4",
                "#009688",
                "#4CAF50",
                "#8BC34A",
                "#CDDC39",
                "#FFEB3B",
                "#FFC107",
                "#FF9800",
                "#FF5722",
                "#795548",
              ]}
            />
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  marginTop: 20,
                  height: 1,
                  width: "50%",
                  justifyContent: "center",
                  borderBottomColor: "white",
                  borderBottomWidth: 1,
                }}
              ></View>
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 18,
                  paddingLeft: 5,
                  paddingRight: 5,
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                {ReactHtmlParser(`${this.state.currentDraw.msg_won}`)}
              </Text>
            </View>
            <TouchableOpacity
              style={{
                // position: "fixed",
                justifyContent: "center",
                marginBottom: 100
                // zIndex: 9999,
              }}
              onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate(PAGE_GAMES()) : navigate("Home")}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  marginTop: 50,
                  fontSize: 14,
                  backgroundColor: BUTTON_COLOR,
                  textAlign: "center",
                  padding: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRadius: 25,
                }}
              >
                <i>{SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}</i>
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

export default withTranslation(Draw);
