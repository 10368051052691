import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { View } from "react-native";
import CountDown from "../../components/CountDown";
import { withAuthorization } from "../../components/Session";
import { isEn } from "../../constants/const";

export class CountDownScreen extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.callbackTimer = this.callbackTimer.bind(this);

    this.timeLeftSecs = this.props.navigation.getParam('params');
    this.act = this.props.navigation.getParam('act');
    this.getStateTimer();
    this.state = { timeLeft: this.timeLeftSecs };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getStateTimer = () => {
    var action = "";
    if (this.act === 'surveys') {
      action = "currentSurvey";
    } else {
      action = "currentQuiz";
    }
    const currentAction = window.localStorage.getItem(action);
    if (currentAction != null) {
      this.currentAction = JSON.parse(currentAction);
      // Process the time line for quiz
      const now = Math.floor((Date.now() + parseInt(window.localStorage.getItem('delay'), 10)) / 1000);
      this.timeLeftSecs = this.currentAction.start.seconds - now;
    }
  }

  callbackTimer(val) {
    //Quizzes started
    if (val >= 0) {
      this.checkTimer(val);
    }
  }

  checkTimer = (val) => {
    this.getStateTimer();
    if (this.currentAction !== null) {
      if (Math.abs(val - this.timeLeftSecs) > 0) {
        if (this._isMounted) {
          this.setState({
            timeLeft: this.timeLeftSecs
          });
        }
      }
    }
  }

  render() {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <CountDown
          msgWaiting={`${isEn() ? this.currentAction?.msg_waiting_en || '' : this.currentAction?.msg_waiting || ''}` || ''}
          key={this.state.timeLeft} {...this.props} timeLeft={this.state.timeLeft} callbackTimer={this.callbackTimer} act={this.act} />
      </View>
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(CountDownScreen));
