import React from "react";
import { Animated, Text, View, TouchableOpacity, Image } from "react-native";
import { APP_NAVTAB, BANNER_BOTTOM_ENABLE, BUTTON_COLOR, PAGE_GAMES, SHOW_GAMES, SHOW_MINUTER_MOBILE, TEXT_COLOR, THEME } from "../../constants/const";
import { withTranslation, t } from "react-multi-lang";
import LottieView from "react-native-web-lottie";
import ReactHtmlParser from 'react-html-parser';
export class CountDown extends React.Component {
  _isMounted = false;
  intervalId = null;

  constructor(props) {
    super(props);
    this.springValue = new Animated.Value(0.3);
    this.startValue = props.timeLeft || 1;
  }

  _displayDate = (timeLeft) => {
    const secLeft = timeLeft % 60;
    const minLeft = Math.floor(timeLeft / 60);
    const hourLeft = Math.floor(minLeft / 60);
    const dayLeft = Math.floor(hourLeft / 24);
    const day = dayLeft > 0 ? dayLeft + "j " : "";
    const hourDisplay = hourLeft % 24;
    const hour = hourDisplay < 10 ? "0" + hourDisplay : hourDisplay;
    const minDisplay = minLeft % 60;
    const min = minDisplay < 10 ? "0" + minDisplay : minDisplay;
    const sec = secLeft < 10 ? "0" + secLeft : secLeft;
    return day + " " + hour + " : " + min + " : " + sec;
  };

  componentDidMount() {
    this._isMounted = true;
    this.intervalId = setInterval(() => {
      if (this.startValue >= 0) {
        if (this._isMounted) {
          this.setState({ currentCount: this.startValue }, () => {
            this.spring();
          });
        }
      } else {
        if (this.props.mode === "insider") {
        } else {
          clearInterval(this.intervalId);
          this.props.navigation.navigate(PAGE_GAMES());
        }
      }
      this.startValue = this.startValue - 1;
      if (this.props.callbackTimer) {
        this.props.callbackTimer(this.startValue);
      }
    }, 1000);
  }

  spring() {
    this.springValue.setValue(2);
    Animated.spring(this.springValue, {
      toValue: 1,
      bounciness: 12,
    }).start();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  render() {

    const { navigate } = this.props.navigation;
    return (
      <View style={{
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center", alignItems: "center"
      }}>
        {["challengeic2024"].includes(THEME) && (
          <Image
            style={{
              height: 625 * this.props.maxWidth / 2000, width: this.props.maxWidth
            }}
            source={'https://storage.googleapis.com/take-over-loges.appspot.com/En_tete_15d4c355d0/En_tete_15d4c355d0.png'}
            resizeMode="contain"
          />
        )}

        {["hasard-fdj"].includes(THEME) && (
          <Image
            style={{
              height: 175, width: 250
            }}
            source={'https://storage.googleapis.com/take-over-loges.appspot.com/Logo_Hasard_85922fe0be/Logo_Hasard_85922fe0be.png'}
            resizeMode="contain"
          />
        )}

        {this.props.timeLeft === 0 && (
          <LottieView
            style={{
              position: "fixed",
              height: 50,
              width: 50,
              top: 80,
              alignSelf: "center",
            }}
            source={require("../../assets/anim/loader.json")}
            autoPlay
            loop
          />
        )}
        <View style={{ marginTop: 10 }}>{this.props.msgWaitingPrefix && ReactHtmlParser(`${this.props.msgWaitingPrefix}`)}</View>


        {
          this.state && this.state.currentCount > 60 && SHOW_MINUTER_MOBILE() && (
            <View>
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 55,
                  fontStyle: "bold",
                  textAlign: "center",
                  textShadowOffset: { width: 5, height: 5 },
                  textShadowColor: "rgba(255, 255, 255, 0.5)",
                  textShadowRadius: 20,
                  margin: 0,
                }}
              >
                {this._displayDate(this.state.currentCount)}
              </Text>
            </View>
          )
        }


        <View style={{
          // position: "fixed",
          justifyContent: "center",
          alignSelf: "center",
          flex: 1,
          flexDirection: "column",
          alignItems: "center"
          // bottom: 200
        }} >
          {this.state && this.state.currentCount <= 60 && (
            <View
              style={{
                height: 160,
                width: this.props.maxWidth
              }}
            >
              <Animated.View
                style={{
                  width: 227,
                  height: 160,
                  alignSelf: "center",
                  transform: [{ scale: this.springValue }],
                }}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: 99,
                    fontStyle: "bold",
                    textAlign: "center",
                    textShadowOffset: { width: 5, height: 5 },
                    textShadowColor: "#000",
                    textShadowRadius: 30,
                  }}
                >
                  {this.state && this.state.currentCount}
                </Text>
              </Animated.View>
            </View>
          )}


          {this.props.msgWaiting && ReactHtmlParser(`${this.props.msgWaiting}`)}

        </View>

        {/* <View style={{
          position: 'fixed',
          bottom: BANNER_BOTTOM_ENABLE ? 70 : 0,
          width: '100%',
          padding: 15
        }}>
          <TouchableOpacity
            onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate("SelectGame") : navigate("Home")}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                margin: 50,
                fontSize: 14,
                backgroundColor: BUTTON_COLOR,
                textAlign: "center",
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: 25
              }}
            >
              <i>{SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}</i>
            </Text>
          </TouchableOpacity>
        </View> */}



      </View >
    );
  }
}

export default withTranslation(CountDown);
