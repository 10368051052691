import React from "react";
import { ImageBackground, View, Text, TouchableOpacity, Image } from "react-native";
import CountDownCircle from "../CountDown/circle";
import PagingControl from "../Paging";
import Response from "../ReponsesSurvey";
import FadeSlideView from "../Anim/FadeSlideView";
import BaseSondage from "./baseSondage";
import LottieView from "react-native-web-lottie";
import { TIME_PAUSE, TIME_EXTRA_ANIM, TEXT_COLOR, SHOW_GAMES, THEME, APP_NAVTAB, MENU_COLOR, BANNER_BOTTOM_ENABLE, isEn, PAGE_GAMES } from "../../constants/const";
import RF from "react-native-responsive-fontsize";

import {
  OPTIONS_QUESTIONS_TV_AND_RESULTS,
  OPTIONS_NO_TV_RESULT_MOBILE,
  OPTIONS_NO_COUNTER,
  OPTIONS_LOGIN_NOT_REQUIRED,
} from "../../constants/routes";
import CountDown from "../CountDown";
import TOButton from "../../libs/to_button";
import { withTranslation, t } from "react-multi-lang";

export class Sondages extends BaseSondage {
  constructor(props) {
    super(props);
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackStartTimer = this.callbackStartTimer.bind(this);
  }

  getStateTimer = () => {
    if (this.state.currentSurvey != null) {
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      this.timeLeftSecs = this.state.currentSurvey.start.seconds - now;
    }
  };

  callbackStartTimer(val) {
    //Quizzes started
    if (val >= 0) {
      this.checkTimer(val);
    } else {
      this.checkSurveyAndQuestion();
    }
  }

  checkTimer = (val) => {
    this.getStateTimer();
    if (this.currentAction !== null) {
      if (Math.abs(val - this.timeLeftSecs) > 0) {
        if (this._isMounted) {
          // console.log('checkTimer');
          this.setState({
            timeLeft: this.timeLeftSecs,
            updateNum: this.state.updateNum + 1,
          });
        }
      }
    }
  };

  callbackTimer(val) {
    // check again the question
    console.log(" from callbackTimer");
    this.checkSurveyAndQuestion();
    // check timer and current question time
    if (this.state.currentQuestion != null) {
      if (this.state.currentQuestion.displayResult === false) {
        var timePast = this.state.currentSurvey.start.seconds;
        var time_pause = 0;
        if (
          this.currentSurvey.display_type ===
          OPTIONS_QUESTIONS_TV_AND_RESULTS ||
          this.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
        ) {
          time_pause = TIME_PAUSE;
        }
        // console.log(timePast);
        // console.log(val);
        for (let i = 0; i < this.state.currentQuestion.order; i++) {
          const question = this.state.currentSurvey.questions.filter((e) => {
            return e.order === i + 1;
          })[0];
          if (i === 0) {
            timePast = timePast + question.time + TIME_EXTRA_ANIM;
          } else {
            timePast = timePast + time_pause + question.time + TIME_EXTRA_ANIM;
          }
        }
        const now = Math.floor(
          (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) /
          1000
        );
        const timeLeft = timePast - now;
        if (Math.abs(timeLeft - val) > 0) {
          if (this._isMounted) {
            this.setState({
              timeLeft: timeLeft,
            });
          }
        }
      }
    }
  }

  finishCountDownCallback = () => {
    if (
      this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS ||
      this.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
    ) {
      //Show result and time response
      if (this.state.currentQuestion.displayResult === true) {
        // Next question
        console.log(" from finishCountDownCallback");
        this.checkSurveyAndQuestion();
        if (this._isMounted) {
          this.setState({ displayResult: false });
        }
      } else {
        if (this._isMounted) {
          this.setState({ displayResult: true });
        }
      }
    } else {
      // Next question
      // console.log("should go to next question");
      console.log(" from finishCountDownCallback");
      this.checkSurveyAndQuestion(true);
    }
  };

  callBackFromAnswered = () => {
    if (this.state.currentSurvey.display_type === OPTIONS_NO_COUNTER) {
      this.checkSurveyAndQuestion(true);
    }
  };

  render() {
    const { navigate } = this.props.navigation;
    console.log("this.props = ", this.props);
    return (
      <View
        style={{
          width: this.props.maxWidth,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {this.state.screen === "CountDown" && (
          <>
            {this.props.mode !== "async" && (
              <CountDown
                mode="insider"
                key={this.state.updateNum}
                {...this.props}
                timeLeft={this.state.timeLeft}
                msgWaiting={`${isEn() ? this.state.currentSurvey?.msg_waiting_en || '' : this.state.currentSurvey?.msg_waiting || ''}` || ''}
                msgWaitingPrefix={`${isEn() ? this.state.currentSurvey?.msg_waiting_prefix_en || '' : this.state.currentSurvey?.msg_waiting_prefix || ''}` || ''}
                callbackTimer={this.callbackStartTimer}
              />
            )}
            {this.props.mode === "async" && (
              <LottieView
                style={{
                  height: 100,
                  width: 100,
                  top: 200,
                  alignSelf: "center",
                }}
                source={require("../../assets/anim/loader.json")}
                autoPlay
                loop
              />
            )}
          </>
        )}

        {this.state.screen === "CountDownAsync" && (
          <>
            <CountDown
              mode="insider"
              key={this.state.updateNum}
              {...this.props}
              msgWaiting={`${isEn() ? this.state.currentSurvey?.msg_waiting_en || '' : this.state.currentSurvey?.msg_waiting || ''}` || ''}
              msgWaitingPrefix={`${isEn() ? this.state.currentSurvey?.msg_waiting_prefix_en || '' : this.state.currentSurvey?.msg_waiting_prefix || ''}` || ''}
              timeLeft={this.state.timeLeft}
              callbackTimer={this.callbackStartTimer}
            />
          </>
        )}

        {this.state.screen === "Question" &&
          this.state.currentQuestion &&
          this.state.shouldShow === true && (
            <View>
              <View
                style={{
                  marginTop: 10,
                  flexDirection: "row",
                }}
              >
                {this.state.currentSurvey.display_type !==
                  OPTIONS_NO_COUNTER && (
                    <FadeSlideView direction="down">
                      <ImageBackground
                        key={
                          this.state.currentQuestion.displayResult +
                          "_" +
                          this.state.currentQuestion.id
                        }
                        style={{
                          width: 66,
                          height: 66,
                          marginLeft: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        resizeMode={"contain"}
                        source={require("../../assets/images/circle.png")}
                      >
                        <CountDownCircle
                          key={this.state.timeLeft}
                          seconds={
                            this.state.currentQuestion.displayResult
                              ? this.state.currentQuestion.displayResultTimeLeft
                              : this.state.timeLeft || 10
                          }
                          radius={28}
                          borderWidth={8}
                          color={
                            this.state.currentQuestion.displayResult
                              ? "limegreen"
                              : "#ff003f"
                          }
                          bgColor="#fff"
                          textStyle={{ fontSize: 18 }}
                          finishCallback={() => this.finishCountDownCallback()}
                          callbackTimer={this.callbackTimer}
                        />
                      </ImageBackground>
                    </FadeSlideView>
                  )}
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 18,
                      fontWeight: "bold",
                      paddingLeft: 5,
                      paddingRight: 5,
                      textAlign: "center",
                    }}
                  >
                    {this.state.currentQuestion.question}
                  </Text>
                </View>
              </View>
              <PagingControl
                key={this.state.currentQuestion.order}
                style={{ marginTop: 15 }}
                max={this.state.currentSurvey.questions.length}
                width={320}
                current={this.state.currentQuestion.order}
              />


              <View
                style={{
                  paddingBottom: 5,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Response
                  {...this.props}
                  key={
                    this.state.currentQuestion.displayResult +
                    "_" +
                    this.state.currentQuestion.id
                  }
                  data={{
                    lastQuestion:
                      this.state.currentQuestion.order ===
                      this.state.currentSurvey.questions.length,
                    answers: this.currentQuestion.answers,
                    currentQuestionId: this.state.currentQuestion.id,
                    currentQuestionTimeLeft:
                      this.state.currentQuestion.timeLeft,
                    currentQuestionTimeEnd:
                      this.state.currentQuestion.timeEnd,
                    currentSurveyId: this.state.currentSurvey.id,
                    currentSurveyVersion: this.state.currentSurvey.version
                      ? this.state.currentSurvey.version
                      : 0,
                    currentSurveySeconds:
                      this.state.currentSurvey.start.seconds,
                    withImage: this.state.currentQuestion.withImage,
                    withResult: this.state.currentQuestion.displayResult,
                    currentQuestion: this.state.currentQuestion,
                    loginNotRequired: this.props.loginNotRequired,
                  }}
                  callBackFromAnswered={this.callBackFromAnswered}
                  modeTele={false}
                />
              </View>

              {(THEME === "takeover" || THEME === "challengefff") && (
                <View
                  style={{
                    height: 70,
                    width: "100%",
                    flexDirection: "row",
                    // position: "fixed",
                    marginTop: 20,
                    // bottom: 0,
                    // shadowColor: "#000",
                    // shadowOpacity: 1,
                    // shadowRadius: 30,
                  }}
                >
                  <a
                    href="https://www.betclic.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        style={{
                          textAlign: "center",
                          color: TEXT_COLOR,
                          marginBottom: 5,
                        }}
                      >
                        Le concours de pronostics par
                      </Text>
                      <Image
                        style={{ width: this.props.maxWidth, height: 50 }}
                        source="https://firebasestorage.googleapis.com/v0/b/jeutop14.appspot.com/o/betclic.png?alt=media&token=7ccd915c-ac18-4eaa-9e68-eed9667849f8"
                        resizeMode="contain"
                      />
                    </View>
                  </a>
                </View>
              )}

            </View>
          )}

        {this.state.screen === "Thankyou" && this.state.currentSurvey && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 30,
            }}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: 25,
                fontWeight: "bold",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {this.state.currentSurvey.name}
            </Text>
            <Text
              style={{
                color: "darkgrey",
                textTransform: "uppercase",
                fontSize: 20,
                fontWeight: "100",
                marginBottom: 50,
                textAlign: "center",
              }}
            >
              {this.state.currentSurvey.desc}
            </Text>
            <FadeSlideView
              style={{ justifyContent: "center", alignItems: "center" }}
              direction="down"
              delay={500}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 30,
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                {this.state.currentSurvey.thankyou_msg}
              </Text>
            </FadeSlideView>

            <View style={{
              position: 'fixed',
              bottom: BANNER_BOTTOM_ENABLE() ? 70 : 0,
              width: '100%',
              backgroundColor: MENU_COLOR,
              padding: 15
            }}>
              <TouchableOpacity
                onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate(PAGE_GAMES()) : navigate("Home")}
              >
                <View style={{
                  flexDirection: "row",
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Image
                    style={{ position: 'absolute', left: 0, width: 20, height: 20, margin: 3 }}
                    source={require("../../assets/images/back.svg")}
                    resizeMode="contain"
                  />
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 16,
                      textAlign: "center",
                      fontWeight: 'bold'
                    }}
                  >
                    {SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

            {this.state.currentSurvey.login === OPTIONS_LOGIN_NOT_REQUIRED &&
              !this.props.authUser && (
                <FadeSlideView
                  direction="down"
                  delay={1000}
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 30,
                  }}
                >
                  <Text
                    style={{
                      color: "#FFF",
                      textTransform: "uppercase",
                      fontSize: RF(2),
                      fontWeight: "200",
                      textAlign: "center",
                      marginLeft: "20%",
                      marginRight: "20%",
                    }}
                  >
                    Vous devez vous connecter pour soumettre vos réponses :
                  </Text>

                  <View
                    style={{
                      flex: 1,
                      marginTop: 30,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "stretch",
                      alignContent: "space-between",
                    }}
                  >
                    <TOButton
                      {...this.props}
                      onClick={() => navigate("SignUp")}
                      text={t("create_new_account_title")}
                    ></TOButton>
                    <TOButton
                      {...this.props}
                      onClick={() => navigate("SignIn")}
                      text={t("signin")}
                    ></TOButton>
                  </View>
                </FadeSlideView>
              )}

            {(THEME === "takeover" || THEME === "challengefff") && (
              <View
                style={{
                  height: 70,
                  width: "100%",
                  flexDirection: "row",
                  // position: "fixed",
                  marginTop: 20,
                  // bottom: 0,
                  // shadowColor: "#000",
                  // shadowOpacity: 1,
                  // shadowRadius: 30,
                }}
              >
                <a
                  href="https://www.betclic.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <View style={{ flexDirection: "column" }}>
                    <Text
                      style={{
                        textAlign: "center",
                        color: TEXT_COLOR,
                        marginBottom: 5,
                      }}
                    >
                      Le concours de pronostics par
                    </Text>
                    <Image
                      style={{ width: this.props.maxWidth, height: 50 }}
                      source="https://firebasestorage.googleapis.com/v0/b/jeutop14.appspot.com/o/betclic.png?alt=media&token=7ccd915c-ac18-4eaa-9e68-eed9667849f8"
                      resizeMode="contain"
                    />
                  </View>
                </a>
              </View>
            )}
          </View>
        )}
      </View>
    );
  }
}

export default withTranslation(Sondages);
