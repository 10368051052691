import React, { Component } from "react";
import { TouchableHighlight, View } from "react-native";
import {
  Text,
  Input,
  Button,
  CheckBox,
  IndexPath,
  Icon,
  AutocompleteItem,
} from "@ui-kitten/components";
import LottieView from "react-native-web-lottie";
import RF from "react-native-responsive-fontsize";
import { withFirebase } from "../Firebase";
import ReactHtmlParser from 'react-html-parser';
import {
  TEXT_OPTION_1,
  URL_RULES,
  TEXT_COLOR,
  TEXT_OPTION_4,
  FORCED_VALIDATION_EMAIL,
  THEME,
  SHOW_GAMES,
  LOGE_TEXT,
  PROFILE_LOGE_NUMBER,
  PROFILE_JOB_OBLIGATION,
  PROFILE_JOB_TEXT,
  PROFILE_JOB_SELECT,
  PROFILE_JOB,
  PROFILE_COMPANY_OBLIGATION,
  PROFILE_COMPANY,
  PROFILE_DOB_OBLIGATION,
  PROFILE_DOB,
  PROFILE_PHONE_OBLIGATION,
  PROFILE_PHONE,
  PROFILE_ADDRESS_OBLIGATION,
  PROFILE_ADDRESS_COUNTRY,
  PROFILE_ADDRESS_CITY,
  PROFILE_ADDRESS_POSTCODE,
  PROFILE_ADDRESS_STREET,
  PROFILE_ADDRESS,
  PROFILE_CIVIL,
  PROFILE_JOB_ITEMS,
  LOGE_NUMBERS,
  TEXT_OPTION_3_OBLIGATION,
  TEXT_OPTION_2_OBLIGATION,
  TEXT_OPTION_3,
  TEXT_OPTION_2,
  DISPLAY_NAME,
  MAIN_URL,
  GO_HOME_INSIDE,
  PROFILE_QUESTIONS_INFO_1_TEXT,
  PROFILE_QUESTIONS_INFO_1_ITEMS,
  PROFILE_QUESTIONS_INFO_3_TEXT,
  PROFILE_QUESTIONS_INFO_3_ITEMS,
  PROFILE_QUESTIONS_INFO_2_ITEMS,
  PROFILE_QUESTIONS_INFO_2_TEXT,
  PAGE_GAMES,
  TEXT_LOGIN,
} from "../../constants/const";
import isoCountry from "../../libs/iso3166";
import { withTranslation, t } from "react-multi-lang";
import Filter from "../../helpers/badwords";
import { Picker, TouchableOpacity } from "react-native-web";
import moment from "moment";
import { withAuthorization } from "../Session";

const filterBadWords = new Filter();

// const filter = (item, query) =>
//   item.title.toLowerCase().includes(query.toLowerCase());

export class SignUp extends Component {
  currentUser = null;
  jobList = [];
  logeNumberList = [];
  question1List = [];
  question2List = [];
  question3List = [];
  countryList = isoCountry.countries;
  errMessage = t("field_requires")
  state = {
    job: false,
    logeNumber: false,
    jobIndex: new IndexPath(0, 0),
    logeNumberIndex: new IndexPath(0, 0),
    question1: "",
    question1Index: new IndexPath(0, 0),
    question2: "",
    question2Index: new IndexPath(0, 0),
    question3: "",
    question3Index: new IndexPath(0, 0),
    isLoading: false
  };
  shoudGoProfile = false;
  GoogleIcon = (props) => <Icon {...props} name="google" />;

  constructor(props) {

    super(props);
    if (props.firebase)
      props.firebase.remoteConfigs().onSnapshot((configSnapshot) => {
        var lData = configSnapshot.data();
        console.log("loaded new remote config");
        // bind data
        if (lData) {
          // Save all key and value of lData to storage local
          for (var key in lData) {
            if (lData.hasOwnProperty(key)) {
              localStorage.setItem(key, lData[key]);
            }
          }
          //check version of config then refresh if needed
          let _lastVersion = localStorage.getItem("last_version");
          let _currentVersion = localStorage.getItem("VERSION");
          console.log('last_version = ', _lastVersion, ' _currentVersion = ', _currentVersion, ' _check = ', (parseInt(`${_lastVersion}` || "0", 10) < parseInt(`${_currentVersion}` || "0", 10)));

          if (parseInt(`${_lastVersion || "0"}`, 10) < parseInt(`${_currentVersion || "0"}` || "0", 10)) {
            // save and restart
            localStorage.setItem("last_version", _currentVersion);
            // window.location.reload();
          }
        }
      });
  }

  componentDidMount() {

    console.log("this.props = ", this.props);

  }

  updateProfile = (authUser) => {
    console.log(this.state);
    var errMessage = t("field_requires");
    if (this.validInput()) {
      this.setState({ error: null });
      const {
        email,
        password,
        passwordActuel,
        civil,
        firstName,
        lastName,
        company,
        phone,
        address,
        postcode,
        city,
        country,
        job,
        dob,
      } = this.state;
      const { navigate } = this.props.navigation;
      const { firebase } = this.props;
      const that = this;


      firebase
        .user(authUser.uid)
        .set(
          {
            email,
            civil: civil || "",
            firstName,
            lastName,
            company: company || false,
            phone: phone || false,
            newsletter: that.state.newsletter || false,
            newsletter_commercial: that.state.newsletter_commercial || false,
            agreement: true,
            theme: THEME,
            address: address || "",
            postcode: postcode || "",
            city: city || "",
            country: country || "",
            job: job || "",
            dob: dob || "",
            logeNumber: that.state.logeNumber || false,
            logeNumberDate: moment().format("YYYY-MM-DD"),
            question1: that.state.question1 !== "" ? `${PROFILE_QUESTIONS_INFO_1_TEXT} :: ${that.state.question1}` : "",
            question2: that.state.question2 !== "" ? `${PROFILE_QUESTIONS_INFO_2_TEXT} :: ${that.state.question2}` : "",
            question3: that.state.question3 !== "" ? `${PROFILE_QUESTIONS_INFO_3_TEXT} :: ${that.state.question3}` : "",
            uid: authUser.uid,
            pointTotal: 0,
            pointQuiz: 0,
            pointShop: 0,
            pointForecast: 0,
            timeRightMs: 0,
            timeRight: 0
          },
          { merge: true }
        )
        .then(() => {
          that.setState({ error: null });
          var _displayName =
            DISPLAY_NAME === "SHORT"
              ? `${firstName} ${lastName.charAt(0).toUpperCase()}.`
              : `${firstName} ${lastName}`;
          that.currentUser = {
            uid: this.state.id,
            email: email,
            displayName: _displayName,
          };
          firebase.auth.currentUser
            .updateProfile({
              displayName: _displayName,
            })
            .then(function () {
              //change password if needed
              if (that.state.changePassword) {
                if (that.state.anonymous === true) {
                  // convert anonymous user
                  var credential = firebase.getCredential(email, password);
                  firebase.auth.currentUser
                    .linkAndRetrieveDataWithCredential(credential)
                    .then(
                      (usercred) => {
                        // var user = usercred.user;
                        // console.log("Anonymous account successfully upgraded");
                        firebase
                          .user(that.state.id)
                          .set(
                            {
                              anonymous: false,
                            },
                            { merge: true }
                          )
                          .then(() => {
                            if (GO_HOME_INSIDE) {
                              navigate("HomeInside");
                            } else {
                              navigate(PAGE_GAMES());
                            }
                          })
                          .catch((error) => {
                            that.setState({ error });
                            console.log(error);
                          });
                      },
                      (error) => {
                        that.setState({ error });
                        console.log("Error upgrading anonymous account", error);
                      }
                    );
                } else {
                  //change pw for normal users
                  var cred = firebase.getCredential(email, passwordActuel);
                  firebase.auth.currentUser
                    .reauthenticateWithCredential(cred)
                    .then(() => {
                      firebase.auth.currentUser
                        .updatePassword(password)
                        .then(() => {
                          console.log("Password updated!");
                          if (GO_HOME_INSIDE) {
                            navigate("HomeInside");
                          } else {
                            navigate(PAGE_GAMES());
                          }
                        })
                        .catch((error) => {
                          that.setState({ error });
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      that.setState({ error });
                      console.log(error);
                    });
                }
              } else {
                // Save data if needed
                that.saveSAIfNeeded(that.currentUser);
                that.savePronoIfNeed(_displayName);
                that.gotoAsyncIfNeeded();
                if (GO_HOME_INSIDE) {
                  navigate("HomeInside");
                } else {
                  navigate(PAGE_GAMES());
                }
                // that._shouldRedirectToEvent();
              }
            })
            .catch(function (error) {
              that.setState({ error });
              console.log(error);
            });
        })
        .catch((error) => {
          that.setState({ error });
          console.log(error);
        });

      // Go to notice screen for checking email
    } else {
      if (
        filterBadWords.isProfane(this.state.firstName) ||
        filterBadWords.isProfane(this.state.lastName)
      ) {
        errMessage = `${errMessage}\n ${t("keyword_blackedlist")}`;
      }
      this.setState({ error: { message: errMessage } });
    }
  };

  _shouldRedirectToEvent = () => {
    var out = [];
    const { navigate } = this.props.navigation;
    if (this.props.currentMatch) {
      out.push({
        type: "match",
        priority: 0,
        game: this.props.currentMatch.type,
        start: this.props.currentMatch.start.seconds,
      });
    }

    if (this.props.currentQuiz && this.props.currentQuiz.name !== "") {
      out.push({
        type: "quiz",
        priority: 1,
        start: this.props.currentQuiz.start.seconds,
      });
    }
    if (this.props.currentAsyncs) {
      for (let i = 0; i < this.props.currentAsyncs.length; i++) {
        const a = this.props.currentAsyncs[i];
        if (a.id) {
          out.push({
            type: "async",
            priority: 1,
            start: a.start.seconds,
            data: a,
          });
        }
      }
    }
    if (this.props.currentSurvey && this.props.currentSurvey.name !== "") {
      out.push({
        type: "survey",
        priority: 1,
        start: this.props.currentSurvey.start.seconds,
      });
    }
    if (this.props.currentSAs) {
      for (let i = 0; i < this.props.currentSAs.length; i++) {
        const a = this.props.currentSAs[i];
        if (a.id) {
          out.push({
            type: "sa",
            priority: 1,
            start: a.start.seconds,
            data: a,
          });
        }
      }
    }
    if (this.props.currentDuel && this.props.currentDuel.name !== "") {
      out.push({
        type: "duel",
        priority: 1,
        start: this.props.currentDuel.start.seconds,
      });
    }

    if (this.props.currentDraw && this.props.currentDraw.name !== "") {
      out.push({
        type: "draw",
        priority: 1,
        start: this.props.currentDraw.start.seconds,
      });
    }

    out.sort((a, b) => {
      return a.start - b.start;
    });
    if (out.length > 0) {
      const event = out[0];
      switch (event.type) {
        case "match":
          if (event.game === "match_rugby") {
            navigate("Rugby");
          } else {
            navigate("Forecast");
          }
          return;
        case "quiz":
          navigate("Quizzes");
          return;
        case "duel":
          navigate("Duel");
          return;
        case "draw":
          navigate("Draw");
          return;
        case "survey":
          navigate("Surveys");
          return;
        default:
          break;
      }
    }

    if (GO_HOME_INSIDE) {
      navigate("HomeInside");
    } else {
      if (SHOW_GAMES) {
        navigate(PAGE_GAMES());
      } else {
        navigate("HomeInside");
      }
    }
  };

  saveSAIfNeeded = (userData) => {
    const needSaveSAString = window.localStorage.getItem("needSaveSA");
    if (needSaveSAString) {
      const needSaveSA = JSON.parse(needSaveSAString);
      const calculateSurvey =
        this.props.firebase.functions.httpsCallable("calculateSondage");
      for (let i = 0; i < needSaveSA.questions.length; i++) {
        const questionData = needSaveSA.questions[i];
        if (userData) {
          const savedData = {
            ...questionData.savedData,
            userId: userData.uid,
            ...userData,
          };
          const calculData = questionData.calculData;
          calculData.userData = savedData;

          // Save for calcul results
          this.props.firebase
            .surveys_result(`${questionData.collection}${userData.uid}`)
            .set(savedData)
            .then(() => {
              calculateSurvey(calculData).then((re) => {
                console.log("calculateSurvey done");
              });
            });
        }
      }
      //delete needSaveSA
      window.localStorage.removeItem("needSaveSA");
      window.localStorage.setItem("SAmsg", t("your_participation_saved"));
    }
  };

  savePronoIfNeed = (_displayName) => {
    const toSave = window.localStorage.getItem("match_forecast_to_save");
    // const { navigate } = this.props.navigation;
    console.log("_displayName savePronoIfNeed = ", _displayName);
    if (toSave) {
      const dataToSave = JSON.parse(toSave);
      // Save to firestore
      this.props.firebase
        .pronostics_responses(
          `${this.currentUser.uid}_${dataToSave.pronosticId}`
        )
        .set({
          ...dataToSave,
          userId: this.currentUser.uid,
          displayName: _displayName,
          email: this.currentUser.email,
        });
      window.localStorage.removeItem("match_forecast_to_save");
      window.localStorage.setItem("match_forecast", JSON.stringify(dataToSave));
    }
    // navigate("Home");
  };

  gotoAsyncIfNeeded = () => {
    const needOpenDataString = window.localStorage.getItem("needOpenAsync");
    if (needOpenDataString) {
      const needOpenData = JSON.parse(needOpenDataString);
      switch (needOpenData.type) {
        case "sa":
          window.location.replace(
            `https://${MAIN_URL}/sa/?sid=${needOpenData.id}`
          );
          break;

        case "async":
          window.location.replace(
            `https://${MAIN_URL}/async/?aid=${needOpenData.id}`
          );
          break;

        default:
          break;
      }
    }
  };

  onGoogleLogin = () => {
    var firebase = this.props.firebase;
    var provider = new firebase.GoogleAuthProvider();
    firebase.auth.signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        console.log("logged in with Google");
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        console.log("google error = ", error);
      });
  };

  validInput = () => {
    this.errMessage = t("field_requires");
    console.log("this.state = ", this.state);
    if (this.state === null) {
      return false;
    }

    if (!this.state.firstName || this.state.firstName === "") {
      return false;
    }

    if (!this.state.lastName || this.state.lastName.length === "") {
      return false;
    }

    if (filterBadWords.isProfane(this.state.firstName)) {
      return false;
    }

    if (filterBadWords.isProfane(this.state.lastName)) {
      return false;
    }

    if (!this.state.email || this.state.email.length === "") {
      return false;
    }

    if (
      this.state.changePassword &&
      (!this.state.password || this.state.password.length === "")
    ) {
      return false;
    }

    if (!this.state.agreement || this.state.agreement === false) {
      console.log("agreement");
      return false;
    }

    if (TEXT_OPTION_2_OBLIGATION) {
      if (!this.state.newsletter || this.state.newsletter === false) {
        console.log("newsletter");
        return false;
      }
    }

    if (TEXT_OPTION_3_OBLIGATION) {
      if (
        !this.state.newsletter_commercial ||
        this.state.newsletter_commercial === false
      ) {
        console.log("newsletter_commercial");
        return false;
      }
    }

    if (PROFILE_DOB_OBLIGATION && PROFILE_DOB) {
      if (!this.state.dob || this.state.dob === false) {
        return false;
      }
      // calculer age if greater than 18
      var age = new Date().getFullYear() - new Date(this.state.dob).getFullYear();
      if (age < 18) {
        this.errMessage = "Vérifiez la date de naissance. Ce dispositif est réservé aux personnes majeures";
        return false;
      }
    }

    if (PROFILE_ADDRESS && PROFILE_ADDRESS_OBLIGATION) {
      if (PROFILE_ADDRESS_STREET) {
        if (!this.state.address || this.state.address === "") {
          console.log("address");
          return false;
        }
      }
      if (PROFILE_ADDRESS_POSTCODE) {
        if (!this.state.postcode || this.state.postcode === "") {
          console.log("postcode");
          return false;
        }
      }
      if (PROFILE_ADDRESS_CITY) {
        if (!this.state.city || this.state.city === "") {
          console.log("city");
          return false;
        }
      }
    }

    if (PROFILE_JOB_OBLIGATION && PROFILE_JOB) {
      if (!this.state.job || this.state.job === "") {
        console.log("job");
        return false;
      }
    }

    if (PROFILE_PHONE_OBLIGATION && PROFILE_PHONE) {
      if (!this.state.phone || this.state.phone === false) {
        console.log("phone");
        return false;
      }
    }

    if (PROFILE_LOGE_NUMBER) {
      if (!this.state.logeNumber || this.state.logeNumber === "") {
        console.log("logeNumber");
        return false;
      }
    }

    if (PROFILE_COMPANY_OBLIGATION && PROFILE_COMPANY) {
      if (!this.state.company || this.state.company === false) {
        console.log("company");
        return false;
      }
    }

    var re = /\S+@\S+\.\S+/;
    return re.test(String(this.state.email).toLowerCase());
  };

  onValidateClicked = async () => {
    console.log(this.state);
    if (this.validInput()) {
      this.setState({ error: null });

      const { email, password } = this.state;

      const { firebase } = this.props;

      if (this.props.firebase && this.props.firebase.analytics) {
        this.props.firebase.analytics.logEvent("button_validate_inscription_1", {
          email: email
        });
      }

      const that = this;
      //send email if needed
      if (FORCED_VALIDATION_EMAIL) {
        window.localStorage.setItem("needSendVerification", true);
      }
      // this.shoudGoProfile = true;
      firebase
        .doCreateUserWithEmailAndPassword(email, password).then((userCredential) => {
          var user = userCredential.user.multiFactor.user;
          console.log("doCreateUserWithEmailAndPassword = ", user);
          this.updateProfile(user);

        })
        .catch(function (err) {
          // Some error occurred, you can inspect the code: error.code
          that.shoudGoProfile = false;
          console.log(err);
          that.setState({ error: { message: err.code } });
          if (that.props.firebase && that.props.firebase.analytics) {
            that.props.firebase.analytics.logEvent(`error_inscription_1_${err.code}`, {
              error: err.code,
            });
          }
        });

      // Go to notice screen for checking email
    } else {
      if (this.props.firebase && this.props.firebase.analytics) {
        this.props.firebase.analytics.logEvent(`error_inscription_1_${this.errMessage}`, {
          error: this.errMessage,
        });
      }
      if (
        filterBadWords.isProfane(this.state.firstName) ||
        filterBadWords.isProfane(this.state.lastName)
      ) {
        this.errMessage = `${this.errMessage}\n ${t("keyword_blackedlist")}`;
      }
      this.setState({ error: { message: this.errMessage } });
    }
  };


  _renderOptionComplete = (item, index) => (
    <AutocompleteItem key={index} title={item.title} />
  );

  _renderItem = (value, label) => {
    return (
      <Picker.Item
        // style={{ color: TEXT_COLOR }}
        key={label}
        label={label}
        value={value}
      />
    );
  };

  _pickerItemLogeNumber = () => {
    var out = [];
    out.push(this._renderItem("", ["hasard-fdj"].includes(THEME) ? `Choix de réponse *` : `${LOGE_TEXT} *`));
    // merge arr of salon and loges
    var arrList = LOGE_NUMBERS();
    for (let i = 0; i < arrList.length; i++) {
      var loge = arrList[i];
      //Get Name from data with geolocation
      loge = loge.split(";")[0];
      out.push(this._renderItem(loge, loge));
      this.logeNumberList.push(loge);
    }
    return out;
  };

  _pickerItemJobList = () => {
    var out = [];
    out.push(
      this._renderItem(
        "",
        `${PROFILE_JOB_TEXT} ${PROFILE_JOB_OBLIGATION ? "*" : ""}`
      )
    );
    var arrList = PROFILE_JOB_ITEMS();
    for (let i = 0; i < arrList.length; i++) {
      const item = arrList[i];
      out.push(this._renderItem(item, item));
      this.jobList.push(item);
    }
    return out;
  };

  _pickerItemQuestion1List = () => {
    var out = [];
    var arrList = PROFILE_QUESTIONS_INFO_1_ITEMS();
    for (let i = 0; i < arrList.length; i++) {
      const item = arrList[i];
      out.push(this._renderItem(item, item));
      this.question1List.push(item);
    }
    return out;
  };
  _pickerItemQuestion2List = () => {
    var out = [];
    var arrList = PROFILE_QUESTIONS_INFO_2_ITEMS();
    for (let i = 0; i < arrList.length; i++) {
      const item = arrList[i];
      out.push(this._renderItem(item, item));
      this.question2List.push(item);
    }
    return out;
  };
  _pickerItemQuestion3List = () => {
    var out = [];
    var arrList = PROFILE_QUESTIONS_INFO_3_ITEMS();
    for (let i = 0; i < arrList.length; i++) {
      const item = arrList[i];
      out.push(this._renderItem(item, item));
      this.question3List.push(item);
    }
    return out;
  };

  render() {

    console.log(' maxWidth: ', this.props.maxWidth);
    const { navigate } = this.props.navigation;
    return (
      <View
        style={{
          flexDirection: "column",
          paddingVertical: 24,
          paddingHorizontal: 16,
          maxWidth: this.props.maxWidth
        }}
        keyboardShouldPersistTaps="always"
      >
        {ReactHtmlParser(`${TEXT_LOGIN()}`)}
        <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>

          <View style={{ borderBottomWidth: 2, borderColor: TEXT_COLOR, opacity: 1 }}>
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: 15,
                margin: 10,
                fontWeight: "bold",
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              {t('create_account')}
            </Text>
          </View>
          <TouchableOpacity onPress={() => navigate("SignIn")} >
            <View style={{ borderBottomWidth: 2, borderColor: TEXT_COLOR, opacity: 0.6 }}>
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 15,
                  margin: 10,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {t('login')}
              </Text>
            </View>
          </TouchableOpacity>
        </View>

        {this.state && this.state.error && (
          <Text category="s1" style={{
            color: TEXT_COLOR
          }}>
            --{" "}
            {this.state.error.message === "auth/email-already-in-use"
              ? t("email_already_exist")
              : this.state.error.message === "auth/wrong-password"
                ? t("wrong_password")
                : this.state.error.message}{" "}
            --
          </Text>
        )}

        {this.state &&
          this.state.error &&
          this.state.error.message === "auth/email-already-in-use" && (
            <>
              {/* <TouchableHighlight
                style={{ alignSelf: "flex-end", marginBottom: 10 }}
                onPress={() => this.props.navigation.navigate("ForgotPassword")}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    textAlign: "right",
                    textDecorationLine: "underline",
                  }}
                >
                  {t("forgot_password")}
                </Text>
              </TouchableHighlight> */}
              <TouchableHighlight
                style={{ alignSelf: "flex-end", marginBottom: 10 }}
                onPress={() => this.props.navigation.navigate("SignIn")}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    textAlign: "right",
                    textDecorationLine: "underline",
                  }}
                >
                  {t("email_exist")}
                </Text>
              </TouchableHighlight>
            </>
          )}

        <Input
          status="control"
          autoFocus={true}
          textContentType="emailAddress"
          label={"Email *"}
          placeholder={"Email *"}
          placeholderTextColor={"#ddd"}
          keyboardType="email-address"
          onChangeText={(text) => {
            this.setState({ email: text });
          }}
          value={(this.state && this.state.email) || ""}
        />
        <View style={{ alignSelf: 'end' }}><Text category="s2">{t("enter_an_email_valid")}</Text></View>

        <Input
          status="control"
          secureTextEntry={true}
          label={t("password")}
          placeholder={t("password")}
          placeholderTextColor={"#ddd"}
          onChangeText={(text) => {
            this.setState({ password: text });
          }}
          value={(this.state && this.state.password) || ""}
        />
        <View style={{ alignSelf: 'end' }}><Text category="s2">{t("min_6_characters")}</Text></View>

        {PROFILE_CIVIL === true && (
          <View style={{ marginTop: 15 }}>
            <Text category="s2">Civilité</Text>
            <Picker
              label={'Civilité'}
              placeholder={`Civilité`}
              selectedValue={(this.state && this.state.civil) || ""}
              mode="dropdown"
              style={{
                marginTop: 5,
                backgroundColor: "rgba(255, 255, 255, 0.24)",
                borderColor: "rgba(255, 255, 255, 0.4)",
                borderRadius: 4,
                borderWidth: 1,
                minHeight: 40,
                padding: 8,
                // color: TEXT_COLOR,
                marginBottom: 4,
                textAlign: "left",
                textAlignLast: "left",
              }}
              onValueChange={(value, index) => {
                this.setState({
                  civil: value,
                });
              }}
              itemStyle={{ textAlign: "left" }}
            >
              <Picker.Item
                key={"Monsieur"}
                label={"Monsieur"}
                value={"Monsieur"}
              />
              <Picker.Item
                key={"Madame"}
                label={"Madame"}
                value={"Madame"}
              />
              <Picker.Item
                key={"Non binaire"}
                label={"Non binaire"}
                value={"Non binaire"}
              />
            </Picker>
          </View>
        )}
        <Input
          status="control"
          label={t("firstname")}
          placeholder={t("firstname")}
          placeholderTextColor={"#ddd"}
          onChangeText={(text) => {
            this.setState({ firstName: text });
          }}
          value={(this.state && this.state.firstName) || ""}
          autoComplete="false"
          style={{ marginTop: 15 }}
        />
        <Input
          status="control"
          label={t("lastname")}
          placeholder={t("lastname")}
          placeholderTextColor={"#ddd"}
          onChangeText={(text) => {
            this.setState({ lastName: text });
          }}
          value={(this.state && this.state.lastName) || ""}
          autoComplete="false"
          style={{ marginTop: 15 }}
        />
        {PROFILE_ADDRESS && (
          <View style={{}}>
            {PROFILE_ADDRESS_STREET && (
              <Input
                label={t("address")}
                status="control"
                autoComplete="false"
                placeholder={
                  PROFILE_ADDRESS_OBLIGATION
                    ? `${t("address")} *`
                    : `${t("address")}`
                }
                placeholderTextColor={"#ddd"}
                onChangeText={(text) => {
                  this.setState({ address: text });
                }}
                value={(this.state && this.state.address) || ""}
                style={{ marginTop: 15 }}
              />
            )}

            {PROFILE_ADDRESS_POSTCODE && (
              <Input
                label={t("postcode")}
                status="control"
                autoComplete="false"
                placeholder={
                  PROFILE_ADDRESS_OBLIGATION
                    ? `${t("postcode")} *`
                    : `${t("postcode")}`
                }
                placeholderTextColor={"#ddd"}
                onChangeText={(text) => {
                  this.setState({ postcode: text });
                }}
                value={(this.state && this.state.postcode) || ""}
                style={{ marginTop: 15 }}
              />
            )}
            {PROFILE_ADDRESS_CITY && (
              <Input
                label={t("city")}
                status="control"
                autoComplete="false"
                placeholder={
                  PROFILE_ADDRESS_OBLIGATION
                    ? `${t("city")} *`
                    : `${t("city")}`
                }
                placeholderTextColor={"#ddd"}
                onChangeText={(text) => {
                  this.setState({ city: text });
                }}
                value={(this.state && this.state.city) || ""}
                style={{ marginTop: 15 }}
              />
            )}
            {PROFILE_ADDRESS_COUNTRY && (
              <Input
                status="control"
                label={
                  PROFILE_ADDRESS_OBLIGATION
                    ? `${t("country")} *`
                    : `${t("country")}`
                }
                placeholder={
                  PROFILE_ADDRESS_OBLIGATION
                    ? `${t("country")} *`
                    : `${t("country")}`
                }
                placeholderTextColor={"#ddd"}
                onChangeText={(text) => {
                  this.setState({ country: text });
                }}
                value={(this.state && this.state.country) || ""}
                style={{ marginTop: 15 }}
              />
              // <Autocomplete
              //   label={t("country")}
              //   status="control"

              //   value={(this.state && this.state.country) || ""}
              //   onSelect={(index) => {
              //     this.setState({
              //       country: isoCountry.countries[index].title,
              //     });
              //   }}
              //   onChangeText={(query) => {
              //     this.setState({ country: query });
              //     this.countryList = isoCountry.countries.filter((item) =>
              //       filter(item, query)
              //     );
              //   }}
              //   style={{ marginTop: 15 }}
              // >
              //   {this.countryList.map(this._renderOptionComplete)}
              // </Autocomplete>
            )}
          </View>
        )}
        {PROFILE_PHONE === true && (
          <Input
            status="control"
            label={PROFILE_PHONE_OBLIGATION ? `${t("phone")} *` : `${t("phone")}`}
            placeholder={PROFILE_PHONE_OBLIGATION ? `${t("phone")} *` : `${t("phone")}`}
            placeholderTextColor={"#ddd"}
            keyboardType="phone-pad"
            onChangeText={(text) => {
              this.setState({ phone: text });
            }}
            value={(this.state && this.state.phone) || ""}
            style={{ marginTop: 15 }}
          />
        )}
        {PROFILE_DOB && (
          <View style={{ marginTop: 5 }}>
            <Text category="s2">{PROFILE_DOB_OBLIGATION ? `${t("birthdate")} *` : `${t("birthdate")}`}</Text>
            <input
              onChange={(event) => {
                this.setState({ dob: event.target.value });
              }}
              value={(this.state && this.state.dob) || ""}
              autoComplete="false"
              style={{
                marginTop: 4,
                backgroundColor: "rgba(255, 255, 255, 0.24)",
                borderColor: "rgba(255, 255, 255, 0.4)",
                borderRadius: 4,
                borderWidth: 1,
                height: 40,
                paddingLeft: 8,
                paddingRight: 8,
                color: TEXT_COLOR,
                marginBottom: 4,
                textAlign: "left",
                textAlignLast: "left",
              }}
              type="date"
              min="1900-01-01"
              max="2023-12-31"
            />
          </View>
        )}

        {PROFILE_COMPANY === true && (
          <Input
            label={t("company")}
            status="control"
            placeholder={
              PROFILE_COMPANY_OBLIGATION
                ? `${t("company")} *`
                : `${t("company")}`
            }
            placeholderTextColor={"#ddd"}
            onChangeText={(text) => {
              this.setState({ company: text });
            }}
            value={(this.state && this.state.company) || ""}
            autoComplete="false"
            style={{ autoComplete: false, marginTop: 15 }}
          />
        )}
        {PROFILE_JOB && (
          <View style={{}}>
            {PROFILE_JOB_SELECT !== true && (
              <Input
                label={`${t("function")} ${PROFILE_JOB_OBLIGATION ? "*" : ""}`}
                autoComplete="false"
                status="control"
                placeholder={`${t("function")} ${PROFILE_JOB_OBLIGATION ? "*" : ""}`}
                placeholderTextColor={"#ddd"}
                onChangeText={(text) => {
                  this.setState({ job: text });
                }}
                value={(this.state && this.state.job) || ""}
                style={{ marginTop: 15 }}
              />
            )}

            {PROFILE_JOB_SELECT === true && (
              <Picker
                label={`${PROFILE_JOB_TEXT} ${PROFILE_JOB_OBLIGATION ? "*" : ""}`}
                placeholder={`${PROFILE_JOB_TEXT} ${PROFILE_JOB_OBLIGATION ? "*" : ""}`}
                status="control"
                selectedValue={this.state.job}
                mode="dropdown"
                style={{
                  marginTop: 4,
                  backgroundColor: "rgba(255, 255, 255, 0.24)",
                  borderColor: "rgba(255, 255, 255, 0.4)",
                  borderRadius: 4,
                  borderWidth: 1,
                  minHeight: 40,
                  padding: 8,
                  // color: TEXT_COLOR,
                  // background: BUTTON_COLOR,
                  marginBottom: 4,
                  textAlign: "left",
                  textAlignLast: "left",
                }}
                onValueChange={(itemValue, index) => {
                  var _name = this.jobList[0];
                  if (index >= 0) {
                    _name = this.jobList[index - 1];
                  }
                  this.setState({
                    jobIndex: index,
                    job: _name,
                  });

                }}
              >
                {this._pickerItemJobList()}
              </Picker>
            )}
          </View>
        )}
        {PROFILE_LOGE_NUMBER === true && (
          <View style={{ marginTop: 15 }}>
            <Text category="s2">{`${LOGE_TEXT} *`}</Text>
            <Picker
              placeholder={["hasard-fdj"].includes(THEME) ? `Choix de réponse *` : `${LOGE_TEXT} *`}
              selectedValue={(this.state && this.state.logeNumber) || ""}
              mode="dropdown"
              style={{
                marginTop: 5,
                backgroundColor: "rgba(255, 255, 255, 0.24)",
                borderColor: "rgba(255, 255, 255, 0.4)",
                borderRadius: 4,
                borderWidth: 1,
                minHeight: 40,
                padding: 8,
                color: TEXT_COLOR,
                // background: BUTTON_COLOR,
                marginBottom: 4,
                textAlign: "left",
                textAlignLast: "left"
              }}

              onValueChange={(itemValue, index) => {
                console.log(index);
                var _name = this.logeNumberList[0];
                if (index >= 0) {
                  _name = this.logeNumberList[index - 1];
                }
                this.setState({
                  logeNumberIndex: index,
                  logeNumber: _name,
                });
              }}
              itemStyle={{ textAlign: "start" }}
            >
              {this._pickerItemLogeNumber()}
            </Picker>
          </View>
        )}
        {PROFILE_QUESTIONS_INFO_1_TEXT !== "" && (
          <View style={{ marginTop: 3 }}>
            <Text category="s2">{PROFILE_QUESTIONS_INFO_1_TEXT}</Text>
            <Picker
              label={`${PROFILE_QUESTIONS_INFO_1_TEXT}`}
              placeholder={`${PROFILE_QUESTIONS_INFO_1_TEXT}`}
              mode="dropdown"
              style={{
                marginTop: 4,
                backgroundColor: "rgba(255, 255, 255, 0.24)",
                borderColor: "rgba(255, 255, 255, 0.4)",
                borderRadius: 4,
                borderWidth: 1,
                minHeight: 40,
                padding: 8,
                maxWidth: 380,
                color: TEXT_COLOR,
                // background: BUTTON_COLOR,
                marginBottom: 4,
                textAlign: "left",
                textAlignLast: "left",
              }}
              status="control"
              selectedValue={(this.state && this.state.question1) || ""}
              onValueChange={(itemValue, index) => {
                console.log(itemValue);

                this.setState({
                  question1Index: index,
                  question1: itemValue,
                });

              }}
            >
              {this._pickerItemQuestion1List()}
            </Picker>
          </View>
        )}
        {PROFILE_QUESTIONS_INFO_2_TEXT !== "" && (
          <View style={{ marginTop: 3 }}>
            <Text category="s2">{PROFILE_QUESTIONS_INFO_2_TEXT}</Text>
            <Picker
              label={`${PROFILE_QUESTIONS_INFO_2_TEXT}`}
              placeholder={`${PROFILE_QUESTIONS_INFO_2_TEXT}`}
              mode="dropdown"
              style={{
                marginTop: 4,
                backgroundColor: "rgba(255, 255, 255, 0.24)",
                borderColor: "rgba(255, 255, 255, 0.4)",
                borderRadius: 4,
                borderWidth: 1,
                minHeight: 40,
                maxWidth: 380,
                padding: 8,
                color: TEXT_COLOR,
                // background: BUTTON_COLOR,
                marginBottom: 4,
                textAlign: "left",
                textAlignLast: "left",
              }}
              status="control"
              selectedValue={(this.state && this.state.question2) || ""}
              onValueChange={(itemValue, index) => {
                console.log(itemValue);


                this.setState({
                  question2Index: index,
                  question2: itemValue,
                });

              }}
            >
              {this._pickerItemQuestion2List()}
            </Picker>
          </View>
        )}
        {PROFILE_QUESTIONS_INFO_3_TEXT !== "" && (
          <View style={{ marginTop: 3 }}>
            <Text category="s2">{PROFILE_QUESTIONS_INFO_1_TEXT}</Text>
            <Picker
              label={`${PROFILE_QUESTIONS_INFO_3_TEXT}`}
              placeholder={`${PROFILE_QUESTIONS_INFO_3_TEXT}`}
              mode="dropdown"
              style={{
                marginTop: 4,
                backgroundColor: "rgba(255, 255, 255, 0.24)",
                borderColor: "rgba(255, 255, 255, 0.4)",
                borderRadius: 4,
                borderWidth: 1,
                minHeight: 40,
                maxWidth: 380,
                padding: 8,
                // color: TEXT_COLOR,
                // background: BUTTON_COLOR,
                marginBottom: 4,
                textAlign: "left",
                textAlignLast: "left",
              }}
              status="control"
              selectedValue={(this.state && this.state.question3) || ""}
              onValueChange={(itemValue, index) => {
                console.log(itemValue);

                this.setState({
                  question3Index: index,
                  question3: itemValue,
                });

              }}
            >
              {this._pickerItemQuestion3List()}
            </Picker>
          </View>
        )}

        <View
          style={{
            height: 1,
            width: "70%",
            backgroundColor: "#fff",
            opacity: 0.5,
            alignSelf: "center",
            marginTop: 15,
            marginBottom: 4,
          }}
        ></View>

        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <CheckBox
            status="control"
            checked={(this.state && this.state.agreement) || false}
            onChange={(v) => {
              this.setState({ agreement: v });
            }}
          />
          <Text
            style={{
              flex: 1,
              textAlignVertical: "center",
              color: TEXT_COLOR,
              fontSize: 12,
              fontWeight: "normal",
              marginLeft: 10,
            }}
          >
            {["fanquizwz", "wizink"].includes(THEME) ?
              ReactHtmlParser(`${TEXT_OPTION_1}`)
              : <span>
                {t("i_read_and_accept")}&nbsp;
                <a
                  style={{ color: TEXT_COLOR }}
                  rel="noopener noreferrer"
                  href={URL_RULES}
                  target="_blank"
                >
                  {t("agreement")}
                </a>
                {t("agreement_after")} *{TEXT_OPTION_1 !== "null" && <br />}
                {TEXT_OPTION_1 !== "null" &&
                  (this.props.navigation.state.params
                    ? this.props.navigation.state.params.opin1
                      ? this.props.navigation.state.params.opin1
                      : TEXT_OPTION_1
                    : TEXT_OPTION_1)}
              </span>
            }
          </Text>
        </View>

        {TEXT_OPTION_2 !== null && TEXT_OPTION_2 !== "null" && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CheckBox
              status="control"
              checked={(this.state && this.state.newsletter) || false}
              onChange={(v) => {
                this.setState({ newsletter: v });
              }}
            />
            <Text
              style={{
                flex: 1,
                textAlignVertical: "center",
                color: TEXT_COLOR,
                fontSize: 13,
                fontWeight: "normal",
                marginLeft: 10,
              }}
            >
              {ReactHtmlParser(`${TEXT_OPTION_2}`)}
            </Text>
          </View>
        )}
        {TEXT_OPTION_3 !== null && TEXT_OPTION_3 !== "null" && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <CheckBox
              status="control"
              checked={
                (this.state && this.state.newsletter_commercial) || false
              }
              onChange={(v) => {
                this.setState({ newsletter_commercial: v });
              }}
            />
            <Text
              style={{
                flex: 1,
                textAlignVertical: "center",
                color: TEXT_COLOR,
                fontSize: 13,
                fontWeight: "normal",
                marginLeft: 10,
              }}
            >
              {ReactHtmlParser(`${TEXT_OPTION_3}`)}
            </Text>
          </View>
        )}
        {this.state && this.state.error && (
          <Text category="s1" style={{
            color: TEXT_COLOR
          }}>
            --{" "}
            {this.state.error.message === "auth/email-already-in-use"
              ? t("email_already_exist")
              : this.state.error.message === "auth/wrong-password"
                ? t("wrong_password")
                : this.state.error.message}{" "}
            --
          </Text>
        )}
        <Button
          style={{ marginVertical: 20, borderRadius: 25, textTransform: 'uppercase' }}
          onPress={() => this.onValidateClicked()}
        >
          {t("submit")}
        </Button>

        {(this.props.navigation.state.params
          ? this.props.navigation.state.params.hasOwnProperty("opin4")
            ? this.props.navigation.state.params.opin4.length > 0
            : TEXT_OPTION_4 !== null && TEXT_OPTION_4 !== "null"
          : TEXT_OPTION_4 !== null && TEXT_OPTION_4 !== "null") && (
            <Text
              style={{
                flex: 1,
                textAlignVertical: "center",
                color: this.props.navigation.state.params
                  ? this.props.navigation.state.params.tc
                    ? this.props.navigation.state.params.tc
                    : TEXT_COLOR
                  : TEXT_COLOR,
                fontSize: RF(1.5),
                fontWeight: "normal",
                marginLeft: 10,
              }}
            >
              {ReactHtmlParser(`${TEXT_OPTION_4}`)}
            </Text>
          )}

        {!["fanquizwz", "wizink", "challengeic2024"].includes(THEME) && <Text
          style={{
            color: TEXT_COLOR,
            textAlign: "center",
          }}
        >
          {t("or_sign_in_with")}
        </Text>}

        {!["fanquizwz", "wizink", "challengeic2024"].includes(THEME) && <View style={{ flexDirection: "row", justifyContent: "center", textTransform: 'uppercase' }}>
          <Button
            appearance="ghost"
            size="giant"
            status="control"
            accessoryLeft={this.GoogleIcon}
            onPress={() => this.onGoogleLogin()}
          />
          {/* <Button
                  appearance="ghost"
                  size="giant"
                  status="control"
                  accessoryLeft={this.FacebookIcon}
                  onPress={() => this.onFacebookLogin()}
                /> */}
        </View>}

        {this.state.isLoading === true && (
          <View style={{
            height: "100%",
            width: "100%",
            position: 'fixed',
            alignSelf: "center",
            alignItems: 'center',
            alignContent: 'center',
            backgroundColor: 'rgba(255,255,255,0.5);'
          }} >
            <LottieView
              style={{
                width: 50, height: 50, alignSelf: "center",
              }}
              source={require("../../assets/anim/loader.json")}
              autoPlay
              loop
            />
          </View>
        )}

      </View>
    );
  }
}
const condition = authUser => !authUser;

export default withFirebase(withAuthorization(condition)(withTranslation(SignUp)));
