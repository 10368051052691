import React from "react";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import Ranking from "../../components/Ranking";
import { Text, View, Image, Dimensions, TouchableOpacity } from "react-native";
import RF from "react-native-responsive-fontsize";
import { APP_NAVTAB, BANNER_BOTTOM_ENABLE, MENU_COLOR, PAGE_GAMES, SHOW_GAMES, TEXT_COLOR, THEME } from "../../constants/const";
import {
  withTranslation,
  t
} from "react-multi-lang";

export class RankingScreen extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { data: null, currentPlayer: null };
  }

  componentDidMount() {
    this._isMounted = true;
    const currentResults = window.localStorage.getItem("currentResults");
    const currentQuiz = window.localStorage.getItem("currentQuiz");
    var playersRanking = [];
    if (currentResults != null) {
      const arrResults = JSON.parse(currentResults);
      const currentQuizData = JSON.parse(currentQuiz);
      arrResults.sort((a, b) => {
        return (
          b.point - a.point ||
          a.timeRightMs - b.timeRightMs ||
          a.timeMs - b.timeMs
        );
      });
      playersRanking = arrResults;

      var currentPlayer = null;
      if (this.props.authUser) {
        for (let i = 0; i < playersRanking.length; i++) {
          const element = playersRanking[i];
          if (this.props.authUser.uid === element.userId) {
            element.rank = i + 1;
            currentPlayer = element;
            break; // for
          }
        }
      }

      playersRanking = playersRanking.slice(0, 50);

      if (this._isMounted) {
        this.setState({
          data: playersRanking,
          totalQuestions: currentQuizData
            ? currentQuizData.questions.length
            : 0,
          showBanner: currentQuizData
            ? ['474', '479'].includes(currentQuizData.quiz_id)
            : false,
          quizId: currentQuizData
            ? currentQuizData.quiz_id
            : false,
          currentPlayer: currentPlayer,
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const screenWidth = Math.round(Dimensions.get("window").width);
    const { navigate } = this.props.navigation;
    console.log(this.state);
    return (
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <Text
          style={{
            color: TEXT_COLOR,
            textAlign: "center",
            textAlignVertical: "center",
            flex: 1,
            margin: 20,
            fontSize: 22,
          }}
        >
          {t("rankings_final")}
        </Text>
        {["fanquizwz", "wizink"].includes(THEME) && (
          <Text
            style={{
              color: TEXT_COLOR,
              textAlign: "center",
              textAlignVertical: "center",
              flex: 1,
              marginBottom: 20,
              fontSize: 16,
            }}
          >
            Obrigado por jogares connosco!<br />
            Os três primeiros classificados receberão os respetivos prémios por email
          </Text>
        )}
        <View style={{ marginBottom: RF(2) + 56 }}>
          <Ranking {...this.state} {...this.props} final={true} />
        </View>




        <View style={{
          position: 'fixed',
          bottom: 50,
          width: '100%',
        }}>
          {this.state.currentPlayer && (
            <View style={{}}>
              <View
                style={{
                  backgroundColor: "dimgray",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  opacity: 1,
                }}
              />

              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignContent: "stretch",
                  alignItems: "flex-start",
                  width: this.props.maxWidth,
                  justifyContent: "space-between",
                  borderBottomColor: "gray",
                  borderBottomWidth: 0.5,
                  paddingBottom: 5,
                  paddingTop: 5,
                }}
              >
                {this.state.currentPlayer.rank === 1 && (
                  <Image
                    style={{
                      width: 40,
                      height: 20,
                    }}
                    source={require("../../assets/images/first.png")}
                    resizeMode="contain"
                  />
                )}
                {this.state.currentPlayer.rank > 1 && (
                  <Text
                    style={{
                      fontSize: 15,
                      textAlign: "center",
                      width: 40,
                      textAlignVertical: "center",
                      color: "lightgray",
                    }}
                  >
                    {this.state.currentPlayer.rank}
                  </Text>
                )}
                <Text
                  style={{
                    color: TEXT_COLOR,
                    textAlign: "left",
                    textAlignVertical: "center",
                    flex: 1,
                    marginLeft: 8,
                    fontSize: 16,
                  }}
                >
                  {this.state.currentPlayer.displayName}
                </Text>

                <Text
                  style={{
                    fontSize: 16,
                    color: TEXT_COLOR,
                    textAlignVertical: "bottom",
                    width: 50,
                  }}
                >
                  {this.state.totalQuestions != null
                    ? `${this.state.currentPlayer.correct}/${this.state.totalQuestions}`
                    : this.state.currentPlayer.correct >= 1
                      ? "✅"
                      : "❌"}
                </Text>

                {this.state.currentPlayer.correct > 0 && this.state.currentPlayer.timeRightMs > 0 && (
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 16,
                      textAlignVertical: "bottom",
                      marginLeft: 5,
                      width: 60,
                    }}
                  >
                    {Math.floor(
                      this.state.currentPlayer.timeRightMs / 1000
                    ).toFixed(0)}
                    '
                    {Math.floor(
                      (this.state.currentPlayer.timeRightMs -
                        Math.floor(
                          this.state.currentPlayer.timeRightMs / 1000
                        ).toFixed(0) *
                        1000) /
                      10
                    ) < 10
                      ? "0" +
                      Math.floor(
                        (this.state.currentPlayer.timeRightMs -
                          Math.floor(
                            this.state.currentPlayer.timeRightMs / 1000
                          ).toFixed(0) *
                          1000) /
                        10
                      )
                      : Math.floor(
                        (this.state.currentPlayer.timeRightMs -
                          Math.floor(
                            this.state.currentPlayer.timeRightMs / 1000
                          ).toFixed(0) *
                          1000) /
                        10
                      )}
                    s
                  </Text>
                )}

                {this.state.currentPlayer.correct > 0 && this.state.currentPlayer.timeRightMs <= 0 && (
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 16,
                      textAlignVertical: "bottom",
                      marginLeft: 5,
                      width: this.props.modeTele
                        ? Math.round(screenWidth / 16)
                        : 60,
                    }}
                  >
                    {Math.floor(this.state.currentPlayer.timeMs / 1000).toFixed(
                      0
                    )}
                    '
                    {Math.floor(
                      (this.state.currentPlayer.timeMs -
                        Math.floor(
                          this.state.currentPlayer.timeMs / 1000
                        ).toFixed(0) *
                        1000) /
                      10
                    ) < 10
                      ? "0" +
                      Math.floor(
                        (this.state.currentPlayer.timeMs -
                          Math.floor(
                            this.state.currentPlayer.timeMs / 1000
                          ).toFixed(0) *
                          1000) /
                        10
                      )
                      : Math.floor(
                        (this.state.currentPlayer.timeMs -
                          Math.floor(
                            this.state.currentPlayer.timeMs / 1000
                          ).toFixed(0) *
                          1000) /
                        10
                      )}
                    s
                  </Text>
                )}
              </View>
            </View>
          )}

          <View style={{
            position: 'fixed',
            bottom: BANNER_BOTTOM_ENABLE() ? 70 : 0,
            width: '100%',
            backgroundColor: MENU_COLOR,
            padding: 15
          }}>
            <TouchableOpacity
              onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate(PAGE_GAMES()) : navigate("Home")}
            >
              <View style={{
                flexDirection: "row",
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Image
                  style={{ position: 'absolute', left: 0, width: 20, height: 20, margin: 3 }}
                  source={require("../../assets/images/back.svg")}
                  resizeMode="contain"
                />
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: 16,
                    textAlign: "center",
                    fontWeight: 'bold'
                  }}
                >
                  {SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}
                </Text>
              </View>
            </TouchableOpacity>
          </View>}
        </View>


      </View >
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(withMainLayout(withTranslation(RankingScreen)));
